import {
  FloatingTabBar,
  ITab,
  MessageOverlay,
  StatisticsPanel,
  PAGINATION_GREY,
  IDropdownItem,
  ITimeState
} from '@doseme/cohesive-ui'
import { format } from 'date-fns'
import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'

import {
  IModelDoseRecommendation,
  IPerDoseOutcome,
  IPredictedOutcome,
  TModelType
} from '../../../../../../../../../store/dosingRecommendation/types'
import { IDefaultLimit, ILimit, TLoadState } from '../../../../../../../../../store/types'
import { ICustomDoseForms, ICustomTargetForms } from '../types'
import {
  customDoseFormFields,
  customTargetDoseStat,
  customTargetFormFields,
  customTargetTroughStat
} from './fields/customFields'
import { predictedOutcomeCmlAUCStats, predictedOutcomeRangeStats, predictedOutcomesStats } from './fields/sharedFields'
import { TCustomType, TSimulationPanelType } from '../../../types'
import { dateFnsRawDateOnly, dateFnsTableReadable } from '../../../../../../../../../constants/timeFormat'
import { isValidDSTDateTime } from '../../../../../../../../../utils/dates'
import { SimulationOutcomesInfoIcon } from '../components/SimulationOutcomesInfoIcon'
import { ICourseCustomTarget } from '../../../../../../../../../store/course/types'

import '../index.scss'

const customSubTabs: ITab[] = [
  { id: 'target', displayName: 'Custom Target' },
  { id: 'dose', displayName: 'Custom Dose' }
]

interface IProps {
  calculationDisabled: boolean
  hospitalTimezone: string
  nextDoseAt: Date | null
  nextDoseAtTime: ITimeState
  minDate: Date | null
  maxDate: Date | null
  calculated: IModelDoseRecommendation | null
  customTargetCalculationValid: boolean
  customDoseCalculationValid: boolean
  perDoseOutcomes: IPerDoseOutcome | null
  predictedOutcome: IPredictedOutcome | null
  storeLoadState: TLoadState
  currentModel: TModelType
  customType: TCustomType
  aucTargetLimit: IDefaultLimit
  doseLimit: ILimit
  peakTargetLimit: IDefaultLimit
  troughTargetLimit: IDefaultLimit
  infusionLengthLimit: IDefaultLimit
  dosingPeriodLimit: IDefaultLimit
  numberOfDosesLimit: IDefaultLimit
  timeAboveMICTargetLimit: IDefaultLimit
  cumulativeAUCTarget: IDefaultLimit
  customTargetForm: ICustomTargetForms
  customDoseForm: ICustomDoseForms
  allowedCustomTargets: IDropdownItem[] | null
  selectedTargetDropdownItem: IDropdownItem
  disableTabs: boolean
  setSelectedTargetDropdownItem: (selectedTargetDropdownItem: IDropdownItem) => void
  setCustomType: (type: TCustomType) => void
  setCustomValidationErrors: (errors: Array<[string, string]>) => void
  handleCustomTargetCalculation: () => void
  handleCustomDoseCalculation: () => void
  setShowSimulateHDScheduleModal: (showSimulatePanel: TSimulationPanelType) => void
  drugModelId?: string
  drugModelName?: string
  defaultCustomTarget: ICourseCustomTarget
}

export const CustomTab: React.FC<IProps> = (props) => {
  const {
    calculationDisabled,
    hospitalTimezone,
    nextDoseAt,
    nextDoseAtTime,
    minDate,
    maxDate,
    calculated,
    customTargetCalculationValid,
    customDoseCalculationValid,
    perDoseOutcomes,
    predictedOutcome,
    storeLoadState,
    currentModel,
    customType,
    aucTargetLimit,
    doseLimit,
    peakTargetLimit,
    troughTargetLimit,
    infusionLengthLimit,
    dosingPeriodLimit,
    numberOfDosesLimit,
    timeAboveMICTargetLimit,
    cumulativeAUCTarget,
    customTargetForm,
    customDoseForm,
    allowedCustomTargets,
    selectedTargetDropdownItem,
    disableTabs,
    setSelectedTargetDropdownItem,
    setCustomType,
    setCustomValidationErrors,
    handleCustomTargetCalculation,
    handleCustomDoseCalculation,
    setShowSimulateHDScheduleModal,
    drugModelName,
    drugModelId
  } = props

  ReactTooltip.rebuild()

  const customTargetFormJSX: JSX.Element = (
    <div className='custom-form-wrapper'>
      <StatisticsPanel
        data={customTargetFormFields(
          aucTargetLimit,
          peakTargetLimit,
          troughTargetLimit,
          infusionLengthLimit,
          dosingPeriodLimit,
          numberOfDosesLimit,
          timeAboveMICTargetLimit,
          cumulativeAUCTarget,
          doseLimit,
          customTargetForm,
          allowedCustomTargets,
          selectedTargetDropdownItem,
          setSelectedTargetDropdownItem,
          setCustomValidationErrors,
          ['loading', 'updating'].includes(storeLoadState),
          drugModelName
        )}
        showDividers={false}
        colWidth={68}
      />
    </div>
  )

  if (!Object.keys(customTargetForm).includes(selectedTargetDropdownItem.value)) {
    throw Error(`Missing hardcoded target type ${selectedTargetDropdownItem.value}`)
  }

  const selectedTargetFormFields = customTargetForm[selectedTargetDropdownItem.value as keyof ICustomTargetForms]

  const getPredictedStatPanel = () => {
    if (selectedTargetDropdownItem.value === 'range') {
      return (
        <StatisticsPanel
          data={predictedOutcomeRangeStats(perDoseOutcomes)}
          boldTitles
          bgColor={PAGINATION_GREY}
          colWidth={110}
        />
      )
    }

    if (selectedTargetDropdownItem.value === 'cumulative_auc') {
      return (
        <StatisticsPanel
          data={predictedOutcomeCmlAUCStats(!!calculated, predictedOutcome)}
          boldTitles
          bgColor={PAGINATION_GREY}
          colWidth={110}
        />
      )
    }

    return (
      <StatisticsPanel
        data={predictedOutcomesStats(
          !!calculated,
          perDoseOutcomes,
          selectedTargetDropdownItem.value === 'time_above_mic' ? predictedOutcome : null,
          aucTargetLimit,
          peakTargetLimit,
          troughTargetLimit,
          timeAboveMICTargetLimit
        )}
        boldTitles
        bgColor={PAGINATION_GREY}
        colWidth={64}
      />
    )
  }

  const customTargetOutcomes: JSX.Element = (
    <div
      className={classnames('predicted-outcomes-content', {
        'custom-predicted-outcomes-outer-time-to-trough': selectedTargetDropdownItem.value === 'timetotrough'
      })}
    >
      <div
        className={classnames('outcomes-dose-stat', {
          'custom-outcomes-dose-stat-time-to-trough': selectedTargetDropdownItem.value === 'timetotrough'
        })}
      >
        {selectedTargetDropdownItem.value === 'timetotrough' ? (
          <StatisticsPanel data={customTargetTroughStat(predictedOutcome)} boldTitles bgColor={PAGINATION_GREY} />
        ) : (
          <StatisticsPanel data={customTargetDoseStat(calculated)} boldTitles bgColor={PAGINATION_GREY} colWidth={64} />
        )}
      </div>

      {getPredictedStatPanel()}
    </div>
  )

  const customTargetStatPanel = () => {
    const nextDoseAtMilliseconds = nextDoseAt?.getTime()
    const withinDateRange =
      nextDoseAtMilliseconds &&
      (!minDate || nextDoseAtMilliseconds >= minDate.getTime()) &&
      (!maxDate || nextDoseAtMilliseconds <= maxDate.getTime())

    const calcTimeValid =
      withinDateRange &&
      nextDoseAt &&
      isValidDSTDateTime(format(nextDoseAt, dateFnsRawDateOnly), nextDoseAtTime, hospitalTimezone)

    const calcEnabled = calcTimeValid && selectedTargetFormFields.valid && !calculationDisabled

    const btnTooltipRef = 'custom-target-daterange-tooltip'

    const disabledProps = calcEnabled
      ? {}
      : {
        btnTooltipRef,
        disabledAction: true
      }
    if (!calculated || !customTargetCalculationValid) {
      return (
        <>
          {!calcTimeValid && minDate && maxDate ? (
            <ReactTooltip id={btnTooltipRef} place='top' effect='solid'>
              Next dose time must be <br /> between&nbsp;
              <span> {format(minDate!, dateFnsTableReadable)}</span> <br /> and&nbsp;
              <span> {format(maxDate!, dateFnsTableReadable)}</span>
            </ReactTooltip>
          ) : null}
          <div className='d-flex custom-predicted-perDoseOutcomes'>
            <MessageOverlay
              type={storeLoadState === 'loading' || storeLoadState === 'updating' ? 'loading' : 'action'}
              actionText={<div>Calculate</div>}
              borderRadiusPx={16}
              height={'110'}
              zIndex={10}
              onAction={() => {
                if (drugModelId === '55') {
                  setShowSimulateHDScheduleModal('customTarget')

                  return
                }
                handleCustomTargetCalculation()
              }}
              {...disabledProps}
            >
              {customTargetOutcomes}
            </MessageOverlay>
          </div>
        </>
      )
    }

    return customTargetOutcomes
  }

  const customDoseFormJSX: JSX.Element = (
    <div className='custom-form-wrapper custom-dose-sub-tab'>
      <StatisticsPanel
        data={customDoseFormFields(
          doseLimit,
          infusionLengthLimit,
          dosingPeriodLimit,
          numberOfDosesLimit,
          customDoseForm,
          setCustomValidationErrors,
          ['loading', 'updating'].includes(storeLoadState)
        )}
        showDividers={false}
      />
    </div>
  )

  const customDoseOutcomes = (): JSX.Element => {
    if (props.defaultCustomTarget.target === 'cumulative_auc' && predictedOutcome) {
      return (
        <StatisticsPanel
          data={predictedOutcomeCmlAUCStats(!!calculated, predictedOutcome)}
          boldTitles
          bgColor={PAGINATION_GREY}
          colWidth={160}
        />
      )
    }

    return (
      <div className='predicted-perDoseOutcomes-content'>
        <StatisticsPanel
          data={predictedOutcomesStats(
            !!calculated,
            perDoseOutcomes,
            predictedOutcome,
            aucTargetLimit,
            peakTargetLimit,
            troughTargetLimit,
            timeAboveMICTargetLimit
          )}
          boldTitles
          bgColor={PAGINATION_GREY}
        />
      </div>
    )
  }

  const customDoseStatPanel = () => {
    const nextDoseAtMilliseconds = nextDoseAt?.getTime()
    const withinDateRange =
      nextDoseAtMilliseconds &&
      (!minDate || nextDoseAtMilliseconds >= minDate.getTime()) &&
      (!maxDate || nextDoseAtMilliseconds <= maxDate.getTime())

    const calcTimeValid =
      withinDateRange &&
      nextDoseAt &&
      isValidDSTDateTime(format(nextDoseAt, dateFnsRawDateOnly), nextDoseAtTime, hospitalTimezone)

    const calcEnabled = calcTimeValid && customDoseForm['dose'].valid

    const btnTooltipRef = 'custom-target-daterange-tooltip'

    const disabledProps = calcEnabled
      ? {}
      : {
        btnTooltipRef,
        disabledAction: true
      }

    if (!calculated || !customDoseCalculationValid) {
      return (
        <>
          {!calcTimeValid && minDate && maxDate ? (
            <ReactTooltip id={btnTooltipRef} place='top' effect='solid'>
              Next dose time must be <br /> between&nbsp;
              <span> {format(minDate!, dateFnsTableReadable)}</span> <br /> and&nbsp;
              <span> {format(maxDate!, dateFnsTableReadable)}</span>
            </ReactTooltip>
          ) : null}
          <div>
            <MessageOverlay
              type={storeLoadState === 'loading' || storeLoadState === 'updating' ? 'loading' : 'action'}
              actionText={<div>Calculate</div>}
              borderRadiusPx={16}
              height={'110'}
              zIndex={10}
              onAction={() => {
                if (drugModelId === '55') {
                  setShowSimulateHDScheduleModal('customDose')

                  return
                }
                handleCustomDoseCalculation()
              }}
              {...disabledProps}
            >
              {customDoseOutcomes()}
            </MessageOverlay>
          </div>
        </>
      )
    }

    return customDoseOutcomes()
  }

  return (
    <div className='d-flex justify-content-between'>
      <div className='flex-column mr-2'>
        <div className='custom-subheading'>
          <FloatingTabBar
            tabNames={customSubTabs}
            activeTab={customType}
            onSelectTab={(activeTab) => setCustomType(activeTab as TCustomType)}
            disabled={disableTabs}
          />
        </div>
        {currentModel === 'customTarget' ? customTargetFormJSX : customDoseFormJSX}
      </div>
      <div className='flex-column'>
        <div className='d-flex align-items-center'>
          <span className='sim-panel-subheading font-bold co-h6'>
            Predicted Outcomes
            <div className='info-icon'>
              <SimulationOutcomesInfoIcon />
            </div>
          </span>
        </div>
        <div className='sim-panel-calculated-subtitle'>Calculated by DoseMeRx</div>
        {currentModel === 'customTarget' ? customTargetStatPanel() : customDoseStatPanel()}
      </div>
    </div>
  )
}
