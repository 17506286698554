import ReactTooltip from 'react-tooltip'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { IMapEntry } from 'mobx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
  ACTIVE_GOLD,
  BackArrowButton,
  Dropdown,
  FOOTER_HOVER,
  IBreadcrumb,
  Icons,
  InfoBubble,
  InfoModal,
  LOADING_GREY,
  Modal,
  Tag,
  red100
} from '@doseme/cohesive-ui'

import {
  useBreadcrumbsStore,
  useDrugModelsStore,
  useHospitalStore,
  usePatientCoursesStore,
  usePatientStore
} from '../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../shared/toast'
import { DrugModel } from '../../../../../store/drugModels/DrugModel'
import { handleBackButton } from '../../../../../utils/navigation'
import { svgAllApplicableModels, svgSuggestedModel } from './constants'
import { getMoleculeDropdownItems } from './utils'
import { IProps } from './types'

import './index.scss'

export const AddCourseForm: React.FC<IProps> = observer((props) => {
  const [selectedMolecule, setSelectedMolecule] = useState<string | undefined>('')
  const [selectedModel, setSelectedModel] = useState<string | null>(null)
  const [showUnapplicableModels, setShowUnapplicableModels] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [infoIconColor, setInfoIconColor] = useState<string | undefined>(undefined)

  const patientCoursesStore = usePatientCoursesStore()
  const drugModelsStore = useDrugModelsStore()
  const patientStore = usePatientStore()
  const hospitalStore = useHospitalStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && patientStore.loadState === 'loaded') {
      const breadcrumbs: IBreadcrumb[] =
        window.env.VENDOR_MODE === 'standalone'
          ? [{ label: `Patients at ${hospitalStore.hospital?.attributes.name}`, onClick: () => returnToPatients() }]
          : []
      breadcrumbs.push({
        label: `${patientStore.patient?.attributes.familyName},
          ${patientStore.patient?.attributes.givenNames} (${patientStore.patient?.attributes.longId})`,
        onClick: () => returnToPatient()
      })
      breadcrumbs.push({
        label: 'Create new course'
      })
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [hospitalStore.loadState, patientStore.loadState])

  useEffect(() => {
    let hasValidDrugModels = false
    if (drugModelsStore.loadStateDrugmodels === 'loaded' && drugModelsStore.drugmodels) {
      const applicableModels = [...drugModelsStore.drugmodels].filter(([key, drugModel]) => {
        if (drugModel.attributes.valid && !drugModel.attributes.isSuggested) {
          return drugModel
        }
      })
      hasValidDrugModels = applicableModels.length > 0
    }

    setShowUnapplicableModels(!hasValidDrugModels)
  }, [drugModelsStore.loadStateDrugmodels])

  useEffect(() => {
    setSelectedModel(null)
    if (selectedMolecule) {
      drugModelsStore.fetchDrugModels(props.patientId, selectedMolecule)
    }
  }, [selectedMolecule])

  useLayoutEffect(() => {
    drugModelsStore.fetchMolecules(props.patientId)
  }, [props.patientId])

  const memoizedMoleculeDropdownItems = useCallback(
    () => getMoleculeDropdownItems(drugModelsStore.moleculeItems()),
    [drugModelsStore.molecules]
  )

  const returnToPatients = () => {
    history.push('/patients')
  }

  const returnToPatient = () => {
    history.push(`/patients/${patientStore.patient?.id}`)
  }

  const handleSubmit = async () => {
    if (
      selectedMolecule &&
      drugModelsStore.loadStateMolecules === 'loaded' &&
      patientCoursesStore.loadState === 'loaded' &&
      selectedModel
    ) {
      const suggestedModel = drugModelsStore.getSuggestedModel()
      const newCourse = await patientCoursesStore.createCourse(
        props.patientId,
        selectedModel.toString(),
        suggestedModel?.id,
        suggestedModel?.attributes.modelSuggestionMethod
      )

      if (!newCourse) {
        showErrorToast('Failed to create course')

        return
      }

      showSuccessToast('Course created')
      history.push(`/patients/${props.patientId}/courses/${newCourse.id}`, { from: `/patients/${props.patientId}` })
    }
  }

  const infoBubble = () => {
    if (window.env.VENDOR_MODE === 'epic' || window.env.VENDOR_MODE === 'cerner') {
      const title = (
        <>
          Any relevant existing SeCr records will show under Recorded Course Data for this new course. <br />
          <br />
          Existing doses and drug levels will not be added, as these records should already be captured in an existing
          course.
        </>
      )

      return <InfoBubble bubbleTitle={title} borderRadius={5} />
    }

    const title = (
      <>
        This will start a new course, containing no course data. <br />
        Any records may need to be manually added.
      </>
    )

    return <InfoBubble bubbleTitle={title} borderRadius={5} />
  }

  const displayApplicableModelsAndSections = (): JSX.Element => {
    const suggestedModel = drugModelsStore.getSuggestedModel()

    if (suggestedModel) {
      if (!selectedModel) {
        setSelectedModel(suggestedModel.id)
      }

      return (
        <div>
          <div className='add-course-suggested-model-title'>Suggested model:</div>
          <div className='add-course-suggested-model-subtitle'>
            <u>Model suitability</u> based on patient demographic information.
          </div>
          <div
            onClick={() => setSelectedModel(suggestedModel.id)}
            className={classnames(
              'selectable-checkbox add-course-content-panel mt-2',
              { 'add-course-model-selection': suggestedModel.id !== selectedModel },
              { 'add-course-model-selection-selected': suggestedModel.id === selectedModel }
            )}
          >
            <input type='checkbox' id='checkbox-round' readOnly checked={suggestedModel.id === selectedModel} />
            <div className='add-course-model-text'>{suggestedModel.attributes.name}</div>
          </div>
          <div className='add-course-suggested-model-title mt-4'>Other applicable models:</div>
        </div>
      )
    }

    return <div className='add-course-suggested-model-title'>All applicable models:</div>
  }

  const displayApplicableModels = (): JSX.Element[] | JSX.Element => {
    const applicableModels = [...drugModelsStore.drugmodels].filter(([key, drugModel]) => {
      if (drugModel.attributes.valid && !drugModel.attributes.isSuggested) {
        return drugModel
      }
    })

    if (applicableModels.length < 1) {
      return <div className='no-applicable-models'>No suitable models for this patient.</div>
    }

    if (applicableModels.length === 1 && !drugModelsStore.getSuggestedModel() && !selectedModel) {
      setSelectedModel(applicableModels[0][1].id)
    }

    return applicableModels.map(([key, drugModel]) => (
      <div
        key={`${drugModel.id}-${key}`}
        onClick={() => setSelectedModel(drugModel.id)}
        className={classnames(
          'selectable-checkbox add-course-content-panel mt-2',
          { 'add-course-model-selection': drugModel.id !== selectedModel },
          { 'add-course-model-selection-selected': drugModel.id === selectedModel }
        )}
      >
        <input type='checkbox' id='checkbox-round' readOnly checked={drugModel.id === selectedModel} />
        <div className='add-course-model-text'>{drugModel.attributes.name}</div>
        {!drugModel.attributes.isValidated ? (
          <>
            <ReactTooltip
              id={`add-course-form-research-info-tip-${key}`}
              place='top'
              effect='solid'
              className='course-variables-tooltip-width'
            >
              For research purposes only.<br/>
              Not for clinical use.
            </ReactTooltip>
            <span style={{ transform: 'translateY(-1px)' }} className='ml-2'>
              <Tag
                size='medium'
                text='Research'
                color={red100}
                iconRight={
                  <div data-tip data-for={`add-course-form-research-info-tip-${key}`}>
                    <Icons.Info />
                  </div>
                }
              />
            </span>
          </>
        ) : null}
      </div>
    ))
  }

  const infoModalContent = (): JSX.Element => {
    return (
      <div className='add-course-form-model-selection-information-outer'>
        <div className='info-modal-title'>How does DoseMeRx suggest a model?</div>
        <div className='info-modal-text mt-2'>
          For supported drugs, you will see a suggested model when there is more than one model applicable for a
          patient.
        </div>
        <div className='info-modal-text mt-2'>
          DoseMeRx compares the patient’s demographic information to the population sample used by the drug model to
          determine the suitability of each drug model for that patient. The most suitable model will appear at the top
          as the suggested model, with all other applicable models listed below.
        </div>
        <div className='info-modal-text mt-2'>
          You can choose to continue with the suggested model, or select any applicable model you believe is most
          appropriate based on your clinical judgment.
        </div>
        <div className='mt-2 mb-2 mr-2 ml-2'>{svgSuggestedModel}</div>
        <div className='mt-3'>
          <InfoBubble bubbleTitle='Important information:' borderRadius={5}>
            <div className='info-modal-important-information'>
              A model’s suitability is determined by comparing a patient’s demographic information to the model
              population before the course has been individualized.
              <div className='mt-2'>
                Once more course information is available (i.e. recorded doses and observations), the model’s
                suitability should be re-evaluated based on individualized model fit.
              </div>
            </div>
          </InfoBubble>
        </div>
        <div className='info-modal-title mt-3' mt-3>
          What if there is no suggested model?
        </div>
        <div className='info-modal-text mt-2'>
          If there is no suggested model, it means that DoseMeRx is unable to determine suitability for any applicable
          model for that drug. All applicable models will be listed for you to select the most appropriate one, based on
          your clinical judgment.
        </div>
        <div className='mt-2 mb-2 mr-2 ml-2'>{svgAllApplicableModels}</div>
        <div className='info-modal-title mt-3' mt-3>
          For more information, head over to the Resources section or contact the DoseMeRx support team directly – we’re
          available 24/7.
        </div>
      </div>
    )
  }

  const displayUnapplicableModels = (unapplicableModelItems: IMapEntry<string, DrugModel>[]): JSX.Element[] => {
    const returnValue = unapplicableModelItems.map(([key, drugModel]) => {
      return (
        <div key={`${drugModel.id}-${key}`} className='unapplicable-models-item'>
          <div className='font-weight-bold'>
            {drugModel.attributes.name}
            {drugModel.attributes.errors &&
              drugModel.attributes.errors.map((error) => (
                <div key={`key-${drugModel.id}-${error}`} className='unapplicable-models-item-error'>
                  <ul>
                    <li>{error}</li>
                  </ul>
                </div>
              ))}
          </div>
        </div>
      )
    })

    return returnValue
  }

  const displayUnapplicableModelsDiv = (): JSX.Element => {
    const unapplicableModels = [...drugModelsStore.drugmodels].filter(([key, drugModel]) => {
      if (!drugModel.attributes.valid) {
        return drugModel
      }
    })

    if (unapplicableModels.length > 0) {
      return (
        <div
          onClick={() => setShowUnapplicableModels(!showUnapplicableModels)}
          className={classnames('unapplicable-models-outer', { closed: !showUnapplicableModels })}
        >
          <div className='d-flex'>
            <div className='font-14-weight-700 mb-1'>Unsuitable models</div>
            <div className='unapplicable-models-chevron-outer'>
              <div className='mr-2'>{showUnapplicableModels ? 'Hide' : 'Show'}</div>
              <div
                className={classnames('unapplicable-models-chevron', {
                  activated: showUnapplicableModels
                })}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </div>
          <div>{showUnapplicableModels && displayUnapplicableModels(unapplicableModels)}</div>
        </div>
      )
    }

    return <></>
  }

  const submitBtn: JSX.Element = (
    <div
      className={classnames('add-course-submit-btn', {
        disabled:
          drugModelsStore.loadStateDrugmodels !== 'loaded' ||
          patientCoursesStore.loadState !== 'loaded' ||
          !selectedModel
      })}
      onClick={handleSubmit}
    >
      <div className='add-course-submit-btn-text'>Continue →</div>
    </div>
  )

  const modelSelectionDiv = (): JSX.Element => {
    if (selectedMolecule === '') {
      return (
        <div className='model-selection-outer'>
          <div className='model-selection-title'>Model selection</div>
          <div className='model-selection-text'>Select a drug to continue...</div>
        </div>
      )
    }

    if (drugModelsStore.loadStateDrugmodels !== 'loaded' || !drugModelsStore.drugmodels) {
      return (
        <div className='mt-3 content-panel-with-shadow add-course'>
          <div className='content-panel-with-shadow-title'>Model selection</div>
          <div className='model-selection-loading-spinner'>
            <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='32px' />
          </div>
        </div>
      )
    }

    return (
      <div className='mt-3 content-panel-with-shadow add-course'>
        <div className='d-flex'>
          <div className='content-panel-with-shadow-title'>Model selection</div>
          <div className='add-course-info-box'>
            {'More information'}
            <div
              className='add-course-info-icon'
              onClick={() => setShowInfoModal(true)}
              onMouseLeave={() => setInfoIconColor(undefined)}
              onMouseEnter={() => setInfoIconColor(FOOTER_HOVER)}
            >
              <Icons.Info color={infoIconColor} />
            </div>
          </div>
        </div>
        <div className='add-course-suggested-model-outer'>
          {displayApplicableModelsAndSections()}
          {displayApplicableModels()}
        </div>
        {displayUnapplicableModelsDiv()}
        {selectedMolecule && submitBtn}
      </div>
    )
  }

  return (
    <div className='add-course'>
      <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)}>
        <InfoModal
          title={'Model selection information'}
          message={infoModalContent()}
          onDismiss={() => setShowInfoModal(false)}
          limitHeightToWindow={true}
        />
      </Modal>

      <div className='d-flex'>
        <div>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(`/patients/${props.patientId}`, history)
            }
          />
        </div>
        <span className='add-course-title'>Create new course</span>
      </div>

      {window.env.VENDOR_MODE !== 'standalone' && <div className='pt-3 mb-3'>{infoBubble()}</div>}

      <div className='content-panel-with-shadow add-course'>
        <div className='content-panel-with-shadow-title mb-3 d-flex'>
          Drug selection
          {drugModelsStore.loadStateMolecules !== 'loaded' || !drugModelsStore.molecules ? (
            <div className='ml-3'>
              <Icons.ThinSpinner strokeWidth={12} r={32} stroke={ACTIVE_GOLD} width='24px' />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Dropdown
          fieldState='valid'
          id='list-drug-models'
          data-testid='model-dropdown'
          value={selectedMolecule}
          showSearchThreshold={5}
          placeholder='Select Drug Model...'
          searchBarWidth={420}
          data={memoizedMoleculeDropdownItems()}
          onSelect={(item) => setSelectedMolecule(item.value)}
          disabled={drugModelsStore.loadStateMolecules !== 'loaded' || !drugModelsStore.molecules}
        />
      </div>
      {modelSelectionDiv()}
    </div>
  )
})
