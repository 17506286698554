import { Icons, LOADING_GREY, Panel } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import classnames from 'classnames'

import { usePatientStore, usePatientCoursesStore } from '../../../../../../hooks/useStore'
import { PatientPreviewPanelBottomSection } from './components/PatientPreviewPanelBottomSection'
import { PatientPreviewPanelTopSection } from './components/PatientPreviewPanelTopSection'

import './index.scss'

interface IProps {
  selectedPatientId?: string
  isArchived: boolean
}

export const PatientPreviewPanel: React.FC<IProps> = observer(({ isArchived, selectedPatientId }) => {
  const patientStore = usePatientStore()
  const patientCoursesStore = usePatientCoursesStore()

  useEffect(() => {
    if (selectedPatientId && selectedPatientId !== '') {
      patientStore.fetchPatient(selectedPatientId)
      patientCoursesStore.resetStore()
      patientCoursesStore.fetchPatientCourses(selectedPatientId)
    } else {
      patientCoursesStore.resetStore()
      patientStore.resetPatient()
    }
  }, [selectedPatientId])

  return (
    <Panel
      className={classnames('patient-preview', { 'patient-preview-none-selected': !selectedPatientId })}
      title='Patient Preview'
      sections={
        patientStore.loadState === 'loading' || patientCoursesStore.loadState === 'loading'
          ? [
            {
              id: 3,
              element: (
                <div className='patient-preview-loading-state' data-testid='patient-preview-panel-spinner' >
                  <Icons.ThinSpinner strokeWidth={12} r={30} stroke={LOADING_GREY} width='60px' />
                </div>
              )
            }
          ]
          : [
            {
              id: 1,
              element: <PatientPreviewPanelTopSection isArchived={isArchived} selectedPatientId={selectedPatientId} />
            },
            {
              id: 2,
              element: <PatientPreviewPanelBottomSection selectedPatientId={selectedPatientId} />
            }
          ]
      }
    />
  )
})
