import React, { useEffect, useRef, useState } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { decode } from 'he'
import classnames from 'classnames'
import saveAs from 'file-saver'
import { isIE } from 'react-device-detect'
import { Button, Icons, InfoModal, ListButton, LOADING_GREY, Modal } from '@doseme/cohesive-ui'

import { ActivityLog } from '../../../../../../../../../store/activityLog/ActivityLog'
import { formatToDisplayDate } from '../../../../../../../../../utils/dates'
import { IDoseReportResponse, TReportResponse } from '../../../../../../../../../store/generateReport/types'
import { showErrorToast, showSuccessToast } from '../../../../../../../../../shared/toast'
import { Hospital } from '../../../../../../../../../store/hospital/Hospital'

import './index.scss'

interface IProps {
  log: ActivityLog
  hospital: Hospital | null
  patientId: string
  courseId: string
}

export const ReportActivityLog: React.FC<IProps> = (props) => {
  const [seeMore, setSeeMore] = useState<boolean>(false)
  const [hasOverFlow, setHasOverFlow] = useState<boolean>(false)
  const [showReportModal, setShowReportModal] = useState<boolean>(false)
  const [base64Preview, setBase64Preview] = useState<string | null>(null)
  const [downloading, setDownloading] = useState(false)

  const noteRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { current } = noteRef

    if (current) {
      if (isIE) {
        setHasOverFlow(current.scrollHeight > current.clientHeight + 1)
      } else {
        setHasOverFlow(current.scrollHeight > current.clientHeight)
      }
    }
  }, [noteRef])

  const formatReportActivityLogNote = () => {
    if (!props.log.attributes.note) {
      return
    }

    return (
      <div className='note-bubble'>
        <span className='font-weight-bold pb-1'>Clinician notes:</span>
        <div ref={noteRef} className={ classnames({ 'note-bubble-text-collapsed': !seeMore }) }>{props.log.attributes.note}</div>
        {hasOverFlow && <a className='note-bubble-show-less-more' onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'Show less' : 'Show more'}</a>}
      </div>
    )
  }

  const getReportActivityLogDetails = (): string[] => {
    const activityLogDetails: string[] = []
    if (props.log.attributes.report_type) {
      activityLogDetails.push(`Report type: ${props.log.attributes.report_type}`)
    }
    if (props.log.attributes.next_dose_date) {
      activityLogDetails.push(`Next dose at: ${formatToDisplayDate(props.log.attributes.next_dose_date, props.hospital!.attributes.timezone)}`)
    }
    if (props.log.attributes.dose_recommendation) {
      activityLogDetails.push(`Dose details: ${props.log.attributes.dose_recommendation}`)
    }

    return activityLogDetails
  }

  const handleGetReportRequest = async (isPreview: boolean, link: string) => {
    if (!isPreview) {
      setDownloading(true)
    }

    const config: AxiosRequestConfig = {
      headers: {
        Accept: 'application/vnd.api+json, application/json'
      }
    }

    const dssSaveConfig: AxiosRequestConfig = {
      headers: {
        Accept: 'application/vnd.api+json, application/json, application/pdf'
      },
      responseType: 'blob'
    }

    await axios
      .get(
        isPreview ? link : link + '/pdf',
        window.env.VENDOR_MODE === 'dss' && !isPreview ? dssSaveConfig : config
      )
      .then((response: AxiosResponse<TReportResponse>) => {
        if (isPreview) {
          const reportDataResponse = response.data as IDoseReportResponse
          setBase64Preview(reportDataResponse.data.report)
        } else {
          const reportFileResponse = response.data as string
          const reportFileBlob = new Blob([reportFileResponse], { type: 'application/pdf' })
          // save with the filename supplied to us in double quotes from the content-disposition header
          saveAs(reportFileBlob, response.headers['content-disposition']?.match(/"([^"]+)"/)?.[1] || 'report.pdf')
          setShowReportModal(false)
          showSuccessToast('Report downloaded')
        }
      })
      .catch((error) => {
        showErrorToast(isPreview ? 'Preview failed to load' : 'Report failed to download')
      })
    setDownloading(false)
  }

  const saveDoseReportDownloadButton = (): JSX.Element | undefined => {
    if (window.env.VENDOR_MODE === 'dss' && props.log.attributes.report_link) {
      return (
        <Button
          variant='primary'
          onClick={() => handleGetReportRequest(false, props.log.attributes.report_link as string)}
          loading={downloading}
        >
          Download a copy
        </Button>
      )
    }
  }

  const saveDoseReportSubtitle = (): JSX.Element => {
    let dateSection = <></>
    if (props.log.attributes.created) {
      dateSection =
        <>&nbsp;on&nbsp;
          <span className='font-weight-bold'>{formatToDisplayDate(props.log.attributes.created, props.hospital!.attributes.timezone)}</span>
        </>
    }

    return (
      <>
        Initially generated by&nbsp;
        <span className='font-weight-bold'>{decode(props.log.attributes.clinician?.name || '')}</span>
        {dateSection}
      </>
    )
  }

  return (
    <>
      <Modal show={showReportModal} onHide={() => setShowReportModal(false)}>
        <div className='report-preview-content-wrapper'>
          <InfoModal
            size='variable'
            title='Saved dose report'
            subtitle={saveDoseReportSubtitle()}
            message={
              base64Preview ? (
                window.env.VENDOR_MODE === 'epic' ? (
                  <div
                    className='dose-report-preview-panel-epic'
                    dangerouslySetInnerHTML={{
                      __html: atob(base64Preview)
                    }}
                  />
                ) : (
                  <iframe className='dose-report-preview-panel' srcDoc={atob(base64Preview)} />
                )
              ) : (
                <div className='dose-report-preview-panel-loading'>
                  <Icons.ThinSpinner strokeWidth={12} r={30} stroke={LOADING_GREY} width='60px' />
                </div>
              )
            }
            linkComponent={saveDoseReportDownloadButton()}
            onDismiss={() => setShowReportModal(false)}
            limitHeightToWindow
          />
        </div>
      </Modal>

      <div className='activity-log-entry-outer'>
        <div className='activity-log-entry-icon d-flex'>
          <Icons.UserIcon height={24} width={24} />
          <div className='activity-log-entry-date'>
            {formatToDisplayDate(props.log.attributes.created, props.hospital!.attributes.timezone)}
          </div>
        </div>

        <div className='activity-log-entry'>
          <div className='activity-log-entry-border'>
            <div className='activity-log-entry-comment'>
              <span className='font-weight-bold'>{decode(props.log.attributes.clinician?.name || '')}</span>
              <span> {props.log.attributes.message}</span>
              {getReportActivityLogDetails()?.map((item, key) => (
                <div className='pt-1' key={`log-entry-${key}-${item}`}>
                  <div className='activity-log-details-wrapper'>{item}</div>
                </div>
              ))}
              {/* move to own function */}
              {formatReportActivityLogNote()}
              {props.log.attributes.report_link &&
                <ListButton
                  className='mt-2'
                  size='md'
                  onClick={() => {
                    setShowReportModal(true)
                    handleGetReportRequest(true, props.log.attributes.report_link as string)
                  }}
                >
                  View saved report
                </ListButton>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
