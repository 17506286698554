import {
  ITab,
  FloatingTabBar,
  MAGENTA,
  OCEAN_BLUE,
  EMERALD,
  FADED_RED,
  IDropdownItem,
  ListButton,
  Icons,
  ACTIVE_GOLD,
  IconButton,
  InfoBubble,
  ITimeState,
  MessageOverlay,
  IDosingMatrixValues,
  ActionButton
} from '@doseme/cohesive-ui'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { format, parseISO } from 'date-fns'
import ReactTooltip from 'react-tooltip'

import { NextDoseAtDateTime } from './components/NextDoseAtDateTime'
import { ICustomTargetForms, TTargetTypes } from './types'
import {
  useAdministrationsStore,
  useCourseFeaturesStore,
  useCourseStore,
  useDosingRecommendationStore,
  useObservationsStore,
  useDosingMatrixStore,
  useHistoricalSimulationStore
} from '../../../../../../../../hooks/useStore'
import {
  IDrugSpecificAttr,
  IModelDoseRecommendation,
  IPerDoseOutcome,
  IPredictedOutcome,
  TModelType
} from '../../../../../../../../store/dosingRecommendation/types'
import { ICourseCustomTarget, ICourseLimits } from '../../../../../../../../store/course/types'
import { IndPopOrGuidelineTab } from './displayLogic/indPopOrGuideline'
import { CustomTab } from './displayLogic/custom'
import {
  createCustomDoseForms,
  createCustomTargetForms,
  buildCustomDoseAttributes,
  buildDefaultNextDoseAttributes
} from './constants'
import {
  getModelTypeName,
  isValidTimeInput,
  getFirstSeCrObservation,
  buildCustomizedTargetAttributes,
  getLastAdministration,
  getLastSeCrObservation,
  getCalcTypeName,
  getDoseDescription
} from './utils'
import { GenerateReportModal } from './components/GenerateReportModal'
import { DosingMatrixModal } from './components/DosingMatrixModal'
import { dateFnsRawDateOnly, dateFnsTableReadable, rawTimeOnly } from '../../../../../../../../constants/timeFormat'
import { isValidDSTDateTime } from '../../../../../../../../utils/dates'
import { WarningsDisplay } from './components/WarningsDisplay'
import { TCustomType, TSimulationFilter, TSimulationPanelType } from '../../types'
import { ValidationErrorsDisplay } from './components/ValidationErrorsDisplay'
import { sortAdministrationsByDateAsc, sortObservationsByDateAsc } from '../../../../../../../../utils/sorting'
import {
  calculateDefaultNextDoseTime,
  calculateMaxNextDoseTime,
  calculateMinNextDoseTime
} from './components/NextDoseAtDateTime/utils/dateProps'
import { InfoPopupModal } from './components/InfoPopupModal'
import { formatErrorMessage } from '../../utils'
import { modelTypeDisplayText } from '../../../../../../../../constants/modelTypes'
import { SimulateHDScheduleModal } from './components/SimulateHDScheduleModal'
import { TDIInfoModal } from './components/TDIInfoModal'

import './index.scss'

interface IProps {
  hospitalTimezone: string
  patientId: string
  courseId: string
  drugModelId: string
  limits: ICourseLimits
  currentModel: TModelType
  isIndividualized: boolean
  currentSimulation: IModelDoseRecommendation | null
  setCurrentModel: (value: React.SetStateAction<TModelType>) => void
  setIndividualized: (value: React.SetStateAction<boolean>) => void
  customTypeTab: TCustomType
  setCustomTypeTab: (type: TCustomType) => void
  setCurrentSimulation: (value: React.SetStateAction<IModelDoseRecommendation | null>) => void
}

const tdmTabs: ITab[] = [
  { id: 'indPop', displayName: 'Individualized', underlineColor: OCEAN_BLUE },
  { id: 'custom', displayName: 'Custom', underlineColor: EMERALD },
  { id: 'guideline', displayName: 'Guideline', underlineColor: MAGENTA }
]

const nonTdmTabs: ITab[] = [
  { id: 'indPop', displayName: 'Population', underlineColor: FADED_RED },
  { id: 'custom', displayName: 'Custom', underlineColor: EMERALD },
  { id: 'guideline', displayName: 'Guideline', underlineColor: MAGENTA }
]

export const SimulationPanel: React.FC<IProps> = observer((props) => {
  const [defaultLastPredictedDoseOutcomes, setDefaultLastPredictedDoseOutcomes] = useState<IPerDoseOutcome | null>(null)
  const [predictedOutcome, setPredictedOutcome] = useState<IPredictedOutcome | null>(null)
  const [currentWarnings, setCurrentWarnings] = useState<string[]>([])

  const [customValidationErrors, setCustomValidationErrors] = useState<Array<[string, string]>>([])
  const [currentInfo, setCurrentInfo] = useState<string[]>([])

  const [activeSimTab, setActiveSimTab] = useState<TSimulationFilter | null>()

  const [initialStateCalculated, setInitialStateCalculated] = useState<boolean>(false)

  const [showDosingMatrixPanel, setShowDosingMatrixPanel] = useState<boolean>(false)
  const [dosingMatrixCustomSimulationValue, setDosingMatrixCustomSimulationValue] =
    useState<IDosingMatrixValues | null>(null)

  const [showSimulateHDScheduleModal, setShowSimulateHDScheduleModal] = useState<TSimulationPanelType>()

  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const courseStore = useCourseStore()
  const courseFeaturesStore = useCourseFeaturesStore()
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const dosingMatrixStore = useDosingMatrixStore()

  const [isNextDoseValidDateTime, setNextDoseValidDateTime] = useState<boolean>(true)
  const [nextDoseAt, setNextDoseAt] = useState<Date>(new Date())
  const [nextDoseAtDate, setNextDoseAtDate] = useState<Date>(parseISO(format(nextDoseAt, dateFnsRawDateOnly)))
  const [nextDoseAtTime, setNextDoseAtTime] = useState<ITimeState>({
    hh: nextDoseAt.getHours().toString().padStart(2, '0'),
    mm: nextDoseAt.getMinutes().toString().padStart(2, '0')
  })

  const currentDate = new Date()
  const [minDate, setMinDate] = useState<Date | null>(currentDate) // set these two to the current time for safety purposes
  const [maxDate, setMaxDate] = useState<Date | null>(currentDate)
  const maximumDosingIntervalHours = courseStore.course!.attributes.limits.dosingPeriod.max.value
  const defaultIntervalHours = courseStore.course!.attributes.limits.dosingPeriod.default.value

  const [allowedCustomTargets, setAllowedCustomTargets] = useState<IDropdownItem[] | null>(null)
  const [selectedTargetDropdownItem, setSelectedTargetDropdownItem] = useState<IDropdownItem>({ value: '', label: '' })

  const [showReportModal, setShowReportModal] = useState(false)
  const [currentModelWarningsChecked, setCurrentModelWarningsChecked] = useState(false)
  const [errorDismissed, setErrorDismissed] = useState(false)
  const [showInfoPopupModal, setShowInfoPopupModal] = useState(false)
  const [showInfoTDI, setShowInfoTDI] = useState(false)
  const [infoTDIContinue, setInfoTDIContinue] = useState<() => void>(() => {})

  const initialDrugSpecificAttr = {
    drugModelId: props.drugModelId,
    attributes: {
      plannedHemodialysisSessions: []
    }
  }

  const [drugSpecificAttr, setDrugSpecificAttr] = useState<IDrugSpecificAttr>(initialDrugSpecificAttr)
  const [requiresReview, setRequiresReview] = useState<boolean>(false)

  const customTargetForms = createCustomTargetForms(props.limits)
  const customDoseForms = createCustomDoseForms(props.limits, dosingMatrixCustomSimulationValue)

  const sortedIncludedAdministrations = useMemo(() => {
    const administrations = [...administrationsStore.administrations.values()].filter(
      (x) => !x.attributes.excludeFromCalculations
    )

    return sortAdministrationsByDateAsc(administrations)
  }, [administrationsStore.loadState])

  const sortedIncludedSeCrObservations = useMemo(() => {
    const seCrObservations = [...observationsStore.observations.values()].filter(
      (x) => x.attributes.observationType.name === 'Serum Creatinine' && !x.attributes.excludeFromCalculations
    )

    return sortObservationsByDateAsc(seCrObservations)
  }, [observationsStore.loadState])

  // This useEffect updates the min and max next dose times when the historical data changes.
  // It will also reset the date and time pickers to the new default next dose time
  useEffect(() => {
    if (historicalSimulationStore.loadState === 'loaded') {
      //Clear the dosingRecommendationStore when the historicalSimulationStore is reloaded
      dosingRecommendationStore.resetStore()

      const isIndividualized =
        !!historicalSimulationStore.historicalSimulationData?.attributes.plotData?.individualized?.plotPoints

      props.setIndividualized(isIndividualized)

      const lastAdministration = getLastAdministration(sortedIncludedAdministrations)
      const lastSeCrObservation = getLastSeCrObservation(sortedIncludedSeCrObservations)
      const firstSeCrObservation = getFirstSeCrObservation(sortedIncludedSeCrObservations)

      // default, max and min are all naive Date objects assumed to be in hospital timezone
      const dosingInterval = historicalSimulationStore.historicalSimulationData?.attributes.assumedDosingInterval || defaultIntervalHours

      const defaultNextDoseTime = calculateDefaultNextDoseTime(
        props.hospitalTimezone,
        dosingInterval,
        lastAdministration?.attributes.administeredAt.value,
        lastSeCrObservation?.attributes.observedAt.value
      )

      const minNextDoseTime = calculateMinNextDoseTime(props.hospitalTimezone, lastAdministration, firstSeCrObservation)

      const maxNextDoseTime = calculateMaxNextDoseTime(
        props.hospitalTimezone,
        maximumDosingIntervalHours,
        lastAdministration,
        lastSeCrObservation
      )

      setNextDoseAt(defaultNextDoseTime)
      setNextDoseAtDate(parseISO(format(defaultNextDoseTime, dateFnsRawDateOnly)))
      setNextDoseAtTime({
        hh: defaultNextDoseTime.getHours().toString().padStart(2, '0'),
        mm: defaultNextDoseTime.getMinutes().toString().padStart(2, '0')
      })
      setNextDoseValidDateTime(true)
      setMinDate(minNextDoseTime)
      setMaxDate(maxNextDoseTime)

      //update custom forms
      customTargetForms['auc24'].validateFields(
        [
          {
            field: 'dosingInterval',
            input: dosingInterval.toString(),
            constraints: props.limits.dosingPeriod
          }
        ],
        'updateFieldsDisplay'
      )
      customTargetForms['auc'].validateFields(
        [
          {
            field: 'dosingInterval',
            input: dosingInterval.toString(),
            constraints: props.limits.dosingPeriod
          }
        ],
        'updateFieldsDisplay'
      )
      customTargetForms['peak/trough'].validateFields(
        [
          {
            field: 'dosingInterval',
            input: dosingInterval.toString(),
            constraints: props.limits.dosingPeriod
          }
        ],
        'updateFieldsDisplay'
      )
      customTargetForms['troughonly'].validateFields(
        [
          {
            field: 'dosingInterval',
            input: dosingInterval.toString(),
            constraints: props.limits.dosingPeriod
          }
        ],
        'updateFieldsDisplay'
      )
      customTargetForms['peakonly'].validateFields(
        [
          {
            field: 'dosingInterval',
            input: dosingInterval.toString(),
            constraints: props.limits.dosingPeriod
          }
        ],
        'updateFieldsDisplay'
      )
      customDoseForms['dose'].validateFields(
        [
          {
            field: 'dosingInterval',
            input: dosingInterval.toString(),
            constraints: props.limits.dosingPeriod
          }
        ],
        'updateFieldsDisplay'
      )

      const limitOverrides =
        historicalSimulationStore.historicalSimulationData?.attributes.limitsOverridesByDosingType

      if (limitOverrides) {
        customTargetForms['cumulative_auc'].validateFields(
          [
            {
              field: 'remainingAUC',
              input: limitOverrides.cumulative_auc.cumulativeAUCTarget.default.value.toString(),
              constraints: props.limits.cumulativeAUCTarget
            },
            {
              field: 'numberOfDoses',
              input: limitOverrides.cumulative_auc.numberOfDoses.default.value.toString(),
              constraints: props.limits.numberOfDoses
            }
          ],
          'updateFieldsDisplay'
        )
      }
    }
  }, [historicalSimulationStore.loadState])

  // This useEffect sets the errorDismissed flag to false everytime a new dose recommendation is calculated
  useEffect(() => {
    if (['loadError', 'updateError', 'loaded'].includes(dosingRecommendationStore.loadState) && errorDismissed) {
      setErrorDismissed(false)
    }
  }, [dosingRecommendationStore.loadState])

  // This useEffect sets the nextDoseAt state var whenever the date and time inputs are valid.
  useEffect(() => {
    const isTimeValid = isValidTimeInput(nextDoseAtTime)
    setNextDoseValidDateTime(isTimeValid)

    if (isTimeValid) {
      setNextDoseAt(
        parseISO(format(nextDoseAtDate, dateFnsRawDateOnly)! + 'T' + nextDoseAtTime.hh + ':' + nextDoseAtTime.mm)
      )
    }
  }, [nextDoseAtDate, nextDoseAtTime])

  // It sets the current model based on the tab state.
  // Will most likely be individualized or population, but all other
  // possible values have been added for compatibility with any future changes.
  useEffect(() => {
    if (activeSimTab === 'custom') {
      if (props.customTypeTab === 'target') {
        props.setCurrentModel('customTarget')

        return
      }

      props.setCurrentModel('customDose')

      return
    }

    if ((!activeSimTab && props.isIndividualized) || activeSimTab === 'indPop') {
      props.setCurrentModel('indPop')

      return
    }

    props.setCurrentModel('guideline')
  }, [activeSimTab, props.customTypeTab, props.isIndividualized])

  // This useEffect sets the individualized status of the model after any model is calculated and store updated.
  // If the model is not individualized, it will also set the active tab (current model) to Guideline.
  // We prioritize Guideline results over the population model.
  useEffect(() => {
    if (dosingRecommendationStore.loadState === 'loaded') {
      // Dynamically set the active tab for the first simulation
      if (!activeSimTab && initialStateCalculated) {
        let activeTab: TSimulationFilter = 'indPop'

        //FIXME: This needs to be sent from the backend with the drug model information
        const hasGuidelineResults =
          !!dosingRecommendationStore.dosingRecommendation.guideline?.attributes.modelResults?.guideline

        if (!props.isIndividualized && hasGuidelineResults) {
          activeTab = 'guideline'
        }

        setActiveSimTab(activeTab)
      }
    }
  }, [dosingRecommendationStore.loadState])

  //fetch the dosing matrix data
  useEffect(() => {
    if (
      showDosingMatrixPanel &&
      dosingRecommendationStore.dosingRecommendation.indPop?.attributes.supportsAlternativeDosing
    ) {
      const formattedDateString = moment
        .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), props.hospitalTimezone)
        .utc()
        .toISOString()
      dosingMatrixStore.fetchDosingMatrix(props.patientId, props.courseId, props.drugModelId, formattedDateString)
    }
  }, [showDosingMatrixPanel])

  useEffect(() => {
    // this useEffect will be triggered whenever a user selects a value in the dosing matrix
    // it will close the modal->set values in the custom form->reset the predicted data and current simulation
    if (dosingMatrixCustomSimulationValue) {
      customDoseForms['dose'].reset()
      setShowDosingMatrixPanel(false)
      props.setCustomTypeTab('dose')
      setActiveSimTab('custom')
      props.setCurrentSimulation(null)
      dosingRecommendationStore.resetPredictedData('customDose')
    }
  }, [dosingMatrixCustomSimulationValue])

  const updateCustomFormValidationState = (customKey: string): void => {
    if (customKey === 'dose') {
      const errorMap = customDoseForms['dose'].updateFieldsDisplay(
        Object.keys(customDoseForms['dose'].inputs),
        'returnErrorMap'
      )

      setCustomValidationErrors(Array.from(errorMap.entries()))

      return
    }

    const errorMap = customTargetForms[customKey as keyof ICustomTargetForms].updateFieldsDisplay(
      Object.keys(customTargetForms[customKey as keyof ICustomTargetForms].inputs),
      'returnErrorMap'
    )

    setCustomValidationErrors(Array.from(errorMap.entries()))
  }

  // This useEffect ensures the custom target form is revalidated when the target dropdown value changes.
  useEffect(() => {
    if (selectedTargetDropdownItem.value) {
      updateCustomFormValidationState(selectedTargetDropdownItem.value)
    }
  }, [selectedTargetDropdownItem.value])

  // This useEffect has a number of triggers (see dependency array).
  // It invalidates the last calculation for the current model when any of the dependencies are changed.
  useEffect(() => {
    props.setCurrentSimulation(null)
    setCurrentWarnings([])
    setCurrentInfo([])
    setShowDosingMatrixPanel(false)

    if (['customTarget', 'customDose'].includes(props.currentModel)) {
      dosingRecommendationStore.resetPredictedData(props.currentModel)

      if (props.currentModel === 'customTarget') {
        dosingRecommendationStore.setCustomTargetCalculationValid(false)
      }

      if (props.currentModel === 'customDose') {
        dosingRecommendationStore.setCustomDoseCalculationValid(false)
      }
    }
  }, [
    nextDoseAtDate,
    nextDoseAtTime,
    selectedTargetDropdownItem,
    customTargetForms['auc24'].jsonState,
    customTargetForms['auc'].jsonState,
    customTargetForms['peak/trough'].jsonState,
    customTargetForms['troughonly'].jsonState,
    customTargetForms['peakonly'].jsonState,
    customTargetForms['extended_interval'].jsonState,
    customTargetForms['timetotrough'].jsonState,
    customTargetForms['time_above_mic'].jsonState,
    customTargetForms['range'].jsonState,
    customTargetForms['cumulative_auc'].jsonState,
    customDoseForms['dose'].jsonState,
    administrationsStore.loadState,
    observationsStore.loadState,
    courseFeaturesStore.loadState,
    courseStore.loadState
  ])

  useEffect(() => {
    if (courseStore.loadState === 'loaded') {
      setDrugSpecificAttr(initialDrugSpecificAttr)
    }
  }, [nextDoseAt, courseStore.loadState])

  useEffect(() => {
    dosingRecommendationStore.resetPredictedData()
  }, [nextDoseAt, nextDoseAtDate, nextDoseAtTime])

  // This "main" useEffect triggers when the dosing recommendation store is updated,
  // OR when current model is switched (most likely via tabs).
  // It handles all of the updates required to the base simpanel state on each event.
  // The dosingRecommendation loadState has to be 'loaded' for anything to happen, however.
  useEffect(() => {
    //must have the updateError condition here in case only a single tab is erroring...even if they all are there is still no issue
    if (dosingRecommendationStore.loadState === 'loaded' || dosingRecommendationStore.loadState === 'updateError') {
      const modelResults =
        dosingRecommendationStore.dosingRecommendation[props.currentModel]?.attributes.modelResults
      // Below conditional is for invalidated custom model results, or if predicted model comes back null.
      // A custom model which has been calculated is "invalidated" when its form fields are changed.
      // In this case, the predicted doses and custom chart of the last valid calculation
      // for the current model remain in the interface, but no outcomes will be displayed and no report
      // can be generated for the current custom model until a new valid calculation is made.
      // Note: if the predicted model comes back null, an error will also display in the charting area.
      if (
        !modelResults ||
        (props.currentModel === 'customTarget' && !dosingRecommendationStore.customTargetCalculationValid) ||
        (props.currentModel === 'customDose' && !dosingRecommendationStore.customDoseCalculationValid)
      ) {
        // validate custom target fields after change
        if (props.currentModel === 'customTarget') {
          if (selectedTargetDropdownItem.value) {
            updateCustomFormValidationState(selectedTargetDropdownItem.value)
          }
        }

        // or validate custom dose fields after change
        if (props.currentModel === 'customDose') {
          updateCustomFormValidationState('dose')
        }
        props.setCurrentSimulation(null)
        setCurrentWarnings([])
        setCurrentInfo([])
        setDefaultLastPredictedDoseOutcomes(null)

        return
      }

      if (props.currentModel === 'customTarget') {
        // prevents residual form errors from previous tabs when switching tabs (switching current model)
        if (selectedTargetDropdownItem.value) {
          updateCustomFormValidationState(selectedTargetDropdownItem.value)
        }
        props.setCurrentSimulation(modelResults.customTarget?.doseRecommendation || null)
        setCurrentWarnings(modelResults.customTarget?.warnings || [])
        setCurrentInfo(modelResults.customTarget?.info || [])
        setPredictedOutcome(modelResults.customTarget?.predictedOutcome || null)
        const defaultCustomOutcomes = modelResults.customTarget?.predictedPerDoseOutcomes
        setDefaultLastPredictedDoseOutcomes(defaultCustomOutcomes?.[defaultCustomOutcomes.length - 1] || null)

        return
      }

      if (props.currentModel === 'customDose') {
        props.setCurrentSimulation(modelResults.customDose?.doseRecommendation || null)
        setCurrentWarnings(modelResults.customDose?.warnings || [])
        setCurrentInfo(modelResults.customDose?.info || [])
        setPredictedOutcome(modelResults.customDose?.predictedOutcome || null)
        const defaultCustomOutcomes = modelResults.customDose?.predictedPerDoseOutcomes
        setDefaultLastPredictedDoseOutcomes(defaultCustomOutcomes?.[defaultCustomOutcomes.length - 1] || null)

        return
      }

      if (props.currentModel === 'guideline') {
        // prevents residual form errors from previous tabs when switching tabs (switching current model)
        setCustomValidationErrors([])
        props.setCurrentSimulation(modelResults.guideline?.doseRecommendation || null)
        const availableGuidelineModel = modelResults.guideline
        setCurrentWarnings(availableGuidelineModel?.warnings || [])
        setCurrentInfo(availableGuidelineModel?.info || [])
        setPredictedOutcome(availableGuidelineModel?.predictedOutcome || null)
        const availableGuidelineOutcomes = availableGuidelineModel?.predictedPerDoseOutcomes
        setDefaultLastPredictedDoseOutcomes(availableGuidelineOutcomes?.[availableGuidelineOutcomes.length - 1] || null)

        return
      }

      if (props.currentModel === 'indPop') {
        setCustomValidationErrors([])
        props.setCurrentSimulation(modelResults.indPop?.doseRecommendation || null)
        const availableIndPopModel = modelResults.indPop
        setCurrentWarnings(availableIndPopModel?.warnings || [])
        setCurrentInfo(availableIndPopModel?.info || [])
        setPredictedOutcome(availableIndPopModel?.predictedOutcome || null)
        const availableIndPopOutcomes = availableIndPopModel?.predictedPerDoseOutcomes
        setDefaultLastPredictedDoseOutcomes(availableIndPopOutcomes?.[availableIndPopOutcomes.length - 1] || null)

        return
      }

      // default condition, currently used for custom target/dose where fields have changed without calc
      props.setCurrentSimulation(null)
      setCurrentWarnings([])
      setCurrentInfo([])
      setPredictedOutcome(null)
      setDefaultLastPredictedDoseOutcomes(null)
    }
  }, [props.currentModel, dosingRecommendationStore.loadState])

  // This useEffect updates the custom target dropdown options.
  // Unlikely to trigger often - only when the base course is updated.
  useEffect(() => {
    if (courseStore.loadState === 'loaded' && courseStore.course) {
      setDrugSpecificAttr(initialDrugSpecificAttr)

      const defaultCustomTarget = courseStore.course.attributes.simulateNextDose.defaultCustomTarget
      const allowedCustomTargets = courseStore.course.attributes.simulateNextDose.allowedCustomTargets

      if (allowedCustomTargets) {
        const actDropdownItems = [...allowedCustomTargets].reduce<IDropdownItem[]>((acc, curr) => {
          return acc.concat({
            value: curr.target,
            label: curr.shortName
          })
        }, [])

        setAllowedCustomTargets(actDropdownItems)

        const isDefaultTargetAllowed =
          !!defaultCustomTarget &&
          [...allowedCustomTargets].some(
            (allowedTarget: ICourseCustomTarget) => allowedTarget.target === defaultCustomTarget.target
          )

        const targetDropdownItem = isDefaultTargetAllowed ? defaultCustomTarget : allowedCustomTargets[0]

        setSelectedTargetDropdownItem({
          value: targetDropdownItem.target,
          label: targetDropdownItem.name
        })
      }
    }
  }, [courseStore.loadState])

  // This useEffect ensures that the user has to reconfirm the model warnings
  // before generating a report. Triggers when model warnings change.
  useEffect(() => {
    if (currentWarnings.length) {
      setCurrentModelWarningsChecked(false)

      return
    }

    // no warnings so implicitly allow
    setCurrentModelWarningsChecked(true)
  }, [currentWarnings])

  //fetch predicted data when the simulateHDSchedule form is returned
  useEffect(() => {
    if (showSimulateHDScheduleModal) {
      dosingRecommendationStore.resetPredictedData()

      handleShowSimulateHDScheduleModal(showSimulateHDScheduleModal)
    }

    setShowSimulateHDScheduleModal(undefined)
  }, [drugSpecificAttr])

  useEffect(() => {
    if (courseFeaturesStore.loadState === 'loaded' && courseFeaturesStore.courseFeatures) {
      const newRequireReview = [...courseFeaturesStore.courseFeatures].some(
        (e) =>
          e[1].attributes.requiresReview &&
          (e[1].attributes.currentValue === null || e[1].attributes.currentValue === undefined)
      )
      setRequiresReview(newRequireReview)
      if (newRequireReview) {
        setInitialStateCalculated(false)
      }
    }
  }, [courseFeaturesStore.loadState])

  const handleShowSimulateHDScheduleModal = (type: TSimulationPanelType) => {
    if (!drugSpecificAttr) {
      setShowSimulateHDScheduleModal(type)

      return
    }

    handleSimulation(type)
  }

  const handleSimulation = (type: TSimulationPanelType) => {
    let simulationCallback: () => void = () => { }

    switch (type) {
      case 'initial':
        simulationCallback = () => {
          handleIndPopOrGuidelineCalculation()
          setInitialStateCalculated(true)
        }
        break
      case 'guideline':
      case 'indPop':
        simulationCallback = () => {
          handleIndPopOrGuidelineCalculation()
        }
        break
      case 'customDose':
        simulationCallback = () => {
          handleCustomDoseCalculation()
        }
        break
      case 'customTarget':
        simulationCallback = () => {
          handleCustomTargetCalculation()
        }
        break
    }

    if (window.env.INSTANCE_TYPE === 'TDI') {
      setShowInfoTDI(true)
      setInfoTDIContinue(() => simulationCallback)

      return
    }

    simulationCallback()
  }

  const handleIndPopOrGuidelineCalculation = (): void => {
    const nextDoseDate = moment
      .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), props.hospitalTimezone)
      .utc()
      .toISOString()

    dosingRecommendationStore.fetchPredictedDosingRecommendation(
      props.patientId,
      props.courseId,
      props.drugModelId,
      'DefaultNextDoseSimulation',
      buildDefaultNextDoseAttributes(nextDoseAt, props.hospitalTimezone),
      ['indPop', 'guideline'],
      nextDoseDate,
      drugSpecificAttr
    )
  }

  const handleCustomTargetCalculation = (): void => {
    if (!selectedTargetDropdownItem) {
      return
    }

    dosingRecommendationStore.fetchPredictedDosingRecommendation(
      props.patientId,
      props.courseId,
      props.drugModelId,
      'CustomizedTargetSimulation',
      buildCustomizedTargetAttributes(
        customTargetForms,
        selectedTargetDropdownItem.value as TTargetTypes,
        props.limits,
        nextDoseAt,
        props.hospitalTimezone
      ),
      ['customTarget'],
      moment
        .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), props.hospitalTimezone)
        .utc()
        .toISOString(),
      drugSpecificAttr
    )
  }

  const handleCustomDoseCalculation = (): void => {
    dosingRecommendationStore.fetchPredictedDosingRecommendation(
      props.patientId,
      props.courseId,
      props.drugModelId,
      'CustomDoseSimulation',
      buildCustomDoseAttributes(customDoseForms, props.limits, nextDoseAt, props.hospitalTimezone),
      ['customDose'],
      moment
        .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), props.hospitalTimezone)
        .utc()
        .toISOString(),
      drugSpecificAttr
    )
  }

  const disableTabs = ![
    administrationsStore.loadState,
    observationsStore.loadState,
    courseFeaturesStore.loadState,
    courseStore.loadState,
    dosingRecommendationStore.loadState
  ].every((x) => !['loading', 'updating'].includes(x))

  const tabContent = (): JSX.Element => {
    // Custom
    if (['customTarget', 'customDose'].includes(props.currentModel)) {
      return (
        <CustomTab
          hospitalTimezone={props.hospitalTimezone}
          nextDoseAt={isNextDoseValidDateTime ? nextDoseAt : null}
          nextDoseAtTime={nextDoseAtTime}
          minDate={minDate}
          maxDate={maxDate}
          calculationDisabled={historicalSimulationStore.loadState !== 'loaded' || requiresReview}
          calculated={props.currentSimulation}
          customTargetCalculationValid={dosingRecommendationStore.customTargetCalculationValid}
          customDoseCalculationValid={dosingRecommendationStore.customDoseCalculationValid}
          perDoseOutcomes={defaultLastPredictedDoseOutcomes}
          predictedOutcome={predictedOutcome}
          storeLoadState={dosingRecommendationStore.loadState}
          currentModel={props.currentModel}
          customType={props.customTypeTab}
          aucTargetLimit={props.limits.aucTarget}
          doseLimit={props.limits.doseClinical}
          peakTargetLimit={props.limits.peakTarget}
          troughTargetLimit={props.limits.troughTarget}
          infusionLengthLimit={props.limits.infusionLength}
          dosingPeriodLimit={props.limits.dosingPeriod}
          numberOfDosesLimit={props.limits.numberOfDoses}
          timeAboveMICTargetLimit={props.limits.timeAboveMICTarget}
          cumulativeAUCTarget={props.limits.cumulativeAUCTarget}
          customTargetForm={customTargetForms}
          customDoseForm={customDoseForms}
          allowedCustomTargets={allowedCustomTargets}
          selectedTargetDropdownItem={selectedTargetDropdownItem}
          disableTabs={disableTabs}
          setSelectedTargetDropdownItem={setSelectedTargetDropdownItem}
          setCustomType={props.setCustomTypeTab}
          setCustomValidationErrors={setCustomValidationErrors}
          handleCustomTargetCalculation={() => handleSimulation('customTarget')}
          handleCustomDoseCalculation={() => handleSimulation('customDose')}
          setShowSimulateHDScheduleModal={handleShowSimulateHDScheduleModal}
          drugModelName={courseStore.course?.attributes.drugModel.name}
          drugModelId={courseStore.course?.attributes.drugModel.id}
          defaultCustomTarget={courseStore.course!.attributes.simulateNextDose.defaultCustomTarget}
        />
      )
    }

    const modelPrettyName = getModelTypeName(props.currentModel, props.isIndividualized)
    const showDosingMatrixButton =
      modelPrettyName === 'Individualized' &&
      !!props.currentSimulation &&
      !props.currentSimulation.withhold &&
      !!defaultLastPredictedDoseOutcomes &&
      !!dosingRecommendationStore.dosingRecommendation.indPop?.attributes.supportsAlternativeDosing

    // Individualized / Population
    return (
      <IndPopOrGuidelineTab
        calculationDisabled={historicalSimulationStore.loadState !== 'loaded' || requiresReview}
        hospitalTimezone={props.hospitalTimezone}
        nextDoseAt={isNextDoseValidDateTime ? nextDoseAt : null}
        nextDoseAtTime={nextDoseAtTime}
        minDate={minDate}
        maxDate={maxDate}
        calculated={props.currentSimulation}
        perDoseOutcomes={defaultLastPredictedDoseOutcomes as IPerDoseOutcome}
        predictedOutcome={predictedOutcome}
        isDefaultDosingIntervalAssumedPredicted={
          !!dosingRecommendationStore.dosingRecommendation['indPop']?.attributes.assumedDosingInterval
        }
        storeLoadState={dosingRecommendationStore.loadState}
        selectedSimulationPanelTab={props.currentModel}
        modelPrettyName={getModelTypeName(props.currentModel, props.isIndividualized)}
        aucTargetLimit={props.limits.aucTarget}
        peakTargetLimit={props.limits.peakTarget}
        troughTargetLimit={props.limits.troughTarget}
        timeAboveMICTargetLimit={props.limits.timeAboveMICTarget}
        showDosingMatrixButton={showDosingMatrixButton}
        defaultCustomTarget={courseStore.course!.attributes.simulateNextDose.defaultCustomTarget}
        activeSimTab={activeSimTab}
        setShowDosingMatrixPanel={setShowDosingMatrixPanel}
        setShowInfoPopupModal={setShowInfoPopupModal}
        handleCalculation={() => handleSimulation('indPop')}
        setShowSimulateHDScheduleModal={handleShowSimulateHDScheduleModal}
        drugModelId={courseStore.course?.attributes.drugModel.id}
      />
    )
  }

  const overlaidContent = (children: JSX.Element) => {
    if (!errorDismissed && ['loaded', 'loadError', 'updateError'].includes(dosingRecommendationStore.loadState)) {
      if (dosingRecommendationStore.error[props.currentModel]) {
        return (
          <MessageOverlay
            type='error'
            headerText='Failed to load dosing recommendation'
            messageText={
              <div className='panel-error-overlay-text'>
                {formatErrorMessage(dosingRecommendationStore.error[props.currentModel])}
                <div className='overlay-message-button'>
                  <ListButton size='md' onClick={() => setErrorDismissed(true)}>
                    Dismiss
                  </ListButton>
                </div>
              </div>
            }
          >
            {children}
          </MessageOverlay>
        )
      }

      const message =
        dosingRecommendationStore.dosingRecommendation[props.currentModel]?.attributes.modelResults?.message[props.currentModel]

      if (message) {
        return (
          <MessageOverlay
            type='info'
            headerText={`${modelTypeDisplayText[props.currentModel]} is unavailable`}
            messageText={
              <div className='panel-error-overlay-text'>
                {formatErrorMessage(message)}
                <div className='overlay-message-button'>
                  <ListButton size='md' onClick={() => setErrorDismissed(true)}>
                    Dismiss
                  </ListButton>
                </div>
              </div>
            }
          >
            {children}
          </MessageOverlay>
        )
      }
    }

    return children
  }

  const currentCalculatedDosingRegimen = (): JSX.Element => {
    const hydrateRegimen = (calcType: string, doseDescription: string): JSX.Element => {
      return (
        <div className='course-regimen-text'>
          <span className='font-bold'>{calcType} regimen:&nbsp;</span>
          {doseDescription}
        </div>
      )
    }

    return hydrateRegimen(
      getCalcTypeName(props.currentModel, props.isIndividualized),
      getDoseDescription(props.currentSimulation)
    )
  }

  const editSimulatedHDBar = (): JSX.Element => {
    const currentModelResult =
      dosingRecommendationStore.dosingRecommendation[props.currentModel]?.attributes.modelResults
    const hdScheduleString =
      currentModelResult?.[props.currentModel]?.drugSpecificResults?.hdScheduleString

    return (
      <div
        className={classnames(
          'edit-bar',
          'w-100',
          'd-flex',
          'justify-content-between',
          'align-items-center',
          props.currentSimulation ? 'edit-bar-enabled' : 'edit-bar-disabled'
        )}
      >
        <div className='course-regimen-text'>
          <span className='font-bold'>Simulated HD:&nbsp;</span>
          {hdScheduleString}
        </div>
        <ActionButton
          actionType='edit'
          className='generate-report-list-button'
          size='md'
          disabled={!props.currentSimulation || props.currentModel === 'guideline'}
          onClick={() => props.currentSimulation && setShowSimulateHDScheduleModal(props.currentModel)}
        />
      </div>
    )
  }

  const reportBar: JSX.Element = (
    <div
      className={classnames(
        'report-bar',
        'w-100',
        'd-flex',
        'justify-content-between',
        'align-items-center',
        props.currentSimulation ? 'report-bar-enabled' : 'report-bar-disabled'
      )}
    >
      {currentCalculatedDosingRegimen()}
      <ListButton
        className='generate-report-list-button'
        size='md'
        disabled={!props.currentSimulation}
        onClick={() => props.currentSimulation && setShowReportModal(true)}
      >
        Generate Report
      </ListButton>
    </div>
  )

  const calculationInfo = (): JSX.Element[] | null => {
    const displayCurrentInfo: string[] = window.env.INSTANCE_TYPE === 'TDI'
      ? currentInfo.concat('This dose recommendation is for teaching and/or learning purposes only.')
      : currentInfo

    if (displayCurrentInfo.length) {
      return displayCurrentInfo.map<JSX.Element>((info, i) => (
        <div className='mt-3' key={`${info}-${i}`}>
          <InfoBubble bubbleTitle={info} />
        </div>
      ))
    }

    return null
  }

  const calculationWarnings = (): JSX.Element | null => {
    if (currentWarnings.length) {
      return <WarningsDisplay display='simulation' warnings={currentWarnings} />
    }

    return null
  }

  const initialCalcButton = (): JSX.Element => {
    if (
      [administrationsStore.loadState, observationsStore.loadState, dosingRecommendationStore.loadState].includes(
        'loading'
      ) ||
      [administrationsStore.loadState, observationsStore.loadState, dosingRecommendationStore.loadState].includes(
        'updating'
      )
    ) {
      return <Icons.ThinSpinner strokeWidth={7} stroke={ACTIVE_GOLD} r={15} width='50px' />
    }

    const nextDoseAtMilliseconds = isNextDoseValidDateTime ? nextDoseAt.getTime() : null
    const withinDateRange =
      nextDoseAtMilliseconds &&
      (!minDate || nextDoseAtMilliseconds >= minDate.getTime()) &&
      (!maxDate || nextDoseAtMilliseconds <= maxDate.getTime())

    const calcButtonDisabled = Boolean(
      !['loaded', 'updateError'].includes(administrationsStore.loadState) ||
        !['loaded', 'updateError'].includes(observationsStore.loadState) ||
        administrationsStore.hasDuplicates ||
        observationsStore.hasDuplicates ||
        !withinDateRange ||
        !isValidDSTDateTime(format(nextDoseAt, dateFnsRawDateOnly), nextDoseAtTime, props.hospitalTimezone) ||
        historicalSimulationStore.loadState !== 'loaded' ||
        !!courseStore.course?.attributes.courseArchived ||
        !!courseStore.course?.attributes.isReadOnly ||
        requiresReview
    )

    const calcButtonTooltip = () => {
      if (courseStore.course?.attributes.courseArchived) {
        return (
          <ReactTooltip className='center-tooltip-text' id='calc-archived-tooltip' place='top' effect='solid'>
            Unable to calculate next dose <br />
            for an archived course.
          </ReactTooltip>
        )
      }

      if (courseStore.course?.attributes.isReadOnly) {
        return (
          <ReactTooltip className='center-tooltip-text' id='calc-readonly-tooltip' place='top' effect='solid'>
            Unable to calculate next dose <br />
            for a read-only course.
          </ReactTooltip>
        )
      }

      if (requiresReview) {
        return (
          <ReactTooltip className='center-tooltip-text' id='calc-requires-review-tooltip' place='top' effect='solid'>
            Required 'Course Variables' settings need <br />
            to be entered before simulating doses.
          </ReactTooltip>
        )
      }
    }

    let calculateButtonTooltip = ''
    if (
      !withinDateRange ||
      !isValidDSTDateTime(format(nextDoseAt, dateFnsRawDateOnly), nextDoseAtTime, props.hospitalTimezone)
    ) {
      calculateButtonTooltip = 'calc-daterange-tooltip'
    }

    if (courseStore.course?.attributes.courseArchived) {
      calculateButtonTooltip = 'calc-archived-tooltip'
    }

    if (courseStore.course?.attributes.isReadOnly) {
      calculateButtonTooltip = 'calc-readonly-tooltip'
    }

    if (requiresReview) {
      calculateButtonTooltip = 'calc-requires-review-tooltip'
    }

    return (
      <div className='ml-2 pt-1'>
        {calcButtonDisabled && minDate && maxDate && (
          <ReactTooltip className='center-tooltip-text' id='calc-daterange-tooltip' place='top' effect='solid'>
            Next dose time must be <br /> between&nbsp;
            <span>{format(minDate, dateFnsTableReadable)}</span> <br /> and&nbsp;
            <span>{format(maxDate, dateFnsTableReadable)}</span>
          </ReactTooltip>
        )}

        {calcButtonTooltip()}
        <IconButton
          onClick={() => {
            if (courseStore.course?.attributes.drugModel.id === '55') {
              setShowSimulateHDScheduleModal('initial')

              return
            }
            handleSimulation('initial')
          }}
          className={classnames('co-control-icon', {
            'initial-calc-btn-disabled': calcButtonDisabled
          })}
          disabled={calcButtonDisabled}
        >
          <div data-tip data-for={calculateButtonTooltip} className='co-control-btn-icon-center'>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </IconButton>
      </div>
    )
  }

  return (
    <>
      <GenerateReportModal
        show={showReportModal}
        setShow={setShowReportModal}
        patientId={props.patientId}
        courseId={props.courseId}
        drugModelId={props.drugModelId}
        currentModel={props.currentModel}
        limits={props.limits}
        predictedOutcome={predictedOutcome}
        nextDoseAt={nextDoseAt}
        hospitalTimezone={props.hospitalTimezone}
        selectedTargetDropdownItem={selectedTargetDropdownItem}
        customTargetForms={customTargetForms}
        customDoseForms={customDoseForms}
        calcTypeName={getCalcTypeName(props.currentModel, props.isIndividualized, true)}
        doseDescription={getDoseDescription(props.currentSimulation)}
        warnings={currentWarnings}
        warningsChecked={currentModelWarningsChecked}
        setWarningsChecked={setCurrentModelWarningsChecked}
        defaultLastPredictedDoseOutcomes={defaultLastPredictedDoseOutcomes}
        drugSpecificAttr={drugSpecificAttr}
      />

      <InfoPopupModal
        patientId={props.patientId}
        drugModelId={props.drugModelId}
        currentModel={props.currentModel}
        isIndividualized={props.isIndividualized}
        show={showInfoPopupModal}
        setShow={setShowInfoPopupModal}
      />

      <DosingMatrixModal
        show={showDosingMatrixPanel}
        setShow={setShowDosingMatrixPanel}
        setDosingMatrixCustomSimulationValue={setDosingMatrixCustomSimulationValue}
      />

      <SimulateHDScheduleModal
        setDrugSpecificAttr={setDrugSpecificAttr}
        showSimulatePanel={showSimulateHDScheduleModal}
        setShowSimulatePanel={setShowSimulateHDScheduleModal}
        hospitalTimezone={props.hospitalTimezone}
        nextDoseAt={nextDoseAt}
        currentModel={props.currentModel}
      />

      {window.env.INSTANCE_TYPE === 'TDI' &&
        <TDIInfoModal
          setShow={setShowInfoTDI}
          show={showInfoTDI}
          continue={() => infoTDIContinue()}
        />
      }

      <div className='simulation-panel content-panel-with-shadow mb-4' data-testid='simulation-panel'>
        <div
          className={classnames('w-100', 'd-flex', 'justify-content-between', 'align-items-end', {
            'mb-4': initialStateCalculated
          })}
        >
          <div className='d-flex align-items-center'>
            <div className='content-panel-with-shadow-title mr-3'>
              {initialStateCalculated ? 'Next Dose' : 'Calculate Next Dose'}
            </div>
            <div className='datetimeinput-line-buffer'>
              <NextDoseAtDateTime
                disabled={
                  historicalSimulationStore.loadState !== 'loaded' ||
                  ['loading', 'updating'].includes(dosingRecommendationStore.loadState) ||
                  !!courseStore.course?.attributes.courseArchived ||
                  !!courseStore.course?.attributes.isReadOnly
                }
                hospitalTimezone={props.hospitalTimezone}
                nextDoseAtDate={nextDoseAtDate}
                nextDoseAtTime={nextDoseAtTime}
                minDate={minDate}
                maxDate={maxDate}
                onUpdateDate={setNextDoseAtDate}
                onUpdateTime={setNextDoseAtTime}
              />
            </div>
            {initialStateCalculated || initialCalcButton()}
          </div>
          {initialStateCalculated && (
            <FloatingTabBar
              tabNames={props.isIndividualized ? tdmTabs : nonTdmTabs}
              underline
              activeTab={activeSimTab || (props.isIndividualized ? 'indPop' : 'guideline')}
              onSelectTab={(activeTab) => setActiveSimTab(activeTab as TSimulationFilter)}
              disabled={disableTabs}
            />
          )}
        </div>
        {initialStateCalculated &&
          overlaidContent(
            <>
              {courseStore.loadState === 'loaded' && courseStore.course && tabContent()}
              <div className='mt-4'>{calculationInfo()}</div>
              <div className='mt-3'>
                <ValidationErrorsDisplay validationErrors={customValidationErrors} />
                {calculationWarnings()}
              </div>
              {courseStore.course?.attributes.drugModel.id === '55' && (
                <div id='edit-simulate-hd-bar'>{editSimulatedHDBar()}</div>
              )}
              {reportBar}
            </>
          )}
      </div>
    </>
  )
})
