import { Route, BrowserRouter, Switch, RouteComponentProps } from 'react-router-dom'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { ToastContainer } from 'react-toastify'

import { reactPlugin } from './components/AzureAppInsights'
import { AppInsightsErrorBoundary, DebugError } from './components/AppInsightsErrorBoundary'
import { Launch } from './components/Launch'
import { TopMenuBar } from './components/TopMenuBar'
import { IntegratedFooter } from './components/IntegratedFooter'
import { IntegratedSessionMiddleware } from './components/SessionMiddleware'
import { PatientRouter } from './components/PatientRouter'
import { ResourcesRouter } from './components/ResourcesRouter'
import { StoreProvider } from './store/StoreProvider'
import { AdminRouter } from './components/AdminRouter'
import { ScrollToTop } from './shared/ScrollToTop'
import { AnalyticsPage } from './components/AnalyticsPage'

import './index.scss'

interface IPatientProfileMatchParams {
  patientId: string
}

const IntegratedApp: React.FC = () => {
  return (
    <>
      <ToastContainer newestOnTop={true} closeOnClick={true} />
      <StoreProvider>
        <BrowserRouter>
          <ScrollToTop />

          <div className='app-wrapper'>
            <AppInsightsContext.Provider value={reactPlugin}>
              <TopMenuBar />

              <AppInsightsErrorBoundary>
                <Switch>
                  {/* Launch Page */}
                  <Route exact path='/launch' component={Launch} />

                  {/* Medication Page (legacy SOF) */}
                  <Route exact path='/medication' component={Launch} />

                  {/* Admin Router */}
                  <Route
                    path='/patients/:patientId/admin'
                    render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
                      <IntegratedSessionMiddleware patientId={match.params.patientId}>
                        <AdminRouter patientId={match.params.patientId} />
                      </IntegratedSessionMiddleware>
                    )}
                  />

                  {/* Analytics Page */}
                  <Route
                    path='/patients/:patientId/analytics'
                    render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
                      <IntegratedSessionMiddleware patientId={match.params.patientId}>
                        <AnalyticsPage patientId={match.params.patientId} />
                      </IntegratedSessionMiddleware>
                    )}
                  />

                  {/* Resources Router */}
                  <Route
                    path='/patients/:patientId/resources'
                    render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
                      <IntegratedSessionMiddleware patientId={match.params.patientId}>
                        <ResourcesRouter patientId={match.params.patientId} />
                      </IntegratedSessionMiddleware>
                    )}
                  />

                  {/* Patient Router */}
                  <Route
                    path='/patients/:patientId'
                    render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
                      <IntegratedSessionMiddleware patientId={match.params.patientId}>
                        <PatientRouter patientId={match.params.patientId} />
                      </IntegratedSessionMiddleware>
                    )}
                  />

                  {/* Second stage SMART on FHIR auth */}
                  <Route
                    exact
                    path='/'
                    render={() => {
                      const params = new URLSearchParams(window.location.search)

                      if (params.get('code') && params.get('state')) {
                        return <Launch />
                      }

                      throw new DebugError(`Launch params missing: ${params.get('code')} & ${params.get('state')}`)
                    }}
                  />

                  {/* Error 404 Page */}
                  <Route
                    exact
                    path='*'
                    render={({ location }: RouteComponentProps) => {
                      throw new DebugError(`Invalid route: ${location.pathname}`)
                    }}
                  />
                </Switch>
              </AppInsightsErrorBoundary>
              <IntegratedFooter />
            </AppInsightsContext.Provider>
          </div>
        </BrowserRouter>
      </StoreProvider>
    </>
  )
}

export {
  IntegratedApp
}
