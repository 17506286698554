import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Icons, SidePanel } from '@doseme/cohesive-ui'
import ReactTooltip from 'react-tooltip'

import { useDosingRecommendationStore, useHistoricalSimulationStore } from '../../../../../../../../hooks/useStore'
import { IPKParameter } from '../../../../../../../../store/historicalSimulation/types'
import { TModelType } from '../../../../../../../../store/dosingRecommendation/types'

import './index.scss'

interface IProps {
  selectedSimulationPanelTab: TModelType
}

export const PKParametersPanel: React.FC<IProps> = observer((props) => {
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const [pkParameters, setPkParameters] = useState<IPKParameter[] | null>(null)

  useEffect(() => {
    if (historicalSimulationStore.loadState === 'loaded' && historicalSimulationStore.historicalSimulationData) {
      setPkParameters(historicalSimulationStore.historicalSimulationData.attributes.pkParameters)
    }
  }, [historicalSimulationStore.loadState])

  useEffect(() => {
    const currentDosingRecommendation =
      dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]

    if (dosingRecommendationStore.loadState === 'loaded' && currentDosingRecommendation) {
      setPkParameters(currentDosingRecommendation.attributes.pkParameters)
    }
  }, [dosingRecommendationStore.loadState])

  const panelContent = () => {
    // testing for empty array as well as null or undefined
    if (!pkParameters?.length) {
      return 'N/A'
    }

    return (
      <>
        <div className='d-flex'>
          <div className='doserecommendation-pk-parameters-title-individual dark-label-text'>Individual</div>
          <div className='doserecommendation-pk-parameters-title-population dark-label-text'>Population</div>
        </div>

        {pkParameters.map((parameter, idx) => {
          return (
            <div key={`pk-parameters-list-${idx}`} className='d-flex mt-2'>
              <div className='doserecommendation-pk-parameters-name'>
                <span className='dark-label-text'>{parameter.name === 'Vdss' ? <span>Vd<sub>ss</sub></span> : parameter.name}: </span>
                {parameter.info ? (
                  <>
                    <ReactTooltip
                      className='center-tooltip-text'
                      id={`pk-parameters-${parameter.name}-tooltip`}
                      place='top'
                      effect='solid'
                    >
                      {parameter.info}
                    </ReactTooltip>
                    <div data-tip data-for={`pk-parameters-${parameter.name}-tooltip`} className='ml-1'>
                      <Icons.Info />
                    </div>
                  </>
                ) : null}
              </div>
              <div className='doserecommendation-pk-parameters-individual'>{parameter.individual || '—'}</div>
              <div className='doserecommendation-pk-parameters-population'>{parameter.population}</div>
            </div>
          )
        })}
      </>
    )
  }

  const isPanelLoading = () => {
    return ['initial', 'loading', 'updating'].includes(historicalSimulationStore.loadState) ||
      ['loading', 'updating'].includes(dosingRecommendationStore.loadState)
  }

  const isPanelInErrorState = () => {
    const errorStates = ['updateError', 'loadError']

    return errorStates.includes(historicalSimulationStore.loadState) ||
      errorStates.includes(dosingRecommendationStore.loadState)
  }

  return (
    <SidePanel
      title='PK Parameters'
      loading={isPanelLoading()}
      errorMessage={isPanelInErrorState() ? 'Failed to load' : ''}
    >
      <div className='mt-4'>{panelContent()}</div>
    </SidePanel>
  )
})
