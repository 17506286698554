import {
  ConfirmModal,
  Icons,
  ListButton,
  Modal,
  Tag,
  TooltipMenu,
  MENU_GREY,
  LOADING_GREY,
  red100,
  ITooltipMenuData
} from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { isIE } from 'react-device-detect'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import { usePatientCoursesStore, usePatientStore, useAuthStore, useClinicianStore } from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { getDrugNameFromDrugModel, getModelNameFromDrugModel } from '../../../../../../../../utils/drugModels'
import { IPatientCourse } from '../../../../../../../../store/patientCourses/types'

import './index.scss'

interface IProps {
  patientId: string
  selectedCourseId: string
}

export const PreviewPanelTopSection: React.FC<IProps> = observer((props) => {
  const patientCoursesStore = usePatientCoursesStore()
  const patientStore = usePatientStore()
  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const history = useHistory()

  useEffect(() => {
    if (clinicianStore.loadState === 'initial' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [])

  const deleteModalMessage = (drugModelName: string) => {
    const messageWarning = () => {
      if (window.env.VENDOR_MODE === 'standalone') {
        return <div>All course data will be permanently lost.</div>
      }

      return (
        <>
          <div>
            Upon next launch, DoseMeRx will attempt to re-generate course data by reading from your EHR.
            However, not all course data may be retrievable.
          </div>
          <br />
          <div>Any manually added/edited/excluded data in the course will be permanently lost.</div>
        </>
      )
    }

    return (
      <div className='preview-panel-delete-modal'>
        <Tag size='large' text={drugModelName} color={MENU_GREY} bold />
        <br />
        {messageWarning()}
        <br />
        <div className='font-weight-bold'>This cannot be reversed.</div>
      </div>
    )
  }

  const deleteModal = (drugModelName: string) => {
    return (
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false)
        }}
      >
        <ConfirmModal
          confirmationType='delete'
          entityType='Course'
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={() => {
            handleCourseDelete()
          }}
          title='Delete Course'
          message={deleteModalMessage(drugModelName)}
          cancelButtonLabel='Cancel'
          confirmButtonLabel='Delete Course'
        />
      </Modal>
    )
  }

  const handleCourseDelete = async () => {
    await patientCoursesStore.deleteCourse(props.patientId, props.selectedCourseId)

    if (patientCoursesStore.loadState === 'updateError') {
      showErrorToast(patientCoursesStore.error || 'Failed to delete course')

      return
    }

    showSuccessToast('Course deleted')
    setShowDeleteModal(false)
  }

  const getTooltipMenuData = (selectedCourse: IPatientCourse): ITooltipMenuData[] => {
    let tooltipMenuData: ITooltipMenuData[] = []

    if (clinicianStore.clinician?.attributes.isSuperAdmin) {
      tooltipMenuData.push({
        id: 0,
        value: 'Export Course Data',
        onSelect: () => {
          window.open(`${window.env.API_BASE_URL}/patients/${props.patientId}/courses/${selectedCourse.id}/export`, '_self'),
          showSuccessToast('Patient course exported')
        },
        actionType: 'export'
      })
    }

    tooltipMenuData.push({
      id: 1,
      value: 'Delete Course',
      onSelect: () => {
        setShowDeleteModal(true)
      },
      disabled: !!selectedCourse.attributes.deleteMessage,
      tooltipMessage: selectedCourse.attributes.deleteMessage,
      actionType: 'delete'
    })

    return tooltipMenuData
  }

  const viewFullCourseButton = (selectedCourse: IPatientCourse) => {
    if (!patientStore.patient?.attributes.weight.value) {
      return (
        <div className={classnames('course-preview-top-buttons', { 'course-btn-IE-displace': isIE })}>
          <ReactTooltip id='missing-weight-tip' place='top' effect='solid'>
            Please enter a patient weight before proceeding to the course profile
          </ReactTooltip>
          <div data-tip data-for='missing-weight-tip'>
            <ListButton className='course-preview-view-full-course-btn' disabled={true} size='md'>
              {selectedCourse.attributes.courseArchived ? 'View course (archived) →' : 'View course →'}
            </ListButton>
          </div>
          <div data-testid='patient-profile-ellipsis-icon' className='course-preview-ellipsis'>
            <ListButton disabled={true} size='cl'>
              <Icons.Ellipsis />
            </ListButton>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className={classnames('course-preview-top-buttons', { 'course-btn-IE-displace': isIE })}>
          <ListButton
            className='course-preview-view-full-course-btn'
            size='md'
            onClick={() =>
              history.push(`/patients/${props.patientId}/courses/${props.selectedCourseId}`, { from: `/patients/${props.patientId}` })
            }
          >
            {selectedCourse.attributes.courseArchived ? 'View course (archived) →' : 'View course →'}
          </ListButton>
          <div data-testid='patient-profile-ellipsis-icon' className='course-preview-ellipsis'>
            <TooltipMenu
              button={
                <ListButton
                  size='cl'
                >
                  <Icons.Ellipsis />
                </ListButton>
              }
              data={getTooltipMenuData(selectedCourse)}
              alignRight={true}
              chevronOffset={10}
            />
          </div>
        </div>
      </>
    )
  }

  const selectedCourse = patientCoursesStore.courses.get(props.selectedCourseId)
  if (patientCoursesStore.loadState === 'loaded' && selectedCourse) {
    const drugModelName = selectedCourse!.attributes.drugModel.name
    const isArchived = !!selectedCourse!.attributes.courseArchived
    const isReadOnly = !!selectedCourse.attributes.isReadOnly

    return (
      <>
        {deleteModal(drugModelName)}
        <div data-testid='drug-details' className='drug-details'>
          {selectedCourse.attributes.archivedReason ? (
            <ReactTooltip
              className='center-tooltip-text'
              id={`course-preview-panel-archived-tooltip-${selectedCourse.id}`}
              place='top'
              effect='solid'
            >
              {selectedCourse.attributes.archivedReason}
            </ReactTooltip>
          ) : null}

          <ReactTooltip
            className='center-tooltip-text'
            id={`course-preview-panel-read-only-tooltip-${selectedCourse.id}`}
            place='top'
            effect='solid'
          >
            This course is read-only as the drug model is no longer available.
          </ReactTooltip>

          <div
            className={classnames('drug-title-container', {
              archived: isArchived,
              active: !isArchived
            })}
          >
            <div className='drug-title'>
              <div className='drug-title-ellipsis-overflow'>{getDrugNameFromDrugModel(drugModelName)}</div>
              {isArchived ? (
                <div data-tip data-for={`course-preview-panel-archived-tooltip-${selectedCourse.id}`} className='ml-2'>
                  <Icons.Archive />
                </div>
              ) : null}

              {isReadOnly && !isArchived ? (
                <div data-tip data-for={`course-preview-panel-read-only-tooltip-${selectedCourse.id}`} className='ml-2'>
                  <FontAwesomeIcon color={LOADING_GREY} icon={faBook} />
                </div>
              ) : null}
            </div>
            <span className='model-subtitle'>
              {getModelNameFromDrugModel(drugModelName)}
              {!selectedCourse.attributes.drugModel.isValidated ? (
                <span style={{ transform: 'translateY(-2px)' }} className='ml-2'>
                  <Tag size='medium' text='Research' color={red100}/>
                </span>
              ) : null}
              {selectedCourse.attributes.drugModel.isNew ? (
                <span style={{ transform: 'translateY(-2px)' }} className='ml-2'>
                  <Tag size='medium' text='New model' />
                </span>
              ) : null}
            </span>
          </div>

          {viewFullCourseButton(selectedCourse)}
        </div>
      </>
    )
  }

  return (
    <div className='drug-details'>
      <div className='drug-title-container'>
        <div className='drug-title'>No Course Selected</div>
      </div>
      <div className={classnames('course-preview-top-buttons', { 'course-btn-IE-displace': isIE })}>
        <ListButton className='course-preview-view-full-course-btn' disabled={true} size='md'>
          View course →
        </ListButton>
        <div data-testid='patient-profile-ellipsis-icon' className='course-preview-ellipsis'>
          <ListButton disabled={true} size='cl'>
            <Icons.Ellipsis />
          </ListButton>
        </div>
      </div>
    </div>
  )
})
