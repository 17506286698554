import { parseISO, format } from 'date-fns'

import { dateFnsRawDateOnly } from '../../../../../../../../constants/timeFormat'
import { AdministrationsStore } from '../../../../../../../../store/administrations/AdministrationsStore'
import { IAdministration } from '../../../../../../../../store/administrations/types'
import { ICourse } from '../../../../../../../../store/course/types'
import { ObservationsStore } from '../../../../../../../../store/observations/ObservationsStore'
import { IObservation } from '../../../../../../../../store/observations/types'
import { IFormState } from '../../../../../../../../types/validation'
import { calculateDefaultNextDoseTime } from '../SimulationPanel/components/NextDoseAtDateTime/utils/dateProps'

export const getInitialValues = (
  form: IFormState | undefined,
  course: ICourse,
  hospitalTimezone: string,
  administrationsStore: AdministrationsStore,
  observationsStore: ObservationsStore,
  assumedDosingInterval: number | null,
  latestIncludedAdministration?: IAdministration,
  lastIncludedSeCrObservation?: IObservation
): Record<string, any> => {
  const previousValues = Object.assign({}, form?.values)

  const peviousDateString = (previousValues.dateAdministered && previousValues.timeAdministered)
    ? previousValues.dateAdministered + 'T' + previousValues.timeAdministered
    : null

  const nextDoseAt = getNextDoseDate(
    peviousDateString,
    course,
    hospitalTimezone,
    administrationsStore,
    observationsStore,
    assumedDosingInterval,
    latestIncludedAdministration,
    lastIncludedSeCrObservation
  )

  if (nextDoseAt) {
    previousValues.dateAdministered = nextDoseAt[0]
    previousValues.timeAdministered = nextDoseAt[1]
  }

  return previousValues
}

export const getNextDoseDate = (
  peviousDateString: string | null,
  course: ICourse,
  hospitalTimezone: string,
  administrationsStore: AdministrationsStore,
  observationsStore: ObservationsStore,
  assumedDosingInterval: number | null,
  latestIncludedAdministration?: IAdministration,
  lastIncludedSeCrObservation?: IObservation
): (string | Date)[] | undefined => {
  if ([administrationsStore.loadState, observationsStore.loadState].includes('loaded')) {
    const dosingInterval = assumedDosingInterval ||
      course!.attributes.limits.dosingPeriod.default.value

    const defaultNextDoseTime = calculateDefaultNextDoseTime(
      hospitalTimezone,
      dosingInterval,
      peviousDateString ?? latestIncludedAdministration?.attributes.administeredAt.value,
      lastIncludedSeCrObservation?.attributes.observedAt.value
    )

    return [
      parseISO(format(defaultNextDoseTime, dateFnsRawDateOnly)),
      defaultNextDoseTime.getHours().toString().padStart(2, '0') +
      ':' +
      defaultNextDoseTime.getMinutes().toString().padStart(2, '0')
    ]
  }
}

export const getSortedFormKeys = (
  forms: { [id: number]: IFormState | undefined },
  type: 'dose' | 'observation',
  latestForm?: string
) => {
  return Object.keys(forms).sort((a: string, b: string) => {
    const formA = forms[parseInt(a)]
    const formB = forms[parseInt(b)]

    const formADate =
      type === 'dose'
        ? parseISO(formA?.values.dateAdministered + 'T' + formA?.values.timeAdministered).getTime() || 0
        : parseISO(formA?.values.dateObserved + 'T' + formA?.values.timeObserved).getTime() || 0
    const formBDate =
      type === 'dose'
        ? parseISO(formB?.values.dateAdministered + 'T' + formB?.values.timeAdministered).getTime() || 0
        : parseISO(formB?.values.dateObserved + 'T' + formB?.values.timeObserved).getTime() || 0

    if (a === latestForm && formADate === 0) return 1
    if (b === latestForm && formBDate === 0) return -1

    return formADate - formBDate
  })
}

export const getLatestValidForm = (
  keys: string[],
  forms: { [id: number]: IFormState | undefined }
) => {
  for (let i = keys.length - 1; i >= 0; i--) {
    const form = forms[parseInt(keys[i])]

    if (form?.values.dateAdministered && form?.values.timeAdministered &&
      !isNaN(parseISO(form?.values.dateAdministered + 'T' + form?.values.timeAdministered).getTime())
    ) {
      return form
    }
  }
}
