import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { ActionButton, SidePanel } from '@doseme/cohesive-ui'

import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { IDrugDetailsPanelWithoutModalProps } from '../../types'
import { useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { buildInlineFormsFields, buildPutRequest, displayInlineEditPanels, displayNoModalPanels } from '../../utils'
import { IPutAdminHospitalDrugDoseCalculatedGeneralLimits } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'

import '../../index.scss'

export const CalculatedDosesGeneralSettings: React.FC<IDrugDetailsPanelWithoutModalProps> = observer((props) => {
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()

  const [selectedPanelsHelpTextList, setSelectedPanelsHelptextList] = useState<string[]>([])
  const dataKeys = [
    'infusionLength',
    'dosingInterval',
    'recommendationNumDoses',
    'recommendationDosingDays',
    'maxUntrainedCourseLength'
  ]

  const inlineFormFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedGeneralLimits)) {
      return buildInlineFormsFields(
        adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedGeneralLimits?.attributes.settings.dosesCalculatedGeneralLimits
      )
    }

    return {
      infusionLength: {},
      dosingInterval: {},
      recommendationNumDoses: {},
      recommendationDosingDays: {},
      maxUntrainedCourseLength: {}
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedGeneralLimits])

  const forms = {
    infusionLength: useFormValidation(inlineFormFields['infusionLength']),
    dosingInterval: useFormValidation(inlineFormFields['dosingInterval']),
    recommendationNumDoses: useFormValidation(inlineFormFields['recommendationNumDoses']),
    recommendationDosingDays: useFormValidation(inlineFormFields['recommendationDosingDays']),
    maxUntrainedCourseLength: useFormValidation(inlineFormFields['maxUntrainedCourseLength'])
  }

  useEffect(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedGeneralLimits === 'loaded') {
      forms.infusionLength.reset()
      forms.dosingInterval.reset()
      forms.recommendationNumDoses.reset()
      forms.recommendationDosingDays.reset()
      forms.maxUntrainedCourseLength.reset()
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedGeneralLimits])

  useEffect(() => {
    if (props.isPanelEditable) {
      adminHospitalDrugDetailsStore.fetchAdminHospitalDrugDoseCalculatedGeneralLimits(props.hospitalId, props.drugModelId)
    }
  }, [props.hospitalId, props.isPanelEditable])

  const handleSubmit = async () => {
    if (props.drugModelId && adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedGeneralLimits) {
      const responseStructure = {
        name: adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedGeneralLimits.attributes.name,
        settings: {
          dosesCalculatedGeneralLimits:
            buildPutRequest(
              forms,
              adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedGeneralLimits.attributes.settings
                .dosesCalculatedGeneralLimits
            )
        }
      }

      await adminHospitalDrugDetailsStore.putAdminHospitalDrugDoseCalculatedGeneralLimits(
        props.hospitalId,
        props.drugModelId,
        responseStructure as IPutAdminHospitalDrugDoseCalculatedGeneralLimits
      )

      if (['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedGeneralLimits)) {
        showErrorToast(
          adminHospitalDrugDetailsStore.settingsErrors.doseCalculatedGeneralLimits || 'Failed to update calculated doses: general settings'
        )

        return
      }

      showSuccessToast('Calculated doses: general settings updated')
      props.setIsPanelEditable(null)
    }
  }

  return (
    <div className='mt-3'>
      <SidePanel
        title='Calculated doses: general settings'
        loading={
          ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedGeneralLimits)
          || !props.data
        }
        actionButton={
          !props.isPanelEditable ? (
            <ActionButton
              data-testid='hospital-drug-details-data-btn'
              actionType='edit'
              onClick={() => props.setIsPanelEditable('generalSettings')}
            />
          ) : (
            <></>
          )
        }
      >
        {props.data &&
          (props.isPanelEditable &&
            adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedGeneralLimits?.attributes.settings.dosesCalculatedGeneralLimits
            ? displayInlineEditPanels(
              props.data,
              adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedGeneralLimits?.attributes.settings
                .dosesCalculatedGeneralLimits,
              dataKeys,
              forms,
              inlineFormFields,
              adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedGeneralLimits,
              handleSubmit,
              props.setIsPanelEditable
            )
            : displayNoModalPanels(props.data, dataKeys, selectedPanelsHelpTextList, setSelectedPanelsHelptextList))}
      </SidePanel>
    </div>
  )
})
