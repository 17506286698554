import axios, { AxiosError, AxiosResponse } from 'axios'
import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import {
  adminClinicianBulkDisablePutUrl,
  adminClinicianBulkEnablePutUrl,
  adminClinicianListGetUrl,
  adminClinicianResendInviteGetUrl,
  adminClinicianInviteDeleteUrl
} from '../../../constants/api'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { IAdminClinicianListItem } from './types'
import { AdminClinicianListItem } from './AdminClinicianListItem'

export class AdminClinicianListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminClinicians: ObservableMap<string, IAdminClinicianListItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  resetAdminClinicians(adminClinicians: IAdminClinicianListItem[]) {
    this.adminClinicians = observable.map({}, { deep: false })
    adminClinicians.forEach((clinician) =>
      this.adminClinicians.set(clinician.id, new AdminClinicianListItem(this, clinician))
    )
  }

  setAdminClinicians(adminClinicians: IAdminClinicianListItem[]) {
    adminClinicians.forEach((clinician) =>
      this.adminClinicians.set(clinician.id, new AdminClinicianListItem(this, clinician))
    )
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchAdminClinicians() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminClinicianListItem[]>>(adminClinicianListGetUrl(), { headers })
      .then((response) => {
        this.resetAdminClinicians(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async enableAdminClinicians(clinicianIds: string[]): Promise<IAdminClinicianListItem[] | void> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axios
      .put<AxiosResponse<IAdminClinicianListItem[]>>(
        adminClinicianBulkEnablePutUrl(),
        {
          data: {
            type: 'adminCliniciansBulkEnable',
            attributes: {
              clinicianIds: clinicianIds
            }
          }
        },
        { headers }
      )
      .then((response) => {
        this.setAdminClinicians(response.data.data)
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }

  async disableAdminClinicians(clinicianIds: string[]): Promise<IAdminClinicianListItem[] | void> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axios
      .put<AxiosResponse<IAdminClinicianListItem[]>>(
        adminClinicianBulkDisablePutUrl(),
        {
          data: {
            type: 'adminCliniciansBulkDisable',
            attributes: {
              clinicianIds: clinicianIds
            }
          }
        },
        { headers }
      )
      .then((response) => {
        this.setAdminClinicians(response.data.data)
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }

  async resendAdminClinicianInvite(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get(adminClinicianResendInviteGetUrl(clinicianId), { headers })
      .then((response) => {
        this.setLoadState('loaded')
        this.fetchAdminClinicians()
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async cancelAdminClinicianInvite(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .delete(adminClinicianInviteDeleteUrl(clinicianId), { headers })
      .then((response) => {
        this.setLoadState('loaded')
        this.fetchAdminClinicians()
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
