import axios, { AxiosError, AxiosResponse } from 'axios'
import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import {
  patientBulkArchivePutUrl,
  patientBulkDeletePutUrl,
  patientBulkUnarchivePutUrl,
  patientsListGetUrl,
  patientsListFiltersGetUrl
} from '../../constants/api'
import { IPatientListItem, IPatientFilterItem } from './types'
import { PatientListItem } from './PatientListItem'
import { PatientFilterItem } from './PatientFilterItem'

export class PatientListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  filterLoadState: TLoadState = 'initial'
  error: string = ''

  patients: ObservableMap<string, IPatientListItem> = observable.map({}, { deep: false })
  patientFilters: ObservableMap<string, IPatientFilterItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  resetPatientFilters(patientFilters: IPatientFilterItem[]) {
    this.patientFilters = observable.map({}, { deep: false })
    patientFilters.forEach((patientFilter) => this.patientFilters.set(patientFilter.attributes.name, new PatientFilterItem(this, patientFilter)))
  }

  resetPatients(patients: IPatientListItem[]) {
    this.patients = observable.map({}, { deep: false })
    patients.forEach((patient) => this.patients.set(patient.id, new PatientListItem(this, patient)))
  }

  setPatients(patients: IPatientListItem[]) {
    patients.forEach((patient) => this.patients.set(patient.id, new PatientListItem(this, patient)))
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setFilterLoadState(filterLoadState: TLoadState) {
    this.filterLoadState = filterLoadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchPatients(hospitalId: string, drugFilter?: string[], wardFilter?: string[], clinicianFilter?: string[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const params = new URLSearchParams()
    drugFilter?.forEach((drug) => {
      params.append('drug', drug)
    })
    wardFilter?.forEach((ward) => {
      params.append('ward', ward)
    })
    clinicianFilter?.forEach((clinician) => {
      params.append('clinician', clinician)
    })

    await axios
      .get<AxiosResponse<IPatientListItem[]>>(patientsListGetUrl(hospitalId), { headers, params })
      .then((response) => {
        this.resetPatients(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async fetchPatientListFilters(hospitalId: string) {
    this.setFilterLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IPatientFilterItem[]>>(patientsListFiltersGetUrl(hospitalId), { headers })
      .then((response) => {
        this.resetPatientFilters(response.data.data)
        this.setFilterLoadState('loaded')
      })
      .catch((error) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async bulkArchivePatients(hospitalId: string, patientIds: string[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IPatientListItem[]>>(
        patientBulkArchivePutUrl(hospitalId),
        { data: { type: 'hospitalsPatientsBulkArchive', attributes: { patientIds: patientIds } } },
        { headers }
      )
      .then((response) => {
        this.setPatients(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }

  async bulkUnarchivePatients(hospitalId: string, patientIds: string[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IPatientListItem[]>>(
        patientBulkUnarchivePutUrl(hospitalId),
        { data: { type: 'hospitalsPatientsBulkUnarchive', attributes: { patientIds: patientIds } } },
        { headers }
      )
      .then((response) => {
        this.setPatients(response.data.data)
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }

  async deletePatients(hospitalId: string, patientIds: string[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IPatientListItem[]>>(
        patientBulkDeletePutUrl(hospitalId),
        { data: { type: 'hospitalsPatientsBulkArchive', attributes: { patientIds: patientIds } } },
        { headers }
      )
      .then((response) => {
        this.resetPatients(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }
}
