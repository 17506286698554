import { ADE, BackArrowButton, Icons } from '@doseme/cohesive-ui'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBreadcrumbsStore } from '../../../../hooks/useStore'
import { formatExternalLinkDivs, downloadDocumentURL, getResourcesRoutePrefix } from '../../utils'
import { handleBackButton } from '../../../../utils/navigation'

import '../index.scss'

interface IProps {
  patientId?: string
}

export const Compliance: React.FC<IProps> = (props) => {
  const [IFUHover, setIFUHover] = useState<boolean>(false)

  const history = useHistory()
  const breadcrumbsStore = useBreadcrumbsStore()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Resources', onClick: () => handleBackButton(`/patients/${props.patientId}/resources`, history) },
      { label: 'Compliance' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  return (
    <div data-testid='compliance' className='co-resource-page'>
      <div className='d-flex'>
        <div className='resource-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton(getResourcesRoutePrefix(props.patientId), history)}
          />
        </div>
        <div className='resource-title compliance'>Compliance</div>
      </div>

      <div className='resource-panel'>
        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_IFU.pdf'),
          undefined,
          <div
            data-testid='compliance-bayesian-dosing'
            className='download-link-div compliance'
            onMouseLeave={() => setIFUHover(false)}
            onMouseOver={() => setIFUHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title mb-2'>DoseMeRx IFU 2.0</div>
              <div className='doseme-link-div-text'>Download our Instructions for Use version 2.0</div>
            </div>
            <div data-testid='compliance-bayesian-dosing-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={IFUHover ? ADE : undefined} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
