import axios, { AxiosError, AxiosResponse } from 'axios'
import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { adminHospitalsGetUrl, hospitalPostUrl, hospitalDuplicatePostUrl } from '../../../constants/api'
import { IAdminHospitalListItem } from './types'
import { AdminHospitalListItem } from './AdminHospitalListItem'

export class AdminHospitalListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminHospitals: ObservableMap<string, IAdminHospitalListItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  resetAdminHospitals(adminHospitals: IAdminHospitalListItem[]) {
    this.adminHospitals = observable.map({}, { deep: false })
    adminHospitals.forEach((hospital) =>
      this.adminHospitals.set(hospital.id, new AdminHospitalListItem(this, hospital))
    )
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchAdminHospitals() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalListItem[]>>(adminHospitalsGetUrl(), { headers })
      .then((response) => {
        this.resetAdminHospitals(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async createHospital(hospitalName: string, timezone: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axios
      .post<AxiosResponse<IAdminHospitalListItem[]>>(
        hospitalPostUrl(),
        {
          type: 'adminHospital',
          attributes: {
            name: hospitalName,
            timezone: timezone
          }
        },
        { headers }
      )
      .then((response) => {
        this.setLoadState('loaded')
        if (response.data.data.length) {
          return response.data.data[0].id
        }

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async duplicateHospital(hospitalId: string, hospitalName: string, timezone: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axios
      .post<AxiosResponse<IAdminHospitalListItem[]>>(
        hospitalDuplicatePostUrl(hospitalId),
        {
          type: 'adminHospital',
          attributes: {
            name: hospitalName,
            timezone: timezone
          }
        },
        { headers }
      )
      .then((response) => {
        this.setLoadState('loaded')
        if (response.data.data.length) {
          return response.data.data[0].id
        }

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
