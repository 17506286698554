import { makeAutoObservable } from 'mobx'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { RootStore } from '../../RootStore'
import { IInputFieldItem, TLoadState } from '../../types'
import {
  adminHospitalAddDrugDetailsGetUrl,
  adminHospitalDrugDoseCalculatedGeneralGetUrl,
  adminHospitalDrugDoseCalculatedTargetGetUrl,
  adminHospitalDrugDoseCalculatedWarningGetUrl,
  adminHospitalDrugGeneralModelSettingsGetUrl,
  adminHospitalDrugRecordedCourseDataGetUrl,
  adminHospitalDrugReportTemplatesGetUrl,
  adminHospitalDrugSuperAdminInfoGetUrl,
  adminHospitalDrugDoseCalculatedGeneralPutUrl,
  adminHospitalDrugDoseCalculatedTargetPutUrl,
  adminHospitalDrugDoseCalculatedWarningPutUrl,
  adminHospitalDrugGeneralModelSettingsPutUrl,
  adminHospitalDrugRecordedCourseDataPutUrl,
  adminHospitalDrugReportTemplatesPutUrl,
  adminHospitalDrugSuperAdminInfoPutUrl
} from '../../../constants/api'
import {
  IAdminHospitalDrugDetails,
  IAdminHospitalDrugDoseCalculatedGeneralLimits,
  IAdminHospitalDrugDoseCalculatedTargetLimits,
  IAdminHospitalDrugDoseCalculatedWarningLimits,
  IAdminHospitalDrugGeneralModelSettings,
  IAdminHospitalDrugRecordedCourseData,
  IAdminHospitalDrugReportTemplates,
  IAdminHospitalDrugSuperAdminInfo,
  IPutAdminHospitalDrugDoseCalculatedGeneralLimits,
  IPutAdminHospitalDrugDoseCalculatedTargetLimits,
  IPutAdminHospitalDrugDoseCalculatedWarningLimits,
  IPutAdminHospitalDrugGeneralModelSettings,
  IPutAdminHospitalDrugRecordedCourseData,
  IPutAdminHospitalDrugReportTemplates,
  IPutAdminHospitalDrugSuperAdminInfo,
  IAdminHospitalDrugSuperAdminInfoConstraints
} from './types'
import { AdminHospitalDrugDetails } from './AdminHospitalDrugDetails'
import { AdminHospitalDrugGeneralModelSettings } from './AdminHospitalDrugGeneralModelSettings'
import { AdminHospitalDrugRecordedCourseData } from './AdminHospitalDrugRecordedCourseData'
import { AdminHospitalDrugDoseCalculatedGeneralLimits } from './AdminHospitalDrugDoseCalculatedGeneralLimits'
import { AdminHospitalDrugDoseCalculatedTargetLimits } from './AdminHospitalDrugDoseCalculatedTargetLimits'
import { AdminHospitalDrugDoseCalculatedWarningLimits } from './AdminHospitalDrugDoseCalculatedWarningLimits'
import { AdminHospitalDrugReportTemplates } from './AdminHospitalDrugReportTemplates'
import { AdminHospitalDrugSuperAdminInfo } from './AdminHospitalDrugSuperAdminSettings'

export class AdminHospitalDrugDetailsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminHospitalDrugDetails: IAdminHospitalDrugDetails | null = null

  settingsLoadStates: Record<string, TLoadState> = {
    generalModelSettings: 'initial',
    recordedCourseData: 'initial',
    doseCalculatedGeneralLimits: 'initial',
    doseCalculatedTargetLimits: 'initial',
    doseCalculatedWarningLimits: 'initial',
    reportTemplates: 'initial',
    superAdminInfo: 'initial'
  }

  settingsErrors: Record<string, string> = {
    generalModelSettings: '',
    recordedCourseData: '',
    doseCalculatedGeneralLimits: '',
    doseCalculatedTargetLimits: '',
    doseCalculatedWarningLimits: '',
    reportTemplates: '',
    superAdminInfo: ''
  }

  adminHospitalDrugGeneralModelSettings: IAdminHospitalDrugGeneralModelSettings | null = null
  adminHospitalDrugRecordedCourseData: IAdminHospitalDrugRecordedCourseData | null = null
  adminHospitalDrugDoseCalculatedGeneralLimits: IAdminHospitalDrugDoseCalculatedGeneralLimits | null = null
  adminHospitalDrugDoseCalculatedTargetLimits: IAdminHospitalDrugDoseCalculatedTargetLimits | null = null
  adminHospitalDrugDoseCalculatedWarningLimits: IAdminHospitalDrugDoseCalculatedWarningLimits | null = null
  adminHospitalDrugReportTemplates: IAdminHospitalDrugReportTemplates | null = null
  adminHospitalDrugSuperAdminInfo: IAdminHospitalDrugSuperAdminInfo | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  setLoadStateSettings(loadState: TLoadState, setting: string) {
    this.settingsLoadStates[setting] = loadState
  }

  setErrorSettings(errorState: 'loadError' | 'updateError', error: string, setting: string) {
    this.settingsErrors[setting] = error
    this.setLoadStateSettings(errorState, setting)
  }

  setStore(adminHospitalDrugDetails: IAdminHospitalDrugDetails) {
    this.resetStore()
    this.adminHospitalDrugDetails = new AdminHospitalDrugDetails(this, adminHospitalDrugDetails)
  }

  resetStore() {
    this.adminHospitalDrugDetails = null
  }

  repackageResponseForBuilding(attributes: any) {
    return { attributes }
  }

  repackageSuperAdminInfoForBuilding(attributes: IAdminHospitalDrugSuperAdminInfoConstraints[], name: string) {
    let defaultValue = undefined
    let currentValue = undefined

    const returnValues = attributes.reduce<IInputFieldItem[]>((acc: IInputFieldItem[], patientConstraint: IAdminHospitalDrugSuperAdminInfoConstraints) => {
      const value = { value: patientConstraint.id, label: patientConstraint.value }
      if (patientConstraint.isDefault) {
        defaultValue = value
      }
      if (patientConstraint.isCurrent) {
        currentValue = value
      }

      return acc.concat(value)
    }, [])

    return {
      attributes: {
        patientConstraints: {
          type: 'selection',
          attributes: {
            name: name,
            label: 'Patient Constraints:',
            currentValue: currentValue,
            defaultValue: defaultValue,
            allowedValues: returnValues,
            isRequired: false
          }
        }
      }
    }
  }

  async fetchAdminHospitalDrugModelDetails(hospitalId: string, drugModelId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugDetails>>(adminHospitalAddDrugDetailsGetUrl(hospitalId, drugModelId), {
        headers
      })
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  setStoreGeneralModelSettings(adminHospitalReportSettings: IAdminHospitalDrugGeneralModelSettings) {
    this.resetStoreGeneralModelSettings()
    this.adminHospitalDrugGeneralModelSettings = new AdminHospitalDrugGeneralModelSettings(
      this,
      adminHospitalReportSettings
    )
  }

  resetStoreGeneralModelSettings() {
    this.adminHospitalDrugGeneralModelSettings = null
  }

  async fetchAdminHospitalDrugGeneralModelSettings(hospitalId: string, drugModelId: string) {
    this.setLoadStateSettings('loading', 'generalModelSettings')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugGeneralModelSettings>>(
        adminHospitalDrugGeneralModelSettingsGetUrl(hospitalId, drugModelId),
        {
          headers
        }
      )
      .then((response) => {
        this.setStoreGeneralModelSettings(response.data.data)
        this.setLoadStateSettings('loaded', 'generalModelSettings')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'generalModelSettings')
      })
  }

  async putAdminHospitalDrugGeneralModelSettings(
    hospitalId: string,
    drugModelId: string,
    details: IPutAdminHospitalDrugGeneralModelSettings
  ) {
    this.setLoadStateSettings('loading', 'generalModelSettings')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminHospitalDrugDetails>>(
        adminHospitalDrugGeneralModelSettingsPutUrl(hospitalId, drugModelId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalDrugModelDetailsGeneralWrite',
            attributes: details
          }
        },
        {
          headers
        }
      )
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadStateSettings('loaded', 'generalModelSettings')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('updateError', loggableError, 'generalModelSettings')
      })
  }

  setStoreRecordedCourseData(adminHospitalDrugRecordedCourseData: IAdminHospitalDrugRecordedCourseData) {
    this.resetStoreRecordedCourseData()
    this.adminHospitalDrugRecordedCourseData = new AdminHospitalDrugRecordedCourseData(
      this,
      adminHospitalDrugRecordedCourseData
    )
  }

  resetStoreRecordedCourseData() {
    this.adminHospitalDrugRecordedCourseData = null
  }

  async fetchAdminHospitalDrugRecordedCourseData(hospitalId: string, drugModelId: string) {
    this.setLoadStateSettings('loading', 'recordedCourseData')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugRecordedCourseData>>(
        adminHospitalDrugRecordedCourseDataGetUrl(hospitalId, drugModelId),
        {
          headers
        }
      )
      .then((response) => {
        this.setStoreRecordedCourseData(response.data.data)
        this.setLoadStateSettings('loaded', 'recordedCourseData')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'recordedCourseData')
      })
  }

  async putAdminHospitalDrugRecordedCourseData(
    hospitalId: string,
    drugModelId: string,
    details: IPutAdminHospitalDrugRecordedCourseData
  ) {
    this.setLoadStateSettings('loading', 'recordedCourseData')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminHospitalDrugDetails>>(
        adminHospitalDrugRecordedCourseDataPutUrl(hospitalId, drugModelId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalDrugModelDetailsRecordedCourseDataLimitsWrite',
            attributes: details
          }
        },
        {
          headers
        }
      )
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadStateSettings('loaded', 'recordedCourseData')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('updateError', loggableError, 'recordedCourseData')
      })
  }

  setStoreDoseCalculatedGeneralLimits(
    adminHospitalDrugDoseCalculatedGeneralLimits: IAdminHospitalDrugDoseCalculatedGeneralLimits
  ) {
    this.resetStoreDoseCalculatedGeneralLimits()
    this.adminHospitalDrugDoseCalculatedGeneralLimits = new AdminHospitalDrugDoseCalculatedGeneralLimits(
      this,
      adminHospitalDrugDoseCalculatedGeneralLimits
    )
  }

  resetStoreDoseCalculatedGeneralLimits() {
    this.adminHospitalDrugDoseCalculatedGeneralLimits = null
  }

  async fetchAdminHospitalDrugDoseCalculatedGeneralLimits(hospitalId: string, drugModelId: string) {
    this.setLoadStateSettings('loading', 'doseCalculatedGeneralLimits')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugDoseCalculatedGeneralLimits>>(
        adminHospitalDrugDoseCalculatedGeneralGetUrl(hospitalId, drugModelId),
        {
          headers
        }
      )
      .then((response) => {
        this.setStoreDoseCalculatedGeneralLimits(response.data.data)
        this.setLoadStateSettings('loaded', 'doseCalculatedGeneralLimits')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'doseCalculatedGeneralLimits')
      })
  }

  async putAdminHospitalDrugDoseCalculatedGeneralLimits(
    hospitalId: string,
    drugModelId: string,
    details: IPutAdminHospitalDrugDoseCalculatedGeneralLimits
  ) {
    this.setLoadStateSettings('loading', 'doseCalculatedGeneralLimits')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminHospitalDrugDetails>>(
        adminHospitalDrugDoseCalculatedGeneralPutUrl(hospitalId, drugModelId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalDrugModelDetailsDosesCalculatedGeneralLimitsWrite',
            attributes: details
          }
        },
        {
          headers
        }
      )
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadStateSettings('loaded', 'doseCalculatedGeneralLimits')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('updateError', loggableError, 'doseCalculatedGeneralLimits')
      })
  }

  setStoreDoseCalculatedTargetLimits(
    adminHospitalDrugDoseCalculatedTargetLimits: IAdminHospitalDrugDoseCalculatedTargetLimits
  ) {
    this.resetStoreDoseCalculatedTargetLimits()
    this.adminHospitalDrugDoseCalculatedTargetLimits = new AdminHospitalDrugDoseCalculatedTargetLimits(
      this,
      adminHospitalDrugDoseCalculatedTargetLimits
    )
  }

  resetStoreDoseCalculatedTargetLimits() {
    this.adminHospitalDrugDoseCalculatedTargetLimits = null
  }

  async fetchAdminHospitalDrugDoseCalculatedTargetLimits(hospitalId: string, drugModelId: string) {
    this.setLoadStateSettings('loading', 'doseCalculatedTargetLimits')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugDoseCalculatedTargetLimits>>(
        adminHospitalDrugDoseCalculatedTargetGetUrl(hospitalId, drugModelId),
        {
          headers
        }
      )
      .then((response) => {
        this.setStoreDoseCalculatedTargetLimits(response.data.data)
        this.setLoadStateSettings('loaded', 'doseCalculatedTargetLimits')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'doseCalculatedTargetLimits')
      })
  }

  async putAdminHospitalDrugDoseCalculatedTargetLimits(
    hospitalId: string,
    drugModelId: string,
    details: IPutAdminHospitalDrugDoseCalculatedTargetLimits
  ) {
    this.setLoadStateSettings('loading', 'doseCalculatedTargetLimits')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminHospitalDrugDetails>>(
        adminHospitalDrugDoseCalculatedTargetPutUrl(hospitalId, drugModelId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalDrugModelDetailsDosesCalculatedTargetLimitsWrite',
            attributes: details
          }
        },
        {
          headers
        }
      )
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadStateSettings('loaded', 'doseCalculatedTargetLimits')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('updateError', loggableError, 'doseCalculatedTargetLimits')
      })
  }

  setStoreDoseCalculatedWarningLimits(
    adminHospitalDrugDoseCalculatedWarningLimits: IAdminHospitalDrugDoseCalculatedWarningLimits
  ) {
    this.resetStoreDoseCalculatedWarningLimits()
    this.adminHospitalDrugDoseCalculatedWarningLimits = new AdminHospitalDrugDoseCalculatedWarningLimits(
      this,
      adminHospitalDrugDoseCalculatedWarningLimits
    )
  }

  resetStoreDoseCalculatedWarningLimits() {
    this.adminHospitalDrugDoseCalculatedWarningLimits = null
  }

  async fetchAdminHospitalDrugDoseCalculatedWarningLimits(hospitalId: string, drugModelId: string) {
    this.setLoadStateSettings('loading', 'doseCalculatedWarningLimits')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugDoseCalculatedWarningLimits>>(
        adminHospitalDrugDoseCalculatedWarningGetUrl(hospitalId, drugModelId),
        {
          headers
        }
      )
      .then((response) => {
        this.setStoreDoseCalculatedWarningLimits(response.data.data)
        this.setLoadStateSettings('loaded', 'doseCalculatedWarningLimits')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'doseCalculatedWarningLimits')
      })
  }

  async putAdminHospitalDrugDoseCalculatedWarningLimits(
    hospitalId: string,
    drugModelId: string,
    details: IPutAdminHospitalDrugDoseCalculatedWarningLimits
  ) {
    this.setLoadStateSettings('loading', 'doseCalculatedWarningLimits')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminHospitalDrugDetails>>(
        adminHospitalDrugDoseCalculatedWarningPutUrl(hospitalId, drugModelId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalDrugModelDetailsDosesCalculatedWarningLimitsWrite',
            attributes: details
          }
        },
        {
          headers
        }
      )
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadStateSettings('loaded', 'doseCalculatedWarningLimits')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('updateError', loggableError, 'doseCalculatedWarningLimits')
      })
  }

  setStoreReportTemplates(adminHospitalDrugReportTemplates: IAdminHospitalDrugReportTemplates) {
    this.resetStoreReportTemplates()
    this.adminHospitalDrugReportTemplates = new AdminHospitalDrugReportTemplates(this, adminHospitalDrugReportTemplates)
  }

  resetStoreReportTemplates() {
    this.adminHospitalDrugReportTemplates = null
  }

  async fetchAdminHospitalDrugReportTemplates(hospitalId: string, drugModelId: string) {
    this.setLoadStateSettings('loading', 'reportTemplates')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugReportTemplates>>(
        adminHospitalDrugReportTemplatesGetUrl(hospitalId, drugModelId),
        {
          headers
        }
      )
      .then((response) => {
        this.setStoreReportTemplates(response.data.data)
        this.setLoadStateSettings('loaded', 'reportTemplates')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'reportTemplates')
      })
  }

  async putAdminHospitalDrugReportTemplates(
    hospitalId: string,
    drugModelId: string,
    details: IPutAdminHospitalDrugReportTemplates
  ) {
    this.setLoadStateSettings('loading', 'reportTemplates')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminHospitalDrugDetails>>(
        adminHospitalDrugReportTemplatesPutUrl(hospitalId, drugModelId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalDrugModelDetailsReportTemplatesWrite',
            attributes: details
          }
        },
        {
          headers
        }
      )
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadStateSettings('loaded', 'reportTemplates')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('updateError', loggableError, 'reportTemplates')
      })
  }

  setStoreSuperAdminInfo(adminHospitalDrugSuperAdminInfo: IAdminHospitalDrugSuperAdminInfo) {
    this.resetStoreSuperAdminInfo()
    this.adminHospitalDrugSuperAdminInfo = new AdminHospitalDrugSuperAdminInfo(this, adminHospitalDrugSuperAdminInfo)
  }

  resetStoreSuperAdminInfo() {
    this.adminHospitalDrugSuperAdminInfo = null
  }

  async fetchAdminHospitalDrugSuperAdminInfo(hospitalId: string, drugModelId: string) {
    this.setLoadStateSettings('loading', 'superAdminInfo')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminHospitalDrugSuperAdminInfo>>(
        adminHospitalDrugSuperAdminInfoGetUrl(hospitalId, drugModelId),
        {
          headers
        }
      )
      .then((response) => {
        this.setStoreSuperAdminInfo(response.data.data)
        this.setLoadStateSettings('loaded', 'superAdminInfo')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'superAdminInfo')
      })
  }

  async putAdminHospitalDrugSuperAdminInfo(
    hospitalId: string,
    drugModelId: string,
    details: IPutAdminHospitalDrugSuperAdminInfo
  ) {
    this.setLoadStateSettings('loading', 'superAdminInfo')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminHospitalDrugDetails>>(
        adminHospitalDrugSuperAdminInfoPutUrl(hospitalId, drugModelId),
        {
          data: {
            id: drugModelId,
            type: 'adminHospitalDrugModelDetailsSuperAdminInfoWrite',
            attributes: details
          }
        },
        {
          headers
        }
      )
      .then((response) => {
        this.setStore(response.data.data)
        this.setLoadStateSettings('loaded', 'superAdminInfo')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('updateError', loggableError, 'superAdminInfo')
      })
  }
}
