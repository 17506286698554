import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { saveAs } from 'file-saver'
import classnames from 'classnames'
import moment from 'moment'
import { Button, InfoModal, Modal, IDropdownItem, InfoBubble, Icons, LOADING_GREY } from '@doseme/cohesive-ui'

import { showErrorToast, showSuccessToast } from '../../../../../../../../../../shared/toast'
import { IModalProps } from '../../../../../../../../types'
import { ICustomDoseForms, ICustomTargetForms, IOutcome } from '../../types'
import { WarningsDisplay } from '../WarningsDisplay'
import {
  useAuthStore,
  useCourseStore,
  useDosingRecommendationStore,
  useHistoricalSimulationStore,
  useGenerateReportStore,
  usePatientStore
} from '../../../../../../../../../../hooks/useStore'
import { IDoseReportResponse } from '../../../../../../../../../../store/generateReport/types'
import {
  IDrugSpecificAttr,
  IPerDoseOutcome,
  IPredictedOutcome,
  TModelType
} from '../../../../../../../../../../store/dosingRecommendation/types'
import { ICourseLimits } from '../../../../../../../../../../store/course/types'
import { tableReadable } from '../../../../../../../../../../constants/timeFormat'
import {
  getCurrentSimulationAttributes,
  getSimulationType,
  getReportTargetOutcomes,
  getReportPredictedOutcomes
} from './utils'

import './index.scss'

interface IProps extends IModalProps {
  patientId: string
  courseId: string
  drugModelId: string
  limits: ICourseLimits
  currentModel: TModelType
  predictedOutcome: IPredictedOutcome | null
  nextDoseAt: Date
  hospitalTimezone: string
  selectedTargetDropdownItem: IDropdownItem
  customDoseForms: ICustomDoseForms
  customTargetForms: ICustomTargetForms
  doseDescription: string
  calcTypeName: string
  warnings: string[]
  warningsChecked: boolean
  setWarningsChecked: (checked: boolean) => void
  defaultLastPredictedDoseOutcomes: IPerDoseOutcome | null
  drugSpecificAttr: IDrugSpecificAttr | null
}

const mapOutcomes = (outcomeTitle: 'Target' | 'Predicted', outcomes: IOutcome[]): JSX.Element[] => {
  return outcomes.map((o) => (
    <div className='report-gen-attribute' key={`outcome-${outcomeTitle}-${o.outcomeType}`}>
      <div className='report-gen-info-header'>{outcomeTitle + ' ' + o.outcomeType}</div>
      <div>{o.amount ? o.amount.value.toString() + (o.amount.unit ? ' ' + o.amount.unit.name : '') : 'N/A'}</div>
    </div>
  ))
}

export const GenerateReportModal: React.FC<IProps> = observer((props) => {
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const generateReportStore = useGenerateReportStore()
  const patientStore = usePatientStore()
  const courseStore = useCourseStore()
  const authStore = useAuthStore()

  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [base64Preview, setBase64Preview] = useState('')
  const [clinicalNotesInput, setClinicalNotesInput] = useState('')

  const closeThisModal = () => {
    props.setShow(false)
    setBase64Preview('')
    setClinicalNotesInput('')
  }

  const sendRecommendationDetails = (): void => {
    const historicalPerDoseOutcomes =
      dosingRecommendationStore.dosingRecommendation[props.currentModel]?.attributes.modelResults?.[props.currentModel]
        ?.historicalPerDoseOutcomes ||
      historicalSimulationStore.historicalSimulationData?.attributes.modelResults?.historicalPerDoseOutcomes

    if (historicalPerDoseOutcomes && props.defaultLastPredictedDoseOutcomes) {
      const aucUnits = courseStore.course?.attributes.limits.aucTarget.default.unit

      if (authStore.auth && patientStore.patient && courseStore.course && aucUnits) {
        const latestHistorical = historicalPerDoseOutcomes[historicalPerDoseOutcomes.length - 1]

        const simulationDetails = {
          patient_id: patientStore.patient.attributes.longId,
          clinician_id: authStore.auth.attributes.clinicianLongId,
          historical_auc24: {
            value: latestHistorical.auc24,
            unit: aucUnits,
            time: latestHistorical.time
          },
          predicted_auc24: {
            value: props.defaultLastPredictedDoseOutcomes.auc24,
            unit: aucUnits,
            time: props.defaultLastPredictedDoseOutcomes.time
          },
          selected_drug_model: courseStore.course.attributes.drugModel.name,
          action: 'DoseMeRx.Dose.Recommendation.Details'
        }

        window.parent.postMessage(simulationDetails, '*')

        if (window.opener) {
          window.opener.postMessage(simulationDetails, '*')
        }
      }
    }
  }

  const handleReportRequest = async (isPreview?: 'isPreview') => {
    const simulationAttributes = getCurrentSimulationAttributes(
      props.currentModel,
      props.selectedTargetDropdownItem,
      props.customDoseForms,
      props.customTargetForms,
      props.limits,
      props.nextDoseAt,
      props.hospitalTimezone
    )
    let response = null

    if (simulationAttributes !== null) {
      response = await generateReportStore.generateReport(
        props.patientId,
        props.courseId,
        props.drugModelId,
        simulationAttributes,
        getSimulationType(props.currentModel),
        isPreview,
        clinicalNotesInput,
        props.drugSpecificAttr
      )
    }

    if (!response || generateReportStore.loadState === 'loadError') {
      showErrorToast(generateReportStore.error || (isPreview ? 'Preview failed to load' : 'Report failed to save'))

      return
    }

    if (isPreview) {
      const reportDataResponse = response.data as IDoseReportResponse
      setBase64Preview(reportDataResponse.data.report)
      setShowPreviewModal(true)

      return
    }

    if (window.env.VENDOR_MODE === 'dss') {
      sendRecommendationDetails()
    }

    if (['cerner', 'epic'].includes(window.env.VENDOR_MODE)) {
      showSuccessToast('Report saved')
      setShowPreviewModal(false)
      closeThisModal()

      return
    }

    // saves the file
    const reportFileResponse = response.data as string
    const reportFileBlob = new Blob([reportFileResponse], { type: 'application/pdf' })
    // save with the filename supplied to us in double quotes from the content-disposition header
    saveAs(reportFileBlob, response.headers['content-disposition']?.match(/"([^"]+)"/)?.[1] || 'report.pdf')
    showSuccessToast('Report saved')
    setShowPreviewModal(false)
    closeThisModal()
  }

  const previewModalButtonBar: JSX.Element = (
    <div className='d-flex'>
      <Button variant='dark-outline' className='mr-2' onClick={() => setShowPreviewModal(false)}>
        Close
      </Button>
      <Button
        variant='primary'
        onClick={() => handleReportRequest()} // can't shorthand due to optional isPreview param
        loading={generateReportStore.loadState === 'saving'}
        disabled={['previewLoading', 'saving'].includes(generateReportStore.loadState)}
      >
        Save
      </Button>
    </div>
  )

  const modalContent: JSX.Element = (
    <>
      {/* Nested preview modal */}
      <Modal show={showPreviewModal} onHide={() => setShowPreviewModal(false)}>
        <div className='report-preview-content-wrapper'>
          <InfoModal
            size='m'
            title='Dosing report preview'
            message={
              base64Preview ? (
                window.env.VENDOR_MODE === 'epic' ? (
                  <div
                    className='dose-report-preview-panel-epic'
                    dangerouslySetInnerHTML={{
                      __html: atob(base64Preview)
                    }}
                  />
                ) : (
                  <iframe className='dose-report-preview-panel' srcDoc={atob(base64Preview)} />
                )
              ) : (
                <div className='dose-report-preview-panel-loading'>
                  <Icons.ThinSpinner strokeWidth={12} r={30} stroke={LOADING_GREY} width='60px' />
                </div>
              )
            }
            linkComponent={previewModalButtonBar}
            onDismiss={() => setShowPreviewModal(false)}
            limitHeightToWindow
          />
        </div>
      </Modal>

      <div className='w-100'>
        {window.env.INSTANCE_TYPE === 'TDI' && (
          <div className='report-gen-info'>
            <InfoBubble bubbleTitle={'This dose recommendation is for teaching and/or learning purposes only.'} />
          </div>
        )}
        <div className='report-gen-content'>
          <div className='report-gen-content-stats'>
            <div className='report-gen-nextdose'>
              <span className='font-bold'>Next dose:&nbsp;</span>
              {moment(props.nextDoseAt).format(tableReadable)}
            </div>
            <div className='report-gen-regimen'>
              <div className='report-gen-header'>{props.calcTypeName} regimen:</div>
              <div className=''>{props.doseDescription}</div>
            </div>
            <div className='d-flex w-100'>
              <div className='report-gen-target-panel'>
                {mapOutcomes(
                  'Target',
                  getReportTargetOutcomes(
                    props.currentModel,
                    props.selectedTargetDropdownItem,
                    props.defaultLastPredictedDoseOutcomes,
                    props.customTargetForms,
                    props.limits,
                    courseStore.course!.attributes.simulateNextDose.defaultCustomTarget,
                    props.predictedOutcome
                  )
                )}
              </div>
              <div className='report-gen-predicted-panel'>
                {mapOutcomes(
                  'Predicted',
                  getReportPredictedOutcomes(
                    props.currentModel,
                    props.selectedTargetDropdownItem,
                    props.defaultLastPredictedDoseOutcomes,
                    props.limits,
                    props.predictedOutcome,
                    courseStore.course!.attributes.simulateNextDose.defaultCustomTarget
                  )
                )}
              </div>
            </div>
          </div>
          <div className='report-gen-content-notes'>
            <div className='report-gen-info-header report-gen-notes-header'>Clinical notes:</div>
            <textarea
              className='clinical-notes'
              placeholder='Enter notes to include on PDF here...'
              value={clinicalNotesInput}
              onChange={(e) => setClinicalNotesInput(e.currentTarget.value)}
            />
          </div>
        </div>
      </div>
    </>
  )

  const warningsContent = (
    <div className='ie11-block-contains-flexbox-bugfix'>
      <WarningsDisplay display='report' warnings={props.warnings} />
      <div className='mt-3 warnings-confirm-box'>
        <input
          type='checkbox'
          id='confirm-warnings'
          checked={props.warningsChecked}
          onChange={() => props.setWarningsChecked(!props.warningsChecked)}
        />
        <label className='pl-2 fs-14' htmlFor='confirm-warnings'>
          Approve and continue
        </label>
      </div>
    </div>
  )

  const buttonBar: JSX.Element = (
    <div className='d-flex'>
      <Button
        variant='primary-outline'
        className='mr-2'
        onClick={() => handleReportRequest('isPreview')}
        loading={generateReportStore.loadState === 'previewLoading'}
        disabled={['previewLoading', 'saving'].includes(generateReportStore.loadState) || !props.warningsChecked}
      >
        Preview Report &#8599;
      </Button>
      <Button
        variant='primary'
        onClick={() => handleReportRequest()}
        loading={generateReportStore.loadState === 'saving'}
        disabled={['previewLoading', 'saving'].includes(generateReportStore.loadState) || !props.warningsChecked}
      >
        Save
      </Button>
    </div>
  )

  return (
    <Modal show={props.show} onHide={closeThisModal}>
      <div className={classnames({ 'report-modal-position-override': props.warnings.length })}>
        <InfoModal
          size='m'
          linkComponent={buttonBar}
          title='Generate a dosing report'
          message={props.warnings.length ? [modalContent, warningsContent] : modalContent}
          onDismiss={closeThisModal}
        />
      </div>
    </Modal>
  )
})
