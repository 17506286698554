import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  BackArrowButton,
  ControlButton,
  IColumnElement,
  IRowElement,
  Icons,
  ListButton,
  PaginationPanel,
  SearchInput,
  SmartList,
  TooltipMenu,
  ConfirmModal,
  MENU_GREY,
  Modal,
  Tag,
  IBreadcrumb
} from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getPageCount, paginate } from '../../../../../utils/pagination'
import {
  useAdminVendorSiteListStore,
  useAdminVendorListStore,
  useBreadcrumbsStore
} from '../../../../../hooks/useStore'
import { sortAlpha } from '../../../../../utils/smartListUtils'
import { IAdminVendorSiteListItem } from '../../../../../store/Admin/AdminVendorSiteList/types'
import { IAdminVendorListItem } from '../../../../../store/Admin/AdminVendorList/types'
import { IProps } from './types'
import { searchVendorSites } from './utils'
import { vendorSiteListColumns, itemsPerPage, vendorSiteActionData } from './constants'
import { showErrorToast, showSuccessToast } from '../../../../../shared/toast'
import { getAdminRoutePrefix } from '../../../utils'
import { handleBackButton } from '../../../../../utils/navigation'

import './index.scss'

export const VendorSiteList: React.FC<IProps> = observer((props) => {
  const [searchText, setSearchText] = useState('')
  const [sortColIndex, setSortColIndex] = useState(0)
  const [sortColAscending, setSortColAscending] = useState(true)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteVendorSite, setDeleteVendorSite] = useState<IAdminVendorSiteListItem | undefined>(undefined)
  const [adminVendorListDetails, setAdminVendorListDetails] = useState<IAdminVendorListItem | undefined>(undefined)

  const adminVendorSiteListStore = useAdminVendorSiteListStore()
  const adminVendorListStore = useAdminVendorListStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    if (adminVendorListStore.loadState === 'loaded') {
      setAdminVendorListDetails(adminVendorListStore.adminVendors.get(props.vendorId))

      const breadcrumbs: IBreadcrumb[] = [
        { label: 'Admin hub', onClick: () => returnToAdminHub() },
        {
          label: 'Vendors',
          onClick: () =>
            handleBackButton(getAdminRoutePrefix(props.patientId) + '/vendors/', history)
        },
        { label: adminVendorListStore.adminVendors.get(props.vendorId)?.attributes.name || '' }
      ]
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [adminVendorListStore.loadState])

  useEffect(() => {
    if (adminVendorListStore.loadState === 'initial') {
      adminVendorListStore.fetchVendors()
    }

    adminVendorSiteListStore.fetchVendorSites(props.vendorId)
  }, [])

  useEffect(() => {
    if (adminVendorSiteListStore.loadState === 'loaded' && adminVendorSiteListStore.adminVendorSites) {
      const searchedVendorSites: IAdminVendorSiteListItem[] = searchVendorSites(
        searchText,
        adminVendorSiteListStore.adminVendorSites
      )
      setTotalPages(getPageCount(searchedVendorSites.length, itemsPerPage))
      const formattedVendorSites = formatVendorSites(searchedVendorSites)
      const sortedSearchedFormattedVendorSites = sortAlpha(formattedVendorSites, sortColIndex, sortColAscending)
      setSortedList(sortedSearchedFormattedVendorSites)
    }
  }, [adminVendorSiteListStore.loadState, searchText, sortColIndex, sortColAscending])

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const handleCreateNewVendorSite = () => {
    history.push(getAdminRoutePrefix(props.patientId) + `/vendors/${props.vendorId}/sites/new`)
  }

  const handleVendorSiteDetailLink = (vendorSiteId: string) => {
    history.push(getAdminRoutePrefix(props.patientId) + `/vendors/${props.vendorId}/sites/${vendorSiteId}`)
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColIndex(colIndex)
    setSortColAscending(ascending)
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText)
    updateCurrentPage(1)
  }

  const handleDeleteVendorSite = (vendorSite: IAdminVendorSiteListItem) => {
    setShowDeleteModal(true)
    setDeleteVendorSite(vendorSite)
  }

  const formatVendorSites = (vendorSites: IAdminVendorSiteListItem[]): IRowElement[] => {
    return vendorSites.map((vendorSite, key) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Site name',
          link: () => handleVendorSiteDetailLink(vendorSite.id),
          text: vendorSite.attributes.name
        },
        {
          name: 'Site key',
          text: vendorSite.attributes.key,
          onClick: () => handleVendorSiteDetailLink(vendorSite.id)
        },
        {
          name: 'Hospital',
          text: vendorSite.attributes.hospital.name,
          onClick: () => handleVendorSiteDetailLink(vendorSite.id)
        },
        {
          name: '',
          element: (
            <div className='vendorsite-list-action-button' data-testid='ellipsis-icon'>
              <TooltipMenu
                button={
                  <ListButton size='cm'>
                    <Icons.Ellipsis />
                  </ListButton>
                }
                data={vendorSiteActionData(vendorSite, handleDeleteVendorSite)}
                alignRight={true}
                chevronOffset={10}
              />
            </div>
          )
        }
      ]

      return {
        id: vendorSite.id,
        columns: recordColumns
      }
    })
  }

  const deleteModalMessage = () => {
    return (
      <div className='preview-panel-delete-modal'>
        <Tag size='large' text={deleteVendorSite?.attributes.hospital.name || ''} color={MENU_GREY} bold />
        <br />
        <div>The selected site will be permanently deleted.</div>
        <br />
        <div className='font-weight-bold'>This cannot be reversed.</div>
      </div>
    )
  }

  const deleteModal = () => {
    if (deleteVendorSite) {
      return (
        <Modal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false)
          }}
        >
          <ConfirmModal
            entityType='Vendor Site'
            confirmationType='delete'
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={() => handleDelete(deleteVendorSite)}
            title='Delete Vendor Site'
            message={deleteModalMessage()}
            cancelButtonLabel='Cancel'
            confirmButtonLabel='Delete'
          />
        </Modal>
      )
    }
  }

  const handleDelete = async (vendorSite: IAdminVendorSiteListItem) => {
    if (vendorSite) {
      await adminVendorSiteListStore.deleteVendorSite(props.vendorId, vendorSite.id)
    }

    if (adminVendorListStore.loadState === 'updateError') {
      showErrorToast(adminVendorListStore.error || 'Failed to delete vendor site')

      return
    }

    setDeleteVendorSite(undefined)
    showSuccessToast('Vendor site deleted')
    setShowDeleteModal(false)
  }

  return (
    <div data-testid='vendorsite-list' className='co-vendorsite-list-page'>
      {deleteModal()}
      <div className='vendorsite-list-heading'>
        <div className='vendorsite-list-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(getAdminRoutePrefix(props.patientId) + '/vendors', history)
            }
          />
        </div>
        <div className='vendorsite-list-title'>
          Vendor sites:&nbsp;
          <div className='vendorsite-list-name'>{adminVendorListDetails?.attributes.name}</div>
        </div>
        <div className='create-new-vendorsite-btn'>
          <ControlButton icon={faPlus} onClick={handleCreateNewVendorSite}>
            Add new site
          </ControlButton>
        </div>
      </div>
      <div className='vendorsite-list-content-panel'>
        <div className='vendorsite-list-search-bar-container'>
          <div className='vendorsite-list-search-bar'>
            <SearchInput
              value={searchText}
              onChange={handleSearch}
              width={360}
              borderRadius={12}
              placeholder='Search list...'
            />
          </div>
        </div>
        <div className='vendorsite-list-list'>
          <SmartList
            cols={vendorSiteListColumns(handleSort)}
            data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
            defaultSortColumn='Site name'
            defaultSortDirection='asc'
            loading={adminVendorSiteListStore.loadState === 'loading'}
            textIfEmpty='No data to display'
          />
        </div>
        {sortedList && sortedList.length > 10 && (
          <div className='patient-profile-pagination-panel'>
            <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
          </div>
        )}
      </div>
    </div>
  )
})
