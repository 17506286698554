import axios, { AxiosError, AxiosResponse } from 'axios'
import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import { applicableDrugModelsGetUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { ApplicableDrugModel } from './ApplicableDrugModels'
import { IApplicableDrugModel } from './types'

export class ApplicableDrugModelsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  applicableDrugModels: ObservableMap<string, ApplicableDrugModel> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  resetApplicableDrugModels(drugModels: IApplicableDrugModel[]) {
    this.applicableDrugModels = observable.map({}, { deep: false })
    drugModels.forEach((drugModel) =>
      this.applicableDrugModels.set(drugModel.id, new ApplicableDrugModel(this, drugModel))
    )
  }

  resetStore() {
    this.applicableDrugModels = observable.map({}, { deep: false })
    this.setLoadState('initial')
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchApplicableDrugModels(patientId: string, courseId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IApplicableDrugModel[]>>(applicableDrugModelsGetUrl(patientId, courseId), { headers })
      .then((response) => {
        this.resetApplicableDrugModels(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
        this.resetApplicableDrugModels([])
      })
  }
}
