import axios, { AxiosError } from 'axios'
import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import { adminChangeLogGetUrl } from '../../../constants/api'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { IChangeLogItem, IGETChangeLog, TChangeLogType } from './types'
import { AdminChangeLogListItem } from './AdminChangeLogItem'

export class AdminChangeLogListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  count: number = 0
  adminChangeLogs: ObservableMap<string, IChangeLogItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  resetAdminChangeLogs(adminChangeLogs: IChangeLogItem[]) {
    this.adminChangeLogs = observable.map({}, { deep: false })
    adminChangeLogs.forEach((changeLog) =>
      this.adminChangeLogs.set(changeLog.id, new AdminChangeLogListItem(this, changeLog))
    )
  }

  setCount(count: number) {
    this.count = count
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchAdminChangeLogs(count: number, type: TChangeLogType) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<IGETChangeLog>(adminChangeLogGetUrl(), {
        headers,
        params: {
          count: count,
          category: type
        }
      })
      .then((response) => {
        this.setCount(response.data.meta.count)
        this.resetAdminChangeLogs(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
