import { Icons } from '@doseme/cohesive-ui'
import ReactTooltip from 'react-tooltip'

import './index.scss'

export const SimulationOutcomesInfoIcon: React.FC = () => {
  return (
    <div className='simulation-outcomes-info-icon'>
      <ReactTooltip id='simulation-outcomes-info-tip' place='top' effect='solid'>
        Based on available doses and observations. <br />
        Values represent final dose in this calculation.
      </ReactTooltip>

      <div data-tip data-for='simulation-outcomes-info-tip' className='info-icon-simoutcomes'>
        <Icons.Info />
      </div>
    </div>
  )
}
