import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { decode } from 'he'
import { BackArrowButton } from '@doseme/cohesive-ui'

import {
  useAdminHospitalDetailsStore,
  useAdminHospitalDrugDetailsStore,
  useBreadcrumbsStore,
  useClinicianStore
} from '../../../../../../hooks/useStore'
import { IDrugDetailsProps, TInlineEditPanels } from './types'
import { GeneralSettingsModal } from './components/GeneralModelSettings/GeneralSettingsModal'
import { DosingReportTemplatesModal } from './components/DosingReportTemplates/DosingReportTemplatesModal'
import { SuperAdminSettingsModal } from './components/SuperadminSettings/SuperAdminSettingsModal'
import { SuperadminSettings } from './components/SuperadminSettings/SuperadminSettings'
import { CalculatedDosesGeneralSettings } from './components/CalculatedDosesGeneralSettings/CalculatedDosesGeneralSettings'
import { CalculatedDosesLimitsAndWarnings } from './components/CalculatedDosesLimitsAndWarnings/CalculatedDosesLimitsAndWarnings'
import { CalculatedDosesTargetSettings } from './components/CalculatedDosesTargetSettings/CalculatedDosesTargetSettings'
import { DosingReportTemplates } from './components/DosingReportTemplates/DosingReportTemplates'
import { GeneralModelSettings } from './components/GeneralModelSettings/GeneralModelSettings'
import { RecordedCourseData } from './components/RecordedCourseData/RecordedCourseData'
import { getAdminRoutePrefix } from '../../../../utils'
import { handleBackButton } from '../../../../../../utils/navigation'

import './index.scss'

export const HospitalDrugDetails: React.FC<IDrugDetailsProps> = observer((props) => {
  const [showHospitalDrugModelSettingsModal, setShowHospitalDrugModelSettingsModal] = useState<boolean>(false)
  const [showHospitalDrugReportTemplatesModal, setShowHospitalDrugReportTemplatesModal] = useState<boolean>(false)
  const [showHospitalDrugSuperAdminSettingsModal, setShowHospitalDrugSuperAdminSettingsModal] = useState<boolean>(false)
  const [panelWithEditEnabled, setPanelWithEditEnabled] = useState<TInlineEditPanels>(null)

  const clinicianStore = useClinicianStore()
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    if (adminHospitalDetailsStore.loadState === 'loaded' && adminHospitalDrugDetailsStore.loadState === 'loaded') {
      const breadcrumbs = [
        { label: 'Admin hub', onClick: () => returnToAdminHub() },
        { label: 'Drug settings', onClick: () => returnToDrugSettings() },
        {
          label: `${decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}`,
          onClick: () => returnToHospitalDrugs()
        },
        {
          label: `${adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.name}`
        }
      ]
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [adminHospitalDetailsStore.loadState, adminHospitalDrugDetailsStore.loadState])

  useEffect(() => {
    if (adminHospitalDetailsStore.loadState !== 'loaded') {
      adminHospitalDetailsStore.fetchAdminHospitalDetails(props.hospitalId)
    }

    adminHospitalDrugDetailsStore.fetchAdminHospitalDrugModelDetails(props.hospitalId, props.drugId)
  }, [])

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const returnToDrugSettings = () => {
    history.push(getAdminRoutePrefix(props.patientId) + '/drugsettings')
  }

  const returnToHospitalDrugs = () => {
    history.push(getAdminRoutePrefix(props.patientId) + `/hospitals/${props.hospitalId}/drugs`)
  }

  const drugDetails = adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.settings

  return (
    <div data-testid='hospital-show' className='co-hospital-show-page'>
      <GeneralSettingsModal
        show={showHospitalDrugModelSettingsModal}
        setShow={setShowHospitalDrugModelSettingsModal}
        hospitalId={props.hospitalId}
        drugId={props.drugId}
      />
      <DosingReportTemplatesModal
        show={showHospitalDrugReportTemplatesModal}
        setShow={setShowHospitalDrugReportTemplatesModal}
        hospitalId={props.hospitalId}
        drugId={props.drugId}
      />
      <SuperAdminSettingsModal
        show={showHospitalDrugSuperAdminSettingsModal}
        setShow={setShowHospitalDrugSuperAdminSettingsModal}
        hospitalId={props.hospitalId}
        drugId={props.drugId}
      />
      <div className='hospital-show-heading'>
        <div className='hospital-show-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(
                getAdminRoutePrefix(props.patientId) + `/hospitals/${props.hospitalId}/drugs`,
                history
              )
            }
          />
        </div>
        <div className='hospital-show-title'>
          Drug settings: <b>{adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.name}</b>
        </div>
      </div>
      <GeneralModelSettings
        setShow={setShowHospitalDrugModelSettingsModal}
        show={showHospitalDrugModelSettingsModal}
        data={drugDetails?.general}
      />
      <RecordedCourseData
        hospitalId={props.hospitalId}
        drugModelId={props.drugId}
        data={drugDetails?.recordedCourseDataLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'recordedCourseData'}
      />
      <CalculatedDosesGeneralSettings
        hospitalId={props.hospitalId}
        drugModelId={props.drugId}
        data={drugDetails?.dosesCalculatedGeneralLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'generalSettings'}
      />
      <CalculatedDosesTargetSettings
        hospitalId={props.hospitalId}
        drugModelId={props.drugId}
        data={drugDetails?.dosesCalculatedTargetLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'targetSettings'}
      />
      <CalculatedDosesLimitsAndWarnings
        hospitalId={props.hospitalId}
        drugModelId={props.drugId}
        data={drugDetails?.dosesCalculatedWarningLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'limitsWarnings'}
      />
      <DosingReportTemplates
        setShow={setShowHospitalDrugReportTemplatesModal}
        show={showHospitalDrugReportTemplatesModal}
        data={drugDetails?.reportTemplates}
      />
      {clinicianStore.clinician?.attributes.isSuperAdmin && (
        <SuperadminSettings
          setShow={setShowHospitalDrugSuperAdminSettingsModal}
          show={showHospitalDrugSuperAdminSettingsModal}
          data={drugDetails?.superAdminInfo}
        />
      )}
    </div>
  )
})
