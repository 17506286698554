import { useHistory } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { decode } from 'he'
import { ActionButton, BackArrowButton, IBreadcrumb, LIGHTGREY, ListButton, SidePanel, Tag } from '@doseme/cohesive-ui'

import { useAdminHospitalDetailsStore, useBreadcrumbsStore, useClinicianStore } from '../../../../../../hooks/useStore'
import { IAdminHospitalDetails } from '../../../../../../store/Admin/AdminHospitalDetails/types'
import { handleBackButton } from '../../../../../../utils/navigation'
import { getAdminRoutePrefix } from '../../../../utils'
import { HospitalCoreDetailsModal } from './SettingsModals/HospitalCoreDetailsModal'
import { HospitalDefaultDoseSettingsModal } from './SettingsModals/HospitalDefaultDoseSettingsModal'
import { HospitalReportSettingsModal } from './SettingsModals/HospitalReportSettingsModal'
import { HospitalClinicianRolesModal } from './SettingsModals/HospitalClinicianRolesModal'
import { HospitalWardsModal } from './SettingsModals/HospitalWardsModal'

import './index.scss'

interface IProps {
  hospitalId: string
  patientId?: string
}

export const HospitalDetails: React.FC<IProps> = observer((props) => {
  const [adminDetails, setAdminDetails] = useState<IAdminHospitalDetails | null>(null)
  const [showSuperadminInformation, setShowSuperadminInformation] = useState<boolean>(true)
  const [showHospitalDetailsModal, setShowHospitalDetailsModal] = useState<boolean>(false)
  const [showHospitalReportSettingsModal, setShowHospitalReportSettingsModal] = useState<boolean>(false)
  const [showHospitalDefaultDoseSettingsModal, setShowHospitalDefaultDoseSettingsModal] = useState<boolean>(false)
  const [showHospitalClinicianRolesModal, setShowHospitalClinicianRolesModal] = useState<boolean>(false)
  const [showHospitalWardsModal, setShowHospitalWardsModal] = useState<boolean>(false)

  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()
  const clinicianStore = useClinicianStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    if (adminHospitalDetailsStore.loadState === 'loaded') {
      const breadcrumbs: IBreadcrumb[] = [
        { label: 'Admin hub', onClick: () => returnToAdminHub() },
        {
          label: 'Hospitals',
          onClick: clinicianStore.onlyAdminHospital()
            ? undefined
            : () =>
              handleBackButton(getAdminRoutePrefix(props.patientId) + '/hospitals', history)
        },
        { label: `${decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}` }
      ]
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [adminHospitalDetailsStore.loadState])

  useEffect(() => {
    if (adminHospitalDetailsStore.loadState === 'loaded' && adminHospitalDetailsStore.adminHospitalDetails) {
      setAdminDetails(adminHospitalDetailsStore.adminHospitalDetails)
    }
  }, [adminHospitalDetailsStore.loadState, adminHospitalDetailsStore.settingsLoadStates])

  useEffect(() => {
    adminHospitalDetailsStore.fetchAdminHospitalDetails(props.hospitalId)
  }, [props.hospitalId])

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const sortedDrugModelGroups = useMemo(() => {
    if (adminHospitalDetailsStore.loadState !== 'loaded' || !adminDetails) {
      return new Map<string, Set<string>>()
    }

    const groupedData = adminDetails.attributes.drugModels.reduce((map, { modelName, moleculeName }) => {
      if (!map[moleculeName]) {
        map[moleculeName] = new Set<string>()
      }

      map[moleculeName].add(modelName)

      return map
    }, {} as Record<string, Set<string>>)

    const sortedOuterKeys = Object.keys(groupedData).sort()

    return new Map<string, Set<string>>(
      sortedOuterKeys.map((key) => [key, new Set([...groupedData[key]].sort())])
    )
  }, [adminHospitalDetailsStore.loadState, adminDetails])

  const handleBackButtonLogic = () => {
    const onlyAdminHospital = clinicianStore.onlyAdminHospital()

    if (onlyAdminHospital) {
      history.push(getAdminRoutePrefix(props.patientId))

      return
    }

    handleBackButton(getAdminRoutePrefix(props.patientId) + '/hospitals', history)
  }

  const handleHospitalDrugSettingsListButton = () => {
    history.push(getAdminRoutePrefix(props.patientId) + `/hospitals/${props.hospitalId}/drugs`, { from: `/admin/hospitals/${props.hospitalId}` })
  }

  const drugsAvailablePanel = () => {
    if (adminDetails?.attributes.drugModels) {
      return (
        <div className='display-formatted-details'>
          {Array.from(sortedDrugModelGroups).map(([modelName, moleculeNames]) => (
            <div key={modelName} className='hospital-show-page-drugs-available-panel'>
              <div className='mb-2'>
                <b>{modelName}</b>
              </div>
              {Array.from(moleculeNames).map((moleculeName) => (
                <div className='mb-1' key={moleculeName}>
                  {moleculeName}
                </div>
              ))}
            </div>
          ))}
          <div className='hospital-show-page-drugs-available-btn-panel'>
            <ListButton
              className='hospial-show-page-side-panel-btn'
              onClick={handleHospitalDrugSettingsListButton}
              size='md'
            >
              View drug settings for this hospital →
            </ListButton>
          </div>
        </div>
      )
    }
  }

  const displayHospitalRolesTags = (): JSX.Element => {
    let returnItems = [<div key={''}></div>]
    if (adminDetails && adminDetails.attributes.clinicians.hospitalWideRoles.length > 0) {
      returnItems = adminDetails.attributes.clinicians.hospitalWideRoles.map((item) => (
        <div className='mr-2 mt-2' key={`admin-details-key-${item.id}`}>
          <Tag color={LIGHTGREY} size='medium' text={item.role} />
        </div>
      ))

      return <div className='hospital-units-roles'>{returnItems}</div>
    }

    return <div className='hospital-show-empty'>No roles defined</div>
  }

  const displayHospitalWardsTags = (): JSX.Element => {
    let returnItems = [<div key={''}></div>]
    if (adminDetails && adminDetails.attributes.hospitalWards.length > 0) {
      returnItems = adminDetails.attributes.hospitalWards.map((item) => (
        <div className='mr-2 mt-2' key={`hospital-units-key-${item.id}`}>
          <Tag color={LIGHTGREY} size='medium' text={decode(item.name, { isAttributeValue: true })} />
        </div>
      ))

      return <div className='hospital-units-roles'>{returnItems}</div>
    }

    return <div className='hospital-show-empty'>No units recorded</div>
  }

  const cliniciansPanel = () => {
    return (
      <div className='display-formatted-details'>
        {/* FIXME - not implemented yet */}
        {/* <div className='hospital-show-page-drugs-available-panel'>
          <div className='d-flex'>
            <b>Active clinicians:</b>
            <div className='active-clinicians-subtitle'>(Number of active clinicians / clinician limit)</div>
          </div>
          <div className='d-flex mt-2'>
            <div className='active-clinicians-number'>N/A / N/A</div>
            <div className='active-clinicians-number-text'> N/A spaces available</div>
          </div>
        </div> */}

        <div className='hospital-show-page-drugs-available-panel'>
          <div className='d-flex'>
            <div>
              <div className='d-flex'>
                <b>Hospital-wide roles:</b>
                <div className='active-clinicians-subtitle'>
                  (These roles apply to any clinician with access to this hospital)
                </div>
              </div>
              {displayHospitalRolesTags()}
            </div>
            <div className='hospital-units-roles-btn'>
              <ActionButton
                data-testid='hospital-details-data-btn'
                actionType='edit'
                onClick={() => setShowHospitalClinicianRolesModal(true)}
              />
            </div>
          </div>
        </div>

        {/* FIXME - not implemented yet */}
        {/* <div className='hospital-show-page-drugs-available-btn-panel'>
          <ListButton className='hospial-show-page-side-panel-btn' disabled={true} size='md'>
            View all clinicians at this hospital →
          </ListButton>
        </div> */}
      </div>
    )
  }

  const hospitalWardsPanel = () => {
    return (
      <div className='hospital-show-page-drugs-available-panel display-formatted-details'>
        <div className='d-flex'>
          <div>
            <div className='d-flex'>
              <b>Units:</b>
              <div className='active-clinicians-subtitle'>(Used to categorize patient location within the hospital)</div>
            </div>
            {displayHospitalWardsTags()}
          </div>
          <div className='hospital-units-roles-btn'>
            <ActionButton
              data-testid='hospital-details-data-btn'
              actionType='edit'
              onClick={() => setShowHospitalWardsModal(true)}
              customLabel='Manage'
            />
          </div>
        </div>
      </div>
    )
  }

  const hospitalDetailsButton: JSX.Element = (
    <div>
      <ActionButton
        data-testid='hospital-details-data-btn'
        actionType='edit'
        onClick={() => setShowHospitalDetailsModal(true)}
      />
    </div>
  )

  const hospitalReportSettingsButton: JSX.Element = (
    <div>
      <ActionButton
        data-testid='hospital-report-settings-data-btn'
        actionType='edit'
        onClick={() => setShowHospitalReportSettingsModal(true)}
      />
    </div>
  )

  const hospitalDefaultDoseSettingsButton: JSX.Element = (
    <div>
      <ActionButton
        data-testid='hospital-default-dose-settings-data-btn'
        actionType='edit'
        onClick={() => setShowHospitalDefaultDoseSettingsModal(true)}
      />
    </div>
  )

  const displayFormattedDetails = (data: Map<string, string | undefined>): JSX.Element => {
    let returnElements: JSX.Element[] = []
    let i = 0
    for (const [title, value] of data) {
      i++
      returnElements.push(
        <div key={`${title}-${value}`}>
          <div className='d-flex mb-2 ml-3'>
            <b>{title}</b>
            <div className='ml-2'>{value}</div>
          </div>
          {i !== data.size ? <hr></hr> : <></>}
        </div>
      )
    }

    return <div className='display-formatted-details'>{returnElements}</div>
  }

  const displaySuperadminInformation = () => {
    return (
      <div
        onClick={() => setShowSuperadminInformation(!showSuperadminInformation)}
        className={classnames('superadmin-info-chevron-outer', { closed: !showSuperadminInformation })}
      >
        <div className='d-flex'>
          <div className='superadmin-info-chevron-outer'>
            <div className='mr-2'>{showSuperadminInformation ? 'Hide' : 'Show'}</div>
            <div
              className={classnames('superadmin-info-chevron', {
                activated: showSuperadminInformation
              })}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div data-testid='hospital-show' className='co-hospital-show-page'>
      <HospitalCoreDetailsModal
        show={showHospitalDetailsModal}
        setShow={setShowHospitalDetailsModal}
        hospitalId={props.hospitalId}
      />
      <HospitalDefaultDoseSettingsModal
        show={showHospitalDefaultDoseSettingsModal}
        setShow={setShowHospitalDefaultDoseSettingsModal}
        hospitalId={props.hospitalId}
        hospitalName={decode(adminDetails?.attributes.details.name || '')}
      />
      <HospitalReportSettingsModal
        show={showHospitalReportSettingsModal}
        setShow={setShowHospitalReportSettingsModal}
        hospitalId={props.hospitalId}
        hospitalName={decode(adminDetails?.attributes.details.name || '')}
      />
      <HospitalClinicianRolesModal
        show={showHospitalClinicianRolesModal}
        setShow={setShowHospitalClinicianRolesModal}
        hospitalId={props.hospitalId}
        hospitalName={decode(adminDetails?.attributes.details.name || '')}
      />
      <HospitalWardsModal
        show={showHospitalWardsModal}
        setShow={setShowHospitalWardsModal}
        hospitalId={props.hospitalId}
        hospitalName={decode(adminDetails?.attributes.details.name || '')}
      />
      <div className='hospital-show-heading'>
        <div className='hospital-show-back-btn'>
          <BackArrowButton data-testid='back-btn' onClick={handleBackButtonLogic} />
        </div>
        <div className='hospital-show-title'>
          Hospital settings: <b>{decode(adminDetails?.attributes.details.name || '')}</b>
        </div>
      </div>
      <SidePanel
        title='Hospital details'
        actionButton={clinicianStore.clinician?.attributes.isSuperAdmin ? hospitalDetailsButton : undefined}
        loading={['loading', 'updating'].includes(adminHospitalDetailsStore.loadState)}
      >
        {displayFormattedDetails(
          new Map([
            ['Hospital name:', decode(adminDetails?.attributes.details.name || '')],
            ['Timezone:', adminDetails?.attributes.details.timezone],
            ['Login method:', adminDetails?.attributes.details.loginMethod]
          ])
        )}
      </SidePanel>
      <div className='mt-3'>
        <SidePanel
          title='Default dosing settings'
          actionButton={hospitalDefaultDoseSettingsButton}
          loading={['loading', 'updating'].includes(adminHospitalDetailsStore.loadState)}
        >
          {displayFormattedDetails(
            new Map([
              ['Default creatinine units:', adminDetails?.attributes.defaultDoseSettings.hospitalCreatinineUnits],
              ['SeCr limits (all models):', adminDetails?.attributes.defaultDoseSettings.patientCreatinineLimits],
              ['Default weight units:', adminDetails?.attributes.defaultDoseSettings.hospitalWeightUnits],
              ['Patient weight limits:', adminDetails?.attributes.defaultDoseSettings.patientWeightLimits],
              ['Default height units:', adminDetails?.attributes.defaultDoseSettings.hospitalHeightUnits],
              ['Patient height limits:', adminDetails?.attributes.defaultDoseSettings.patientHeightLimits],
              [
                'Extend default dose period to guideline period:',
                adminDetails?.attributes.defaultDoseSettings.extendDefaultDosePeriod?.toString()
              ],
              [
                'Days of recorded doses permitted in future:',
                adminDetails?.attributes.defaultDoseSettings.recordedDosesDaysPermittedInFuture?.toString()
              ]
            ])
          )}
        </SidePanel>
      </div>
      {clinicianStore.clinician?.attributes.isSuperAdmin && (
        <div className='mt-3'>
          <SidePanel
            title='Dosing report general settings'
            actionButton={hospitalReportSettingsButton}
            loading={['loading', 'updating'].includes(adminHospitalDetailsStore.loadState)}
          >
            {displayFormattedDetails(
              new Map([
                ['Override report logo file:', adminDetails?.attributes.reportSettings.overrideReportLogoFile],
                [
                  'Show info warnings on report:',
                  adminDetails?.attributes.reportSettings.showInfoWarningsOnReports?.toString()
                ]
              ])
            )}
          </SidePanel>
        </div>
      )}
      <div className='mt-3'>
        <SidePanel title='Hospital Units'>{hospitalWardsPanel()}</SidePanel>
      </div>
      <div className='mt-3'>
        <SidePanel
          loading={['loading', 'updating'].includes(adminHospitalDetailsStore.loadState)}
          title={`Drug models available at ${decode(adminDetails?.attributes.details.name || '')}`}
        >
          {drugsAvailablePanel()}
        </SidePanel>
      </div>
      {/* FIXME when the other sections are implemented only hospital wide roles should be superadmin only */}
      {clinicianStore.clinician?.attributes.isSuperAdmin && (
        <div className='mt-3'>
          <SidePanel title={`Clinicians at ${decode(adminDetails?.attributes.details.name || '')}`}>{cliniciansPanel()}</SidePanel>
        </div>
      )}

      {clinicianStore.clinician?.attributes.isSuperAdmin && (
        <div className='mt-3'>
          <SidePanel
            title='Superadmin information'
            loading={['loading', 'updating'].includes(adminHospitalDetailsStore.loadState)}
            actionButton={displaySuperadminInformation()}
          >
            {showSuperadminInformation &&
              displayFormattedDetails(
                new Map([
                  ['Hospital group:', adminDetails?.attributes.superAdminInfo.hospitalGroup || 'Not Applicable'],
                  [
                    'Hospital analytics UUID:',
                    adminDetails?.attributes.superAdminInfo.analytics.hospitalAnalyticsUUID || 'Not Applicable'
                  ],
                  [
                    'Hospital analytics user:',
                    adminDetails?.attributes.superAdminInfo.analytics.hospitalAnalyticsUser || 'Not Applicable'
                  ]
                ])
              )}
          </SidePanel>
        </div>
      )}
    </div>
  )
})
