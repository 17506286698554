import { Footer } from '@doseme/cohesive-ui'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import useScreenSize from 'use-screen-size'

import { useAuthStore, usePatientCoursesStore, useVersionsStore } from '../../hooks/useStore'
import { receiveEpicToken, requestEpicHandshake } from '../OpenWindowEpic'
import { formatExternalLinkDivs } from '../ResourcesRouter/utils'
import { showErrorToast } from '../../shared/toast'

import './index.scss'

const IntegratedFooter: React.FC = observer(() => {
  const authStore = useAuthStore()
  const patientCoursesStore = usePatientCoursesStore()
  const versionsStore = useVersionsStore()
  const size = useScreenSize()

  const [token, setToken] = useState<string>()
  const [displayVersions, setDisplayVersions] = useState<boolean>(false)

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'epic') {
      requestEpicHandshake(receiveEpicToken, setToken)
    }
  }, [])

  useEffect(() => {
    if (versionsStore.loadState === 'loadError') {
      showErrorToast(versionsStore.error || 'Failed to retrieve version information')
      setDisplayVersions(false)
    }
  }, [versionsStore.loadState])

  useEffect(() => {
    if (displayVersions && versionsStore.loadState !== 'loaded') {
      versionsStore.fetchVersions()
    }
  }, [displayVersions])

  const drugInformationLink = () => {
    if (authStore.auth?.attributes.patientId
      && ['loaded', 'updating'].includes(patientCoursesStore.loadState)) {
      return (
        <div className='footer-row'>
          <Link to={`/patients/${authStore.auth?.attributes.patientId}/resources/drug-information`}>
            Drug Information
          </Link>
        </div>
      )
    }
  }

  const displayVersionInformation = (subclass?: string) => {
    if (displayVersions && versionsStore.loadState === 'loaded') {
      return (
        <div className={`versions-wrapper ${subclass}`}>
          <div className='footer-row'>IFE: {window.env.VERSION} ({window.env.COMMIT_HASH})</div>
          <div className='footer-row'>WWW: {versionsStore.versions?.attributes.backend_version}</div>
          <div className='footer-row'>API: {versionsStore.versions?.attributes.openapi_version}</div>
          <div className='footer-row'>Drugs: {versionsStore.versions?.attributes.dpl_version}</div>
        </div>
      )
    }
  }

  if (size.width > 1200) {
    return (
      <div className='mt-auto w-100'>
        <Footer onIconClick={() => setDisplayVersions(!displayVersions)}>
          <div className='d-flex'>
            <div className='co-col-4 d-block'>
              <div className='footer-row font-weight-bold'>Important Documents</div>
              {formatExternalLinkDivs(
                'https://doseme-rx.com/tos',
                token,
                <div className='footer-row footer-link'>Terms of Service</div>
              )}
              {formatExternalLinkDivs(
                'https://doseme-rx.com/privacy-policy',
                token,
                <div className='footer-row footer-link'>Privacy Policy</div>
              )}
            </div>

            <div className='co-col-4 d-block'>
              <div className='footer-row font-weight-bold'>Compliance</div>
              {formatExternalLinkDivs(
                'https://doseme-rx.com/hipaa',
                token,
                <div className='footer-row footer-link'>HIPAA Privacy Statement</div>
              )}
            </div>
            <div className='co-col-4 d-block'>
              <div className='footer-row font-weight-bold'>Useful Resources</div>

              {drugInformationLink()}

              {formatExternalLinkDivs(
                'https://help.doseme-rx.com/en/',
                token,
                <div className='footer-row footer-link'>Help Center</div>
              )}
            </div>
            <div className='co-col-4 d-block'>
              <div className='footer-row font-weight-bold'>Contact Us</div>
              <div className='footer-row'>US: +1 (832) 358-3308</div>
              <div className='footer-row'>support@dosemehealth.com</div>
            </div>
            {displayVersionInformation()}
          </div>
        </Footer>
      </div>
    )
  }

  return (
    <div className='mt-auto w-100'>
      <Footer onIconClick={() => setDisplayVersions(!displayVersions)}>
        <div className='integrated-footer-wrapper'>
          <div className='footer-info-top-column'>
            <div>
              <div className='footer-row font-weight-bold'>Important Documents</div>
              {formatExternalLinkDivs(
                'https://doseme-rx.com/tos',
                token,
                <div className='footer-row footer-link'>Terms of Service</div>
              )}
              {formatExternalLinkDivs(
                'https://doseme-rx.com/privacy-policy',
                token,
                <div className='footer-row footer-link'>Privacy Policy</div>
              )}
            </div>
            <div className='mt-4'>
              <div className='footer-row font-weight-bold'>Useful Resources</div>

              {drugInformationLink()}

              {formatExternalLinkDivs(
                'https://help.doseme-rx.com/en/',
                token,
                <div className='footer-row footer-link'>Help Center</div>
              )}
            </div>
          </div>
          <div className='footer-info-bottom-column'>
            <div>
              <div className='footer-row font-weight-bold'>Compliance</div>
              {formatExternalLinkDivs(
                'https://doseme-rx.com/hipaa',
                token,
                <div className='footer-row footer-link'>HIPAA Privacy Statement</div>
              )}
            </div>
            <div className='mt-4'>
              <div className='footer-row font-weight-bold'>Contact Us</div>
              <div className='footer-row'>US: +1 (832) 358-3308</div>
              <div className='footer-row'>support@dosemehealth.com</div>
            </div>
          </div>
          {displayVersionInformation('reactive')}
        </div>
      </Footer>
    </div>
  )
})

export { IntegratedFooter }
