import axios, { AxiosError, AxiosResponse } from 'axios'
import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import {
  adminClinicianAddHospitalAccessPostUrl,
  adminClinicianDetailsGetUrl,
  adminClinicianDetailsPutUrl,
  adminClinicianHospitalAccessGetUrl,
  adminClinicianHospitalAdminTogglePutUrl,
  adminClinicianInviteGetUrl,
  adminClinicianInvitePostUrl,
  adminClinicianRemoveHospitalAccessDeleteUrl
} from '../../../constants/api'
import { AdminClinicianDetails } from './AdminClinicianDetails'
import { AdminClinicianInvite } from './AdminClinicianInvite'
import {
  IAdminClinicianDetails,
  IAdminClinicianDetailsHospitalAccessList,
  IPutAdminClinicianDetails,
  IAdminClinicianInvite,
  IPutAdminClinicianInvite
} from './types'
import { AdminClinicianDetailsHospitalAccessList } from './AdminClinicianDetailsHospitalAccess'

export class AdminClinicianDetailsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminClinicianDetails: AdminClinicianDetails | null = null

  adminClinicianInvite: AdminClinicianInvite | null = null

  settingsLoadStates: Record<string, TLoadState> = {
    hospitalAccess: 'initial'
  }
  settingsErrors: Record<string, string> = {
    hospitalAccess: ''
  }

  adminClinicianDetailsHospitalAccess: ObservableMap<string, IAdminClinicianDetailsHospitalAccessList> = observable.map(
    {},
    { deep: false }
  )

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  removeSingleHospitalAccess(hospitalId: string) {
    this.adminClinicianDetailsHospitalAccess.delete(hospitalId)
  }

  setDetails(adminClinicianDetails: IAdminClinicianDetails) {
    this.adminClinicianDetails = new AdminClinicianDetails(this, adminClinicianDetails)
  }

  resetDetails() {
    this.adminClinicianDetails = null
  }

  resetDetailsHospitalAccess(hospitalAccess: IAdminClinicianDetailsHospitalAccessList[]) {
    this.adminClinicianDetailsHospitalAccess = observable.map({}, { deep: false })
    hospitalAccess.forEach((hospital) =>
      this.adminClinicianDetailsHospitalAccess.set(
        hospital.id,
        new AdminClinicianDetailsHospitalAccessList(this, hospital)
      )
    )
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  setLoadStateSettings(loadState: TLoadState, setting: string) {
    this.settingsLoadStates[setting] = loadState
  }

  setErrorSettings(errorState: 'loadError' | 'updateError', error: string, setting: string) {
    this.settingsErrors[setting] = error
    this.setLoadStateSettings(errorState, setting)
  }

  async fetchAdminClinicianDetails(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminClinicianDetails>>(adminClinicianDetailsGetUrl(clinicianId), { headers })
      .then((response) => {
        this.setDetails(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async editAdminClinicianDetails(clinicianId: string, details: IPutAdminClinicianDetails) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminClinicianDetails>>(
        adminClinicianDetailsPutUrl(clinicianId),
        {
          data: {
            id: clinicianId,
            type: 'adminClinicianDetails',
            attributes: details
          }
        },
        { headers }
      )
      .then((response) => {
        this.setDetails(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async fetchAdminClinicianHospitalAccess(clinicianId: string) {
    this.setLoadStateSettings('loading', 'hospitalAccess')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminClinicianDetailsHospitalAccessList[]>>(adminClinicianHospitalAccessGetUrl(clinicianId), {
        headers
      })
      .then((response) => {
        this.resetDetailsHospitalAccess(response.data.data)
        this.setLoadStateSettings('loaded', 'hospitalAccess')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'hospitalAccess')
      })
  }

  async toggleClinicianHospitalAdminRights(clinicianId: string, hospitalId: string, isAdmin: boolean) {
    this.setLoadStateSettings('loading', 'hospitalAccess')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .put<AxiosResponse<IAdminClinicianDetailsHospitalAccessList[]>>(
        adminClinicianHospitalAdminTogglePutUrl(clinicianId, hospitalId),
        {
          data: {
            type: 'adminClinicianHospitalAccess',
            attributes: { admin: isAdmin }
          }
        },
        { headers }
      )
      .then((response) => {
        this.resetDetailsHospitalAccess(response.data.data)
        this.setLoadStateSettings('loaded', 'hospitalAccess')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'hospitalAccess')
      })
  }

  async addClinicianHospitalAccess(clinicianId: string, hospitalId: string, hospitalName: string) {
    this.setLoadStateSettings('loading', 'hospitalAccess')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .post<AxiosResponse<IAdminClinicianDetailsHospitalAccessList[]>>(
        adminClinicianAddHospitalAccessPostUrl(clinicianId),
        { data: { id: hospitalId, type: 'adminClinicianAddHospitalAccess', attributes: { name: hospitalName } } },
        { headers }
      )
      .then((response) => {
        this.resetDetailsHospitalAccess(response.data.data)
        this.setLoadStateSettings('loaded', 'hospitalAccess')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'hospitalAccess')
      })
  }

  async removeClinicianHospitalAccess(clinicianId: string, hospitalId: string) {
    this.setLoadStateSettings('loading', 'hospitalAccess')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .delete<AxiosResponse<IAdminClinicianDetailsHospitalAccessList[]>>(
        adminClinicianRemoveHospitalAccessDeleteUrl(clinicianId, hospitalId),
        { headers }
      )
      .then(() => {
        this.removeSingleHospitalAccess(hospitalId)
        this.setLoadStateSettings('loaded', 'hospitalAccess')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'hospitalAccess')
      })
  }

  setAdminClinicianInvite(adminClinicianInvite: IAdminClinicianInvite) {
    this.adminClinicianInvite = new AdminClinicianInvite(this, adminClinicianInvite)
  }

  async fetchAdminClinicianInvite() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IAdminClinicianInvite>>(adminClinicianInviteGetUrl(), {
        headers
      })
      .then((response) => {
        this.setAdminClinicianInvite(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async createAdminClinicianInvite(newClinician: IPutAdminClinicianInvite) {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axios
      .post<AxiosResponse<IAdminClinicianInvite[]>>(
        adminClinicianInvitePostUrl(),
        {
          data: {
            type: 'AdminClinicianInvite',
            attributes: {
              email: newClinician.attributes.email,
              title: newClinician.attributes.title,
              fname: newClinician.attributes.firstName,
              lname: newClinician.attributes.lastName,
              hospitalAccess: newClinician.attributes.hospitalAccess,
              userSpecificRoles: newClinician.attributes.userSpecificRoles
            }
          }
        },
        { headers }
      )
      .then((response) => {
        this.setLoadState('loaded')

        if (response) {
          return response
        }

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }
}
