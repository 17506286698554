import { IHeaderItem, ITooltipMenuData } from '@doseme/cohesive-ui'

import { IAdminVendorSiteListItem } from '../../../../../store/Admin/AdminVendorSiteList/types'

export const itemsPerPage = 10

export const vendorSiteListColumns = (handleSort: (colIndex: number, ascending: boolean) => void): IHeaderItem[] => [
  {
    name: 'Site name',
    handleSort,
    width: 6
  },
  {
    name: 'Site key',
    handleSort,
    width: 6
  },
  {
    name: 'Hospital',
    handleSort,
    width: 6
  },
  {
    name: '',
    width: 1
  }
]

export const vendorSiteActionData = (vendorSite: IAdminVendorSiteListItem, handleDeleteVendorSite: (vendor: IAdminVendorSiteListItem) => void): ITooltipMenuData[] => {
  return [
    {
      id: 2,
      value: 'Delete site',
      onSelect: () => handleDeleteVendorSite(vendorSite)
    }
  ]
}
