import axios, { AxiosError, AxiosResponse } from 'axios'
import { makeAutoObservable } from 'mobx'

import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { hospitalGetUrl, hospitalSwitchGetUrl } from '../../constants/api'
import { Hospital } from './Hospital'
import { IHospital } from './types'

export class HospitalStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  hospital: Hospital | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setHospital(hospitalAttrs: IHospital) {
    const hospital = new Hospital(this, hospitalAttrs)
    this.hospital = hospital

    return hospital
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchHospital(hospitalId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IHospital>>(hospitalGetUrl(hospitalId), { headers })
      .then((response) => {
        this.setHospital(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async switchHospital(clinicianId: string, hospitalId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IHospital>>(hospitalSwitchGetUrl(clinicianId, hospitalId), { headers })
      .then((response) => {
        this.fetchHospital(hospitalId)
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
