import axios, { AxiosError, AxiosResponse } from 'axios'
import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import { patientExternalDataDownloadGetUrl, patientExternalDataListGetUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { PatientExternalDataListItem } from './PatientExternalDataListItem'
import { IPatientExternalDataListItem, IPatientExternalDataResponse } from './types'

export class PatientExternalDataStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  patientExternalDataList: ObservableMap<string, PatientExternalDataListItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetPatientExternalDataList(patientExternalDataList: IPatientExternalDataListItem[]) {
    this.patientExternalDataList = observable.map({}, { deep: false })
    patientExternalDataList.forEach((patientExternalDataList) =>
      this.patientExternalDataList.set(patientExternalDataList.id, new PatientExternalDataListItem(this, patientExternalDataList))
    )
  }

  async fetchPatientExternalDataList(patientId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IPatientExternalDataListItem[]>>(patientExternalDataListGetUrl(patientId), { headers })
      .then((response) => {
        this.resetPatientExternalDataList(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async fetchPatientExternalData(patientId: string, externalDataId: string): Promise<AxiosResponse<IPatientExternalDataResponse> | null>  {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/json'
    }

    return await axios
      .get(patientExternalDataDownloadGetUrl(patientId, externalDataId), { headers })
      .then((response: AxiosResponse<IPatientExternalDataResponse>) => {
        this.setLoadState('loaded')

        return response
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)

        return null
      })
  }
}
