import ReactTooltip from 'react-tooltip'
import { IDropdownItem, IStatistic, Icons, TextDropdown, TextInput } from '@doseme/cohesive-ui'

import {
  IModelDoseRecommendation,
  IPredictedOutcome
} from '../../../../../../../../../../store/dosingRecommendation/types'
import { IDefaultLimit, ILimit } from '../../../../../../../../../../store/types'
import { ICustomTargetForms, ICustomDoseForms, TTargetTypes } from '../../types'

import './index.scss'

const customTargetTextInputs = (
  customForms: ICustomTargetForms,
  selectedTargetDropdownItem: IDropdownItem,
  aucTargetLimit: IDefaultLimit,
  peakTargetLimit: IDefaultLimit,
  troughTargetLimit: IDefaultLimit,
  timeAboveMICTargetLimit: IDefaultLimit,
  cumulativeAUCTargetLimit: IDefaultLimit,
  errorMap: Map<string, string>,
  fieldOrder: string[],
  updateValidationErrors: (errorMap: Map<string, string>) => void,
  isLoading: boolean
): JSX.Element => {
  if (selectedTargetDropdownItem.value === 'auc24') {
    return (
      <div className='custom-field-invalid-wrapper'>
        {errorMap.get('auc24') ? (
          <div className='custom-field-invalid-indicator validation-error-number'>
            {fieldOrder.indexOf('auc24') + 1}
          </div>
        ) : null}
        <TextInput
          data-testid='custom-target-auc24-input'
          fieldState={customForms['auc24'].getValidState('auc24')}
          className='custom-form-input'
          borderRadius={8}
          value={customForms['auc24'].inputs['auc24']}
          textAlignCenter
          onChange={(value) =>
            customForms['auc24'].validateFields([
              {
                field: 'auc24',
                input: value,
                constraints: aucTargetLimit
              }
            ])
          }
          onBlur={() => {
            const errorMap = customForms['auc24'].updateFieldsDisplay(['auc24'], 'returnErrorMap')
            updateValidationErrors(errorMap)
          }}
          name='custom-target-auc24-input'
          disabled={isLoading}
        />
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'auc') {
    return (
      <div className='custom-field-invalid-wrapper'>
        {errorMap.get('auc') ? (
          <div className='custom-field-invalid-indicator validation-error-number'>{fieldOrder.indexOf('auc') + 1}</div>
        ) : null}
        <TextInput
          data-testid='custom-target-auc-input'
          fieldState={customForms['auc'].getValidState('auc')}
          borderRadius={8}
          className='custom-form-input'
          value={customForms['auc'].inputs['auc']}
          textAlignCenter
          onChange={(value) =>
            customForms['auc'].validateFields([
              {
                field: 'auc',
                input: value,
                constraints: aucTargetLimit
              }
            ])
          }
          onBlur={() => {
            const errorMap = customForms['auc'].updateFieldsDisplay(['auc'], 'returnErrorMap')
            updateValidationErrors(errorMap)
          }}
          name='custom-target-auc-input'
        />
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'peakonly') {
    return (
      <div className='custom-field-invalid-wrapper'>
        {errorMap.get('peak') ? (
          <div className='custom-field-invalid-indicator validation-error-number'>{fieldOrder.indexOf('peak') + 1}</div>
        ) : null}
        <TextInput
          data-testid='custom-target-peak-input'
          fieldState={customForms['peakonly'].getValidState('peak')}
          borderRadius={8}
          className='custom-form-input'
          value={customForms['peakonly'].inputs['peak']}
          textAlignCenter
          onChange={(value) =>
            customForms['peakonly'].validateFields([
              {
                field: 'peak',
                input: value,
                constraints: peakTargetLimit
              }
            ])
          }
          onBlur={() => {
            const errorMap = customForms['peakonly'].updateFieldsDisplay(['peak'], 'returnErrorMap')
            updateValidationErrors(errorMap)
          }}
          name='custom-target-peak-input'
        />
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'troughonly') {
    return (
      <div className='custom-field-invalid-wrapper'>
        {errorMap.get('trough') ? (
          <div className='custom-field-invalid-indicator validation-error-number'>
            {fieldOrder.indexOf('trough') + 1}
          </div>
        ) : null}
        <TextInput
          data-testid='custom-target-trough-input'
          fieldState={customForms['troughonly'].getValidState('trough')}
          borderRadius={8}
          className='custom-form-input'
          value={customForms['troughonly'].inputs['trough']}
          textAlignCenter
          onChange={(value) =>
            customForms['troughonly'].validateFields([
              {
                field: 'trough',
                input: value,
                constraints: troughTargetLimit
              }
            ])
          }
          onBlur={() => {
            const errorMap = customForms['troughonly'].updateFieldsDisplay(['trough'], 'returnErrorMap')
            updateValidationErrors(errorMap)
          }}
          name='custom-target-trough-input'
          disabled={isLoading}
        />
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'timetotrough') {
    return (
      <div className='custom-field-invalid-wrapper'>
        {errorMap.get('trough') ? (
          <div className='custom-field-invalid-indicator validation-error-number'>
            {fieldOrder.indexOf('trough') + 1}
          </div>
        ) : null}
        <TextInput
          data-testid='custom-target-trough-input'
          fieldState={customForms['timetotrough'].getValidState('trough')}
          borderRadius={8}
          className='custom-form-input custom-form-input-timetotrough'
          value={customForms['timetotrough'].inputs['trough']}
          textAlignCenter
          onChange={(value) =>
            customForms['timetotrough'].validateFields([
              {
                field: 'trough',
                input: value,
                constraints: troughTargetLimit
              }
            ])
          }
          onBlur={() => {
            const errorMap = customForms['timetotrough'].updateFieldsDisplay(['trough'], 'returnErrorMap')
            updateValidationErrors(errorMap)
          }}
          name='custom-target-trough-input'
          disabled={isLoading}
        />
      </div>
    )
  }

  // order is reverse here because we display it as min/max (trough/peak)
  if (selectedTargetDropdownItem.value === 'peak/trough') {
    return (
      <div className='co-text-input-group'>
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('trough') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('trough') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-target-peak-trough-input-2'
            fieldState={customForms['peak/trough'].getValidState('trough')}
            borderRadius={8}
            className='custom-form-input'
            value={customForms['peak/trough'].inputs['trough']}
            textAlignCenter
            onChange={(value) =>
              customForms['peak/trough'].validateFields([
                {
                  field: 'trough',
                  input: value,
                  constraints: troughTargetLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['peak/trough'].updateFieldsDisplay(['trough'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-peak-trough-input-2'
            disabled={isLoading}
          />
        </div>
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('peak') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('peak') + 1}
            </div>
          ) : null}
          <TextInput
            className='custom-form-input'
            data-testid='custom-target-peak-trough-input-1'
            fieldState={customForms['peak/trough'].getValidState('peak')}
            borderRadius={8}
            value={customForms['peak/trough'].inputs['peak']}
            textAlignCenter
            onChange={(value) =>
              customForms['peak/trough'].validateFields([
                {
                  field: 'peak',
                  input: value,
                  constraints: peakTargetLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['peak/trough'].updateFieldsDisplay(['peak'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-peak-trough-input-1'
            disabled={isLoading}
          />
        </div>
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'extended_interval') {
    return (
      <div className='co-text-input-group'>
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('trough') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('trough') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-target-extended-interval-input-2'
            fieldState='valid'
            borderRadius={8}
            className='custom-form-input'
            value={customForms['extended_interval'].inputs['trough']}
            onChange={(value) =>
              customForms['extended_interval'].validateFields([
                {
                  field: 'trough',
                  input: value,
                  constraints: troughTargetLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['extended_interval'].updateFieldsDisplay(['trough'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-extended-interval-input-2'
          />
        </div>
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('peak') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('peak') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-target-extended-interval-input-1'
            fieldState='valid'
            borderRadius={8}
            className='custom-form-input'
            value={customForms['extended_interval'].inputs['peak']}
            onChange={(value) =>
              customForms['extended_interval'].validateFields([
                {
                  field: 'peak',
                  input: value,
                  constraints: peakTargetLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['extended_interval'].updateFieldsDisplay(['peak'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-extended-interval-input-1'
          />
        </div>
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'time_above_mic') {
    return (
      <div className='custom-field-invalid-wrapper'>
        {errorMap.get('time_above_mic') ? (
          <div className='custom-field-invalid-indicator validation-error-number'>
            {fieldOrder.indexOf('time_above_mic') + 1}
          </div>
        ) : null}
        <TextInput
          data-testid='custom-target-timeaobvemic-input'
          fieldState={customForms['time_above_mic'].getValidState('timeAboveMICPercentage')}
          borderRadius={8}
          className='custom-form-input'
          value={customForms['time_above_mic'].inputs['timeAboveMICPercentage']}
          textAlignCenter
          onChange={(value) =>
            customForms['time_above_mic'].validateFields([
              {
                field: 'timeAboveMICPercentage',
                input: value,
                constraints: timeAboveMICTargetLimit
              }
            ])
          }
          onBlur={() => {
            const errorMap = customForms['time_above_mic'].updateFieldsDisplay(
              ['timeAboveMICPercentage'],
              'returnErrorMap'
            )
            updateValidationErrors(errorMap)
          }}
          name='custom-target-timeabovemic-input'
          disabled={isLoading}
        />
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'range') {
    return (
      <div className='co-text-input-group'>
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('trough') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('trough') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-target-range-input-2'
            fieldState='valid'
            borderRadius={8}
            className='custom-form-input'
            value={customForms['range'].inputs['trough']}
            onChange={(value) =>
              customForms['range'].validateFields([
                {
                  field: 'trough',
                  input: value,
                  constraints: troughTargetLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['range'].updateFieldsDisplay(['trough'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-range-input-2'
          />
        </div>
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('peak') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('peak') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-target-range-input-1'
            fieldState='valid'
            borderRadius={8}
            className='custom-form-input'
            value={customForms['range'].inputs['peak']}
            onChange={(value) =>
              customForms['range'].validateFields([
                {
                  field: 'peak',
                  input: value,
                  constraints: peakTargetLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['range'].updateFieldsDisplay(['peak'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-range-input-1'
          />
        </div>
      </div>
    )
  }

  if (selectedTargetDropdownItem.value === 'cumulative_auc') {
    return (
      <div className='custom-field-invalid-wrapper'>
        {errorMap.get('remainingAUC') ? (
          <div className='custom-field-invalid-indicator validation-error-number'>
            {fieldOrder.indexOf('remainingAUC') + 1}
          </div>
        ) : null}
        <TextInput
          data-testid='custom-target-remainingauc-input'
          fieldState={customForms['cumulative_auc'].getValidState('remainingAUC')}
          borderRadius={8}
          className='custom-form-input custom-form-input-cumulative_auc'
          value={customForms['cumulative_auc'].inputs['remainingAUC']}
          textAlignCenter
          onChange={(value) =>
            customForms['cumulative_auc'].validateFields([
              {
                field: 'remainingAUC',
                input: value,
                constraints: cumulativeAUCTargetLimit
              }
            ])
          }
          onBlur={() => {
            const errorMap = customForms['cumulative_auc'].updateFieldsDisplay(['remainingAUC'], 'returnErrorMap')
            updateValidationErrors(errorMap)
          }}
          name='custom-target-remainingauc-input'
          disabled={isLoading}
        />
      </div>
    )
  }

  return <></>
}

export const customTargetFormFields = (
  aucTargetLimit: IDefaultLimit,
  peakTargetLimit: IDefaultLimit,
  troughTargetLimit: IDefaultLimit,
  infusionLengthLimit: IDefaultLimit,
  dosingPeriodLimit: IDefaultLimit,
  numberOfDosesLimit: IDefaultLimit,
  timeAboveMICTargetLimit: IDefaultLimit,
  cumulativeAUCTargetLimit: IDefaultLimit,
  doseLimit: ILimit,
  customForms: ICustomTargetForms,
  allowedCustomTargets: IDropdownItem[] | null,
  selectedTargetDropdownItem: IDropdownItem,
  setSelectedTargetDropdownItem: (selectedTargetDropdownItem: IDropdownItem) => void,
  setCustomValidationErrors: (errors: Array<[string, string]>) => void,
  isLoading: boolean,
  drugModelName?: string
): IStatistic[] => {
  const targets = [
    'auc24',
    'auc',
    'peak/trough',
    'troughonly',
    'peakonly',
    'extended_interval',
    'time_above_mic',
    'timetotrough',
    'range',
    'cumulative_auc'
  ]

  // "complete dose params" in this case includes dosing interval and number of doses.
  // extended interval is currently the only target that does not have these fields
  const targetsWithCompleteDoseParams = ['auc24', 'auc', 'peak/trough', 'troughonly', 'peakonly', 'time_above_mic', 'range', 'cumulative_auc']

  if (allowedCustomTargets) {
    allowedCustomTargets = [...allowedCustomTargets].filter((target) => targets.includes(target.value))
  }

  const targetSubtitles: Record<string, string> = {
    auc24: aucTargetLimit.default.unit!.name,
    auc: aucTargetLimit.default.unit!.name,
    peakonly: peakTargetLimit.default.unit!.name,
    troughonly: troughTargetLimit.default.unit!.name,
    'peak/trough': `min-max ${peakTargetLimit.default.unit!.name}`,
    extended_interval: `min-max ${peakTargetLimit.default.unit!.name}`,
    time_above_mic: timeAboveMICTargetLimit.default.unit!.name,
    timetotrough: troughTargetLimit.default.unit!.name,
    range: 'min - max INR',
    cumulative_auc: `Remaining ${cumulativeAUCTargetLimit.default.unit!.name}`
  }

  const errorMap = customForms[selectedTargetDropdownItem.value as keyof ICustomTargetForms].getErrorMap()
  const fieldOrder = Array.from(errorMap.keys())

  const updateValidationErrors = (errorMap: Map<string, string>) =>
    setCustomValidationErrors(Array.from(errorMap.entries()))

  const selectedTarget = selectedTargetDropdownItem.value as unknown as TTargetTypes
  const subtitleLabel = selectedTarget === 'timetotrough' ? 'Trough' : undefined

  const formFields = [
    {
      id: selectedTargetDropdownItem.value,
      title: (
        <TextDropdown
          data={allowedCustomTargets ? allowedCustomTargets : []}
          initialValue={selectedTargetDropdownItem}
          onSelect={(item) => {
            setSelectedTargetDropdownItem(item)
          }}
          heightPx={15}
        />
      ),
      subtitleLabel: subtitleLabel,
      subtitle: targetSubtitles[selectedTargetDropdownItem.value as keyof ICustomTargetForms],
      value: customTargetTextInputs(
        customForms,
        selectedTargetDropdownItem,
        aucTargetLimit,
        peakTargetLimit,
        troughTargetLimit,
        timeAboveMICTargetLimit,
        cumulativeAUCTargetLimit,
        errorMap,
        fieldOrder,
        updateValidationErrors,
        isLoading
      )
    },
    {
      id: 'infusionLength',
      title: 'Infusion',
      subtitle: infusionLengthLimit.default.unit!.name,
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('infusionLength') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('infusionLength') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-infusion-length-input'
            fieldState={customForms[selectedTargetDropdownItem.value as keyof ICustomTargetForms].getValidState(
              'infusionLength'
            )}
            className='custom-form-input'
            borderRadius={8}
            value={customForms[selectedTargetDropdownItem.value as keyof ICustomTargetForms].inputs['infusionLength']}
            textAlignCenter
            onChange={(value) => {
              for (const formKey of targets) {
                customForms[formKey as keyof ICustomTargetForms].validateFields([
                  {
                    field: 'infusionLength',
                    input: value,
                    constraints: infusionLengthLimit
                  }
                ])
              }
            }}
            onBlur={() => {
              const errorMap = customForms[
                selectedTargetDropdownItem.value as keyof ICustomTargetForms
              ].updateFieldsDisplay(['infusionLength'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-infusion-length-input'
            disabled={isLoading}
          />
        </div>
      )
    }
  ]

  if (selectedTarget === 'extended_interval') {
    return formFields
  }

  if (selectedTarget === 'timetotrough') {
    formFields.splice(1, 0, {
      id: 'doseAmount',
      title: 'Dose',
      subtitle: doseLimit.min.unit!.name,
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('doseAmount') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('doseAmount') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-number-doses-input'
            fieldState={customForms['timetotrough'].getValidState('doseAmount')}
            borderRadius={8}
            className='custom-form-input'
            value={customForms['timetotrough'].inputs['doseAmount']}
            textAlignCenter
            onChange={(value) => {
              customForms['timetotrough'].validateFields([
                {
                  field: 'doseAmount',
                  input: value,
                  constraints: doseLimit
                }
              ])
            }}
            onBlur={() => {
              const errorMap = customForms['timetotrough'].updateFieldsDisplay(['doseAmount'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-number-doses-input'
            disabled={isLoading}
          />
        </div>
      )
    })

    return formFields
  }

  const isIntervalDisabled =
    selectedTarget === 'range' &&
    dosingPeriodLimit.default.value === dosingPeriodLimit.max.value &&
    dosingPeriodLimit.max.value === dosingPeriodLimit.min.value

  return formFields.concat([
    {
      id: 'dosingInterval',
      title: isIntervalDisabled ? (
        <div className='d-flex'>
          Interval
          <ReactTooltip id='interval-locked-info-tip' place='top' effect='solid'>
            Dosing interval is fixed to 24 h <br />
            for {drugModelName}
          </ReactTooltip>
          <div data-tip data-for='interval-locked-info-tip' className='ml-1'>
            <div className='interval-info-icon'>
              <Icons.Info />
            </div>
          </div>
        </div>
      ) : (
        <>Interval</>
      ),
      subtitle: dosingPeriodLimit.default.unit!.name,
      subtitleLabel: undefined,
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('dosingInterval') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('dosingInterval') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-dosing-interval-input'
            fieldState={customForms[selectedTargetDropdownItem.value as keyof ICustomTargetForms].getValidState(
              'dosingInterval'
            )}
            borderRadius={8}
            className='custom-form-input'
            value={customForms[selectedTargetDropdownItem.value as keyof ICustomTargetForms].inputs['dosingInterval']}
            textAlignCenter
            onChange={(value) => {
              for (const formKey of targetsWithCompleteDoseParams) {
                customForms[formKey as keyof ICustomTargetForms].validateFields([
                  {
                    field: 'dosingInterval',
                    input: value,
                    constraints: dosingPeriodLimit
                  }
                ])
              }
            }}
            onBlur={() => {
              const errorMap = customForms[
                selectedTargetDropdownItem.value as keyof ICustomTargetForms
              ].updateFieldsDisplay(['dosingInterval'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-dosing-interval-input'
            disabled={isLoading || isIntervalDisabled}
          />
        </div>
      )
    },
    {
      id: 'doses',
      title: 'Doses',
      subtitle: 'No.',
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('numberOfDoses') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('numberOfDoses') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-number-doses-input'
            fieldState={customForms[selectedTargetDropdownItem.value as keyof ICustomTargetForms].getValidState(
              'numberOfDoses'
            )}
            borderRadius={8}
            className='custom-form-input'
            value={customForms[selectedTargetDropdownItem.value as keyof ICustomTargetForms].inputs['numberOfDoses']}
            textAlignCenter
            onChange={(value) => {
              for (const formKey of targetsWithCompleteDoseParams) {
                customForms[formKey as keyof ICustomTargetForms].validateFields([
                  {
                    field: 'numberOfDoses',
                    input: value,
                    constraints: numberOfDosesLimit
                  }
                ])
              }
            }}
            onBlur={() => {
              const errorMap = customForms[
                selectedTargetDropdownItem.value as keyof ICustomTargetForms
              ].updateFieldsDisplay(['numberOfDoses'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-target-number-doses-input'
            disabled={isLoading}
          />
        </div>
      )
    }
  ])
}

export const customTargetDoseStat = (model: IModelDoseRecommendation | null): IStatistic[] => [
  {
    id: 'dose',
    title: 'Dose',
    subtitle: model?.doseRecommendationAttributes?.amount.unit!.name || '—',
    value: model?.doseRecommendationAttributes?.amount.value.toString() || '—'
  }
]

export const customTargetTroughStat = (predictedOutcome: IPredictedOutcome | null): IStatistic[] => [
  {
    id: 'predictedTroughAtNextDose',
    title: 'Trough at next dose',
    subtitle: predictedOutcome?.troughUnits || '—',
    value: predictedOutcome?.trough?.toString() || '—'
  }
]

export const customDoseFormFields = (
  doseLimit: ILimit,
  infusionLengthLimit: IDefaultLimit,
  dosingPeriodLimit: IDefaultLimit,
  numberOfDosesLimit: IDefaultLimit,
  customForms: ICustomDoseForms,
  setCustomValidationErrors: (errors: Array<[string, string]>) => void,
  isLoading: boolean
): IStatistic[] => {
  const errorMap = customForms['dose'].getErrorMap()
  const fieldOrder = Array.from(errorMap.keys())

  const updateValidationErrors = (errorMap: Map<string, string>) =>
    setCustomValidationErrors(Array.from(errorMap.entries()))

  return [
    {
      id: 'dose',
      title: 'Dose',
      subtitle: doseLimit.min.unit!.name,
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('doseAmount') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('doseAmount') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-dose-amount-input'
            fieldState={customForms['dose'].getValidState('doseAmount')}
            borderRadius={8}
            className='customdose-dose-form-field'
            value={customForms['dose'].inputs['doseAmount']}
            textAlignCenter
            onChange={(value) =>
              customForms['dose'].validateFields([
                {
                  field: 'doseAmount',
                  input: value,
                  constraints: doseLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['dose'].updateFieldsDisplay(['doseAmount'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-dose-amount-input'
            disabled={isLoading}
          />
        </div>
      )
    },
    {
      id: 'infusionLength',
      title: 'Infusion',
      subtitle: infusionLengthLimit.default.unit!.name,
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('infusionLength') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('infusionLength') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-infusion-length-input'
            fieldState={customForms['dose'].getValidState('infusionLength')}
            borderRadius={8}
            className='custom-form-input'
            value={customForms['dose'].inputs['infusionLength']}
            textAlignCenter
            onChange={(value) =>
              customForms['dose'].validateFields([
                {
                  field: 'infusionLength',
                  input: value,
                  constraints: infusionLengthLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['dose'].updateFieldsDisplay(['infusionLength'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-dose-infusion-length-input'
            disabled={isLoading}
          />
        </div>
      )
    },
    {
      id: 'dosingInterval',
      title: 'Interval',
      subtitle: dosingPeriodLimit.default.unit!.name,
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('dosingInterval') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('dosingInterval') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-dosing-interval-input'
            fieldState={customForms['dose'].getValidState('dosingInterval')}
            borderRadius={8}
            className='custom-form-input'
            value={customForms['dose'].inputs['dosingInterval']}
            textAlignCenter
            onChange={(value) =>
              customForms['dose'].validateFields([
                {
                  field: 'dosingInterval',
                  input: value,
                  constraints: dosingPeriodLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['dose'].updateFieldsDisplay(['dosingInterval'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-dose-dosing-interval-input'
            disabled={isLoading}
          />
        </div>
      )
    },
    {
      id: 'doses',
      title: 'Doses',
      subtitle: 'No.',
      value: (
        <div className='custom-field-invalid-wrapper'>
          {errorMap.get('numberOfDoses') ? (
            <div className='custom-field-invalid-indicator validation-error-number'>
              {fieldOrder.indexOf('numberOfDoses') + 1}
            </div>
          ) : null}
          <TextInput
            data-testid='custom-number-doses-input'
            fieldState={customForms['dose'].getValidState('numberOfDoses')}
            borderRadius={8}
            className='custom-form-input'
            value={customForms['dose'].inputs['numberOfDoses']}
            textAlignCenter
            onChange={(value) =>
              customForms['dose'].validateFields([
                {
                  field: 'numberOfDoses',
                  input: value,
                  constraints: numberOfDosesLimit
                }
              ])
            }
            onBlur={() => {
              const errorMap = customForms['dose'].updateFieldsDisplay(['numberOfDoses'], 'returnErrorMap')
              updateValidationErrors(errorMap)
            }}
            name='custom-dose-number-doses-input'
            disabled={isLoading}
          />
        </div>
      )
    }
  ]
}
