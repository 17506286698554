import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { makeAutoObservable } from 'mobx'

import { doseReportPostUrl } from '../../constants/api'
import { TReportSimulationType, TValidDosingAttributes } from '../../types/doseReport'
import { RootStore } from '../RootStore'
import { IGenerateReport, TReportLoadState, TReportResponse } from './types'
import { IDrugSpecificAttr } from '../dosingRecommendation/types'

export class GenerateReportStore {
  rootStore: RootStore

  loadState: TReportLoadState = 'idle'
  error: string = ''

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TReportLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async generateReport(
    patientId: string,
    courseId: string,
    drugModelId: string,
    attributes: TValidDosingAttributes,
    dosingType: TReportSimulationType,
    isPreview: 'isPreview' | undefined,
    clinicalNotesInput: string,
    drugSpecificAttributes: IDrugSpecificAttr | null
  ): Promise<AxiosResponse<TReportResponse> | null> {
    if (isPreview) {
      this.setLoadState('previewLoading')
    } else {
      this.setLoadState('saving')
    }

    const config: AxiosRequestConfig = {
      headers: {
        Accept: 'application/vnd.api+json, application/json'
      }
    }

    const pdfSaveConfig: AxiosRequestConfig = {
      headers: {
        Accept: 'application/vnd.api+json, application/json'
      },
      responseType: 'blob'
    }

    const doseReportRequest: IGenerateReport = {
      type: 'DoseReportRequest',
      attributes: {
        clinicianNotes: clinicalNotesInput.trim(),
        vendor: window.env.VENDOR_MODE,
        preview: !!isPreview,
        dosingType: dosingType,
        dosingAttributes: attributes,
        drugSpecificAttributes: drugSpecificAttributes
      }
    }

    return await axios
      .post(
        doseReportPostUrl(patientId, courseId, drugModelId),
        doseReportRequest,
        ['dss', 'standalone'].includes(window.env.VENDOR_MODE) && !isPreview ? pdfSaveConfig : config
      )
      .then((response: AxiosResponse<TReportResponse>) => {
        this.setLoadState('loaded')

        return response
      })
      .catch(async (error: Error | AxiosError) => {
        this.setLoadState('loadError')

        // If the error response type is 'blob', it means the error details are in binary format, and we need to
        // convert the Blob response data to text and parse it as JSON to extract the error details.
        if (axios.isAxiosError(error) && error.response && error.response.data instanceof Blob) {
          try {
            const errorText = await error.response.data.text()
            error.response.data = JSON.parse(errorText)
          } catch (e) {
            // If parsing fails, fall back to the original error
          }
        }

        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)

        this.setError('loadError', loggableError)

        return null
      })
  }
}
