import axios, { AxiosError, AxiosResponse } from 'axios'
import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import { drugModelsInformationGetUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { DrugModelInformation } from './DrugModelInformation'
import { IDrugModelInformation } from './types'

// FIXME - We need to make this work when there is no patient set for standalone
// FIXME - Also need to make this component use patientStore.patient.id for standalone
export class DrugModelsInformationStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  drugModels: ObservableMap<string, IDrugModelInformation> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  // Create-or-update. Does not delete.
  setDrugModelsInformation(drugModels: IDrugModelInformation[]) {
    drugModels.forEach((drugModel) => this.drugModels.set(drugModel.id, new DrugModelInformation(this, drugModel)))
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchDrugModelsInformation(patientId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IDrugModelInformation[]>>(drugModelsInformationGetUrl(patientId), { headers })
      .then((response) => {
        this.setDrugModelsInformation(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
        this.setDrugModelsInformation([])
      })
  }
}
