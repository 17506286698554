import { makeAutoObservable, observable, ObservableMap } from 'mobx'
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios'

import { courseDeleteUrl, patientCoursesGetUrl, patientCoursesPostUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { PatientCourse } from './PatientCourse'
import { IPatientCourse } from './types'

export class PatientCoursesStore {
  rootStore: RootStore

  cancelToken?: CancelTokenSource = undefined
  loadState: TLoadState = 'initial'
  error: string = ''
  selectedCourseId?: string

  courses: ObservableMap<string, PatientCourse> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  resetStore() {
    this.courses = observable.map({}, { deep: false })
    this.loadState = 'initial'
  }

  setCancelToken(cancelToken?: CancelTokenSource) {
    this.cancelToken = cancelToken
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  setCourses(courses: IPatientCourse[]) {
    courses.forEach((course) => this.courses.set(course.id, new PatientCourse(this, course)))
  }

  filterCourseResponse(response: IPatientCourse[]) {
    return [...response].filter((course) => course.attributes.drugModel.molecule.name !== 'unknown')
  }

  async fetchPatientCourses(patientId: string) {
    this.setLoadState('loading')
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
    const cancelToken = axios.CancelToken.source()
    this.setCancelToken(cancelToken)

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .get<AxiosResponse<IPatientCourse[]>>(patientCoursesGetUrl(patientId), { headers, cancelToken: cancelToken?.token })
      .then((response) => {
        this.setCancelToken()
        const res = this.filterCourseResponse(response.data.data)
        this.setCourses(res)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isCancel(error)) {
          return
        }
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async createCourse(
    patientId: string,
    drugModelId: string,
    suggestedDrugModelId?: string,
    modelSuggestionMethod?: 'suitability' | 'priority' | 'fit' | 'fallback' | null
  ): Promise<IPatientCourse | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const response = await axios
      .post<AxiosResponse<IPatientCourse[]>>(
        patientCoursesPostUrl(patientId),
        {
          type: 'patientCourse',
          attributes: {
            drugModelId,
            suggestedDrugModelId,
            modelSuggestionMethod
          }
        },
        { headers }
      )
      .then((response) => {
        this.setLoadState('loaded')

        // This endpoint will always return an array containing a single ICourse
        // (the course just created) on success.
        return response.data.data[0]
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })

    return response
  }

  async deleteCourse(patientId: string, courseId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axios
      .delete<AxiosResponse<IPatientCourse[]>>(courseDeleteUrl(patientId, courseId), { headers })
      .then(() => {
        this.resetStore()
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })

    return null
  }
}
